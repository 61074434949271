import React, { Component } from 'react'
import { withStyles, Dialog, DialogTitle, ListItem, ListItemAvatar, ListItemText, List, Avatar, DialogActions, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person'
import Done from '@material-ui/icons/Done'
import { green, red } from '@material-ui/core/colors';
const styles = theme => ({

})

class AttendanceListStudent extends Component {
  render() {
      let {classes} = this.props;
      let i = [];
      if(this.props.open){
          let sl = this.props.table_data;
          if(Array.isArray(sl) && sl.length> 0){
              i = sl.map((el,index) => 

                  <ListItem key={index}>
                      <ListItemAvatar>
                        {el.status === true ? <Avatar style={{backgroundColor: green[300]}} >
                              P
                        </Avatar> : 
                        <Avatar style={{backgroundColor: red[300]}}>
                            A
                        </Avatar>}
                      </ListItemAvatar>
                      <ListItemText
                          primary={el.name}
                          secondary={el.roll_no}
                      />
                  </ListItem>

              )
          }
      }
    return (
      <div>
        
            <Dialog open={this.props.open} onClose={this.props.onClose} aria-labelledby="simple-dialog-title">
                <DialogTitle id="simple-dialog-title">Student List</DialogTitle>
                <div>
                    <List>
                        
                           {i} 
                        
                        
                    </List>
                </div>

                <DialogActions>
                    <Button onClick={this.props.onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
      </div>
    )
  }
}


export default withStyles(styles)(AttendanceListStudent)
