import React, { Component } from 'react'
import { withStyles, Drawer, Typography, TextField, Button, AppBar, Toolbar, Grid, FormControl, InputLabel, Select, MenuItem, FormGroup, Switch, FormControlLabel } from '@material-ui/core';
import Sel from 'react-select';
import { GetData } from './../../../api/service'

const drawerWidth = 800;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        minWidth: '100%',
        marginTop: '15px'
    }
})

class EmployeeAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            course_type: 'HONOURS',
            session_id:'1',
            stream: 'SCIENCE',
            course: 'TDC',
            dept_code: '',
            class_id: 0,
            name: '',
            roll_no: '',
            phone: '',
            email: '',
            registration_no: '',
            university_roll_no: '',
            gender: '',
            caste: '',
            date_of_birth: '',
            date_of_admission: '',
            term_list: [1, 2, 3, 4, 5, 6],
            term_name: 'TERM',
            term: '1',
            father_name: '',
            mother_name: '',
            religion: '',
            nationality: '',
            state: '',
            is_handicapped: '',
            disability: '',
            present_address: '',
            permanent_address: '',
            dp: [],
            signature: [],
            attendance: [],
            class_data: [],
            educational_details: [],
            subjects: [],
            class_list: this.props.classes_list,
            id: '',
            f_subjects: [],
            achievements: [],
            major_subjects: [],
            compulsory_subjects: [],
            compulsory_subjects_list: [],
            mil_subjects: [],
            mil_subjects_list: [],
            subjects_op: [],
            showMil: false,
            major_list: []
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeS = this.onChangeS.bind(this);

    }

    handleSChange = (name, sub_code) => {
        this.setState({
            [name]: sub_code
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })



        if (e.target.name === 'course_type') {
            if (e.target.value !== 'HONOURS') {
                this.setState({
                    dept_code: ''
                })
            }
            this.showMil(this.state.course, this.state.stream, this.state.dept_code, this.state.term, this.props.classes_list)
        }

        if (e.target.name === 'course') {
            let { stream, dept_code } = this.state;
            let courses = this.props.courses;
            let term_name = ""; let term_list = [];
            let results = courses.filter((el) => el.name === e.target.value);
            if (results.length > 0) {
                term_name = results[0].tenure;
                let no_of_term = parseFloat(results[0].no_of_term);
                for (let i = 1; i <= no_of_term; i++) {
                    term_list.push(i);
                }
                this.setState({
                    term_name,
                    term_list
                })
            }

            this.showMil(e.target.value, this.state.stream, this.state.dept_code, this.state.term, this.props.classes_list)
        }

        if (e.target.name === 'stream') {
            this.showMil(this.state.course, e.target.value, this.state.dept_code, this.state.term, this.props.classes_list)
        }

        if (e.target.name === 'dept_code') {
            this.showMil(this.state.course, this.state.stream, e.target.value, this.state.term, this.props.classes_list)
        }

        if (e.target.name === 'class_id') {

            let classes_list = this.props.classes_list;

            let result = classes_list.filter(el => el.id === parseFloat(e.target.value));
            if (result.length > 0) {
                this.setState({
                    f_subjects: result[0].optional
                })
            }
        }

        if (e.target.name === 'term') {

            this.showMil(this.state.course, this.state.stream, this.state.dept_code, e.target.value, this.props.classes_list)
        }
    }

    onChangeS(e) {
        let is_HOD = this.state.is_HOD;
        if (e.target.checked) {
            is_HOD = 1
        } else {
            is_HOD = 0
        }

        this.setState({
            chec: e.target.checked,
            is_HOD
        })

    }

    componentDidMount() {
        this.showMil(this.state.course, this.state.stream, this.state.dept_code, this.state.term, this.props.classes_list)

        if (this.props.edit) {
            this.showMil(this.props.edit_data.course, this.props.edit_data.stream, this.props.edit_data.dept_code, this.props.edit_data.semester, this.props.classes_list)
            //console.log(this.props.edit_data)
            let r = this.props.edit_data.subjects;
            let subjects = r.filter(el => el.type === 'optional' || el.type == 'generic' || el.is_generic == '1' || el.type === 'SE' || el.sub_type == 'HG' || el.sub_type == 'RC' || el.sub_type == 'SE')
            let major_subjects = r.filter(el => el.type === 'major' || el.is_major == '1' || el.sub_type == 'HC')
            let compulsory_subjects = r.filter(el => el.type === 'compulsory' || el.is_compulsory == '1')
            let mil_subjects = r.filter(el => el.type === 'mil' || el.is_MIL == '1' || el.sub_type == 'AE')
            //console.log(dd)
            this.setState({
                ...this.props.edit_data,
                term: this.props.edit_data.semester,
                subjects,
                major_subjects,
                compulsory_subjects,
                mil_subjects,
                f_subjects: []
            })


            
        } else {

            //this.onClearState();

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.showMil(this.state.course, this.state.stream, this.state.dept_code, this.state.term, this.props.classes_list)
            if (this.props.edit) {
                this.showMil(this.props.edit_data.course, this.props.edit_data.stream, this.props.edit_data.dept_code, this.props.edit_data.semester, this.props.classes_list)
                let r = this.props.edit_data.subjects;
                let subjects_op = r.filter(el => el.type === 'optional' || el.type == 'generic' || el.is_generic == '1' || el.type === 'SE' || el.sub_type == 'HG' || el.sub_type == 'RC' || el.sub_type == 'SE')
                let major_subjects = r.filter(el => el.type === 'major' || el.is_major == '1' || el.sub_type == 'HC')
                let compulsory_subjects = r.filter(el => el.type === 'compulsory' || el.is_compulsory == '1')
                let mil_subjects = r.filter(el => el.type === 'mil' || el.is_MIL == '1' || el.sub_type == 'AE')
                //console.log(dd)
                this.setState({
                    ...this.props.edit_data,
                    term: this.props.edit_data.semester,
                    subjects_op,
                    major_subjects,
                    compulsory_subjects,
                    mil_subjects,
                    f_subjects: []
                })

                
            } else {
                //this.onClearState();
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        let { term,
            dept_code,
            course_type,
            course,
            stream,
            department,
            semester,
            session_id,
            roll_no,
            registration_no,
            university_roll_no,
            name, email,
            phone,
            date_of_birth,
            gender,
            caste,
            date_of_admission,
            state,
            religion,
            nationality,
            present_address,
            permanent_address,
            father_name,
            mother_name,
            mil_subjects,
            compulsory_subjects,
            major_subjects,
            subjects_op,
            id
        } = this.state;

        let comp_sub = this.modifySubjects(compulsory_subjects, 'compulsory') || [];
        let major_sub = this.modifySubjects(major_subjects, 'major') || [];
        let optional_sub = this.modifySubjects(subjects_op, 'optional') || [];
        let mil_sub = this.modifySubjects(mil_subjects, 'mil') || [];



        let sub = mil_sub.concat(major_sub.concat(optional_sub.concat(comp_sub)))


        const data = {
            term,
            dept_code,
            subjects: sub,
            course_type,
            course,
            stream,
            department,
            semester,
            session_id,
            roll_no,
            registration_no,
            university_roll_no,
            name, email,
            phone,
            date_of_birth,
            gender,
            caste,
            date_of_admission,
            state,
            religion,
            nationality,
            present_address,
            permanent_address,
            father_name,
            mother_name,
            class_id: 0,
            is_handicapped: '',
            disability: '',
            attendance: [],
            class_data: [],
            achievements: [],
            educational_details: [],
            dp: [],
            signature: [],
            id
        }

        //console.log(data);

        if (this.props.edit) {
            this.props.esetData(data);
        } else {
            this.props.setData(data);
            this.onClearState();
        }

    }

    onClearState = () => {
        this.setState({
            course_type: 'HONOURS',
            stream: 'SCIENCE',
            course: 'TDC',
            dept_code: '',
            class_id: 0,
            name: '',
            roll_no: '',
            phone: '',
            email: '',
            registration_no: '',
            university_roll_no: '',
            term_list: [1, 2, 3, 4, 5, 6],
            term_name: 'TERM',
            term: '1',
            gender: '',
            caste: '',
            date_of_birth: '',
            date_of_admission: '',
            father_name: '',
            mother_name: '',
            religion: '',
            nationality: '',
            state: '',
            is_handicapped: '',
            disability: '',
            present_address: '',
            permanent_address: '',
            dp: [],
            signature: [],
            attendance: [],
            class_data: [],
            educational_details: [],
            subjects: [],
            id: '',
            f_subjects: [],
            session_id: '1',
            achievements: [],
            major_subjects: [],
            compulsory_subjects: [],
            mil_subjects: [],
            subjects_op: [],
            showMil: false,
            major_list: []
        })
    }


    showMil = (course, stream, dept_code, term, classes_list) => {
        let mil_subjects_list = this.state.mil_subjects_list;

        if (Array.isArray(mil_subjects_list) && mil_subjects_list.length === 0) {
            GetData(`/${this.props.apikey}/getmilsubjects`)
                .then((resp) => {
                    let r = [];
                    if (Array.isArray(resp)) {
                        r = resp.map((el, index) => {
                            return ({
                                label: el.sub_name,
                                value: el.sub_code,
                                dept_code: el.dept_code
                            })
                        }
                        )
                        //console.log(r)
                        this.setState({
                            mil_subjects_list: r
                        })
                    }
                })
        }

        //fetch all the subjects

        GetData(`/getsubject`)
        .then((resp) => {
            if(Array.isArray(resp) && resp.length > 0){
                let subjects_op = [];
                if(dept_code == ''){
                    subjects_op = resp.filter(el => el.sub_type == 'RC' || el.sub_type == 'SE' || el.sub_type == 'HG')
                }else{
                    subjects_op = resp.filter(el => el.sub_type == 'HG' || el.sub_type == 'SE' || el.sub_type == 'RC')
                }
                
                let major_subjects = resp.filter(el => el.is_major == '1' && el.dept_code==dept_code && dept_code !='')
                let compulsory_subjects = resp.filter(el => el.type === 'compulsory' || el.is_compulsory == '1')

                this.setState({
                    optional_subjects: subjects_op,
                    major_list: major_subjects,
                    //major_subjects: major_subjects,
                    compulsory_subjects_list: compulsory_subjects,
                    //compulsory_subjects: compulsory_subjects
                    
                }, () => {
                    console.log("🚀 ~ file: EmployeeAdd.js ~ line 425 ~ EmployeeAdd ~ .then ~ v", this.state)
                })
                    
            }
        })


        if (course === 'TDC' && stream === 'ARTS' && (term === 1 || term === 2 || term === 3 || term === 4)) {
            this.setState({
                showMil: true
            })
        }
    }


    modifySubjects(subjectArray, type) {
        if(subjectArray != null){
            let r = subjectArray.map((el, index) => {
                el['type'] = type;
                return el;
            }
            )
            return r;
        }else{
            return [];
        }
        
    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    onSetDp(dp) {
        this.setState({
            dp: dp
        })
    }

    onSetCv(dp) {
        this.setState({
            cv: dp
        })
    }

    setQualification(data) {
        this.setState({
            qualification: data
        })
    }

    setAchievements(data) {
        this.setState({
            details: data
        })
    }

    render() {

        let { classes } = this.props;
        let i = [];
        let stream = [];
        let course = [];
        let cl = [];
        let ss = [];
        let term = [];
        if (this.props.isDeptLoaded) {
            let departments = this.props.departments;
            if (Array.isArray(departments) && departments.length > 0) {
                i = departments.map((el, index) =>
                    <MenuItem key={index} value={el.dept_code}>{el.dept_name}</MenuItem>
                )
            }

            let streams = this.props.streams;
            if (Array.isArray(streams) && streams.length > 0) {
                stream = streams.map((el, index) =>
                    <MenuItem key={index} value={el.stream_name}>{el.stream_name}</MenuItem>
                )
            }

            let courses = this.props.courses;
            if (Array.isArray(courses) && courses.length > 0) {
                course = courses.map((el, index) =>
                    <MenuItem key={index} value={el.name}>{el.name}</MenuItem>
                )
            }

            let cls = this.state.class_list;
            if (Array.isArray(cls) && cls.length > 0) {
                cl = cls.map((el, index) =>
                    <MenuItem key={index} value={el.id}>{el.class_name}</MenuItem>
                )
            }

            let sss = this.props.sessions;
            if (Array.isArray(sss) && sss.length > 0) {
                ss = sss.map((el, index) =>
                    <MenuItem key={index} value={el.id}>{el.session_name}</MenuItem>
                )
            }

            if (Array.isArray(this.state.term_list) && this.state.term_list.length > 0) {
                term = this.state.term_list.map((el, index) =>
                    <MenuItem key={index} value={el}>{el}</MenuItem>
                )
            }
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <Grid container spacing={2}>


                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Course Type</InputLabel>
                                        <Select
                                            value={this.state.course_type}
                                            onChange={this.onChange}
                                            name="course_type"
                                            inputProps={{
                                                name: 'course_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            
                                            <MenuItem value="HONOURS">HONOURS</MenuItem>
                                            <MenuItem value="REGULAR">REGULAR</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>


                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Session</InputLabel>
                                        <Select
                                            value={this.state.session_id}
                                            onChange={this.onChange}
                                            name="session_id"
                                            inputProps={{
                                                name: 'session_id'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {ss}
                                        </Select>
                                    </FormControl>

                                </Grid>


                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Stream</InputLabel>
                                        <Select
                                            value={this.state.stream}
                                            onChange={this.onChange}
                                            name="stream"
                                            inputProps={{
                                                name: 'stream'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {stream}
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Course</InputLabel>
                                        <Select
                                            value={this.state.course}
                                            onChange={this.onChange}
                                            name="course"
                                            inputProps={{
                                                name: 'course'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {course}
                                        </Select>
                                    </FormControl>

                                </Grid>

                                {(this.state.course_type === 'HONOURS') ? <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Department</InputLabel>
                                        <Select
                                            value={this.state.dept_code}
                                            onChange={this.onChange}
                                            name="dept_code"
                                            inputProps={{
                                                name: 'dept_code'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {i}
                                        </Select>
                                    </FormControl>

                                </Grid> : null}

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>{this.state.term_name}</InputLabel>
                                        <Select
                                            value={this.state.term}
                                            onChange={this.onChange}
                                            name="term"
                                            inputProps={{
                                                name: 'term'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {term}
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Student Name"
                                        name="name"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.name}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Roll No"
                                        name="roll_no"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.roll_no}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Phone "
                                        name="phone"
                                        className={classes.textField}
                                        type="number"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.phone}
                                        onChange={this.onChange}
                                    />

                                </Grid>


                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Student ID / Username"
                                        name="email"
                                        className={classes.textField}
                                        type="text"

                                        margin="normal"
                                        
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.email}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="University Reg No"
                                        name="registration_no"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.registration_no}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="University Roll No"
                                        name="university_roll_no"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.university_roll_no}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Gender</InputLabel>
                                        <Select
                                            value={this.state.gender}
                                            onChange={this.onChange}
                                            name="gender"
                                            inputProps={{
                                                name: 'gender'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>Choose Gender</em>
                                            </MenuItem>

                                            <MenuItem value="MALE">MALE</MenuItem>
                                            <MenuItem value="FEMALE">FEMALE</MenuItem>
                                            <MenuItem value="OTHER">OTHER</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Caste</InputLabel>
                                        <Select
                                            value={this.state.caste}
                                            onChange={this.onChange}
                                            name="caste"
                                            inputProps={{
                                                name: 'caste'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>Choose Caste</em>
                                            </MenuItem>

                                            <MenuItem value="GENERAL">GENERAL</MenuItem>
                                            <MenuItem value="ST(p)">ST(P)</MenuItem>
                                            <MenuItem value="ST(H)">ST(H)</MenuItem>
                                            <MenuItem value="SC">SC</MenuItem>
                                            <MenuItem value="OBC">OBC</MenuItem>
                                            <MenuItem value="MOBC">MOBC</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Date of Birth"
                                        name="date_of_birth"
                                        className={classes.textField}
                                        type="date"
                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.date_of_birth}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Date of Admission"
                                        name="date_of_admission"
                                        className={classes.textField}
                                        type="date"
                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.date_of_admission}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Father's Name"
                                        name="father_name"
                                        className={classes.textField}
                                        type="text"

                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.father_name}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Mother's Name"
                                        name="mother_name"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.mother_name}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Religion"
                                        name="religion"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.religion}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Nationality"
                                        name="nationality"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.nationality}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>State</InputLabel>
                                        <Select
                                            value={this.state.state}
                                            onChange={this.onChange}
                                            name="state"
                                            inputProps={{
                                                name: 'state'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>Choose State</em>
                                            </MenuItem>
                                            <MenuItem value="ASSAM">ASSAM</MenuItem>
                                            <MenuItem value="ARUNACHAL PRADESH">ARUNACHAL PRADESH</MenuItem>
                                            <MenuItem value="MANIPUR">MANIPUR</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Are you physically handicapped?</InputLabel>
                                        <Select
                                            value={this.state.is_handicapped}
                                            onChange={this.onChange}
                                            name="is_handicapped"
                                            inputProps={{
                                                name: 'is_handicapped'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>Choose State</em>
                                            </MenuItem>
                                            <MenuItem value="NO">NO</MenuItem>
                                            <MenuItem value="YES">YES</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4} lg={4}>


                                    <TextField
                                        label="Describe your disablilty"
                                        name="disability"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        multiline

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.disability}
                                        onChange={this.onChange}
                                    />


                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        label="Present Address"
                                        name="present_address"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        multiline
                                        rows="2"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.present_address}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        label="Permanent Address"
                                        name="permanent_address"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"

                                        multiline
                                        rows="2"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.permanent_address}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <InputLabel className={classes.textStyle}>Major Subjects</InputLabel>
                                    <Sel
                                        isMulti
                                        value={this.state.major_subjects}
                                        onChange={this.handleSChange.bind(this, "major_subjects")}
                                        options={this.state.major_list}
                                    />
                                </Grid>


                                <Grid item xs={12} lg={6}>
                                    <InputLabel className={classes.textStyle}>Compulsory Subjects</InputLabel>
                                    <Sel
                                        isMulti
                                        value={this.state.compulsory_subjects}
                                        onChange={this.handleSChange.bind(this, "compulsory_subjects")}
                                        options={this.state.compulsory_subjects_list}
                                    />
                                </Grid>

                                {this.state.showMil ?
                                    <Grid item xs={12} lg={6}>
                                        <InputLabel className={classes.textStyle}>MIL Subjects</InputLabel>
                                        <Sel
                                            isMulti
                                            value={this.state.mil_subjects}
                                            onChange={this.handleSChange.bind(this, "mil_subjects")}
                                            options={this.state.mil_subjects_list}
                                        />
                                    </Grid> : null}

                                <Grid item xs={12} lg={12}>
                                    <InputLabel className={classes.textStyle}>Select Optional Subjects</InputLabel>
                                    <Sel
                                        isMulti
                                        value={this.state.subjects_op}
                                        onChange={this.handleSChange.bind(this, "subjects_op")}
                                        options={this.state.optional_subjects}
                                    />
                                </Grid>
                            </Grid>

                            <br />
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(EmployeeAdd)