import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import MarksLanding from '../Components/MarksEntry/MarksLanding';


class MarksContainer extends Component {
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">

                   <MarksLanding history={this.props.history} />

                </div>
            </div>
        )
    }
}

export default withAuth(MarksContainer);