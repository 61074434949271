import React, { Component } from 'react';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { GetData } from '../../../api/service';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class StudentFeedbackReport extends Component {
    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        GetData(`/${this.props.apikey}/getfeedbackstudent`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }
    render() {
        return (
            <div>
                <Typography
                    variant="h6"
                    gutterBottom
                >
                    <u>Student's Feedback Report --- Current Session</u>
                </Typography>
                <br />

                {this.state.isLoaded ? (<div>
                    <ReactHTMLTableToExcel
                        table="table-to-xls"
                        filename="Student Feedback Report Current Session"
                        sheet="tablexls"
                        buttonText="Download as XLS" />
                    <br />
                    <br />
                    <Table style={{ backgroundColor: 'white' }} size="small" id="table-to-xls">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Course</TableCell>
                                <TableCell>Stream</TableCell>
                                <TableCell>Course Type</TableCell>
                                <TableCell>Semester</TableCell>
                                <TableCell>Roll No</TableCell>
                                <TableCell>Student ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>No of Feedbacks</TableCell>
                                <TableCell>Time Stamp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.data.map((el, index) =>

                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{el.course}</TableCell>
                                    <TableCell>{el.stream}</TableCell>
                                    <TableCell>{el.course_type}</TableCell>
                                    <TableCell>{el.semester}</TableCell>
                                    <TableCell>{el.roll_no}</TableCell>
                                    <TableCell>{el.student_id}</TableCell>
                                    <TableCell>{el.name}</TableCell>
                                    <TableCell>{el.count_feedback}</TableCell>
                                    <TableCell>{el.time_stamp}</TableCell>
                                </TableRow>

                            )}
                        </TableBody>
                    </Table>
                </div>) : (<div>
                    <Typography>
                        Loading . . . .
                   </Typography>
                </div>)}

            </div>
        );
    }
}

export default StudentFeedbackReport;