import React, { Component } from 'react'
import { withStyles, Button, Typography, Grid } from '@material-ui/core';
import { GetData } from '../../../api/service';
// import SmallBread from '../../SmallBread';
import ReactToPrint from 'react-to-print';
import Print from '@material-ui/icons/Print'
import './print.css'

const styles = theme => ({
    printContainer: {
        padding: '20px',
        textAlign: 'center'
    },
    billContainer: {
        border: 'solid thin lightgrey',
        width: '100%',
        minHeight: '100vh'
    }
})

class PrintForm extends Component {
    constructor(props) {
        super(props);

        let id = this.props.history.location.state;


        this.state = {
            marks_id: id,
            marks_data: [],
            isLoadded: false
        }

    }

    componentDidMount() {
        if (this.state.marks_id !== undefined) {
            GetData(`/${this.props.apikey}/${this.props.username}/${this.state.marks_id}/getmarkssingle`)
                .then((resp) => {
                    //console.log(resp);
                    this.setState({
                        marks_data: resp[0],
                        isLoadded: true
                    })
                })
        } else {
            this.props.history.replace('/viewform')
        }

    }


    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>


                <div className={classes.printContainer}>
                    <ReactToPrint
                        trigger={() => <Button color="primary" variant="outlined"><Print /> Print </Button>}
                        content={() => this.componentRef}
                        closeAfterPrint={true}
                        copyStyles={true}
                        pageStyle="margin: 10px"
                    />

                    <br />
                    <br />

                    {this.state.isLoadded ? <PrintFormat
                        {...this.props}
                        ref={el => (this.componentRef = el)}
                        data={this.state.marks_data}
                        isLoaded={this.state.isLoaded}
                    /> : 'Loading....'}


                </div>
                <br /><br />

                
            </div>
        )
    }
}


export default withStyles(styles)(PrintForm);



class PrintFormat extends Component {

    getGoodSemester = (semester) => {
        if(semester == '1'){
            return `1st Semester`;
        }else if(semester == '2'){
            return `2nd Semester`;
        }else if(semester == '3'){
            return `3rd Semester`;
        }else {
            return `${semester}th Semester`;
        }
    }


    render() {

        let { classes } = this.props;
        let j = this.props.data.marks_details.length;
        let k = [];
        for(let i=20;i>j;i--){
          
           k.push(<tr><td colSpan="7"></td></tr>);
        }
    
        return (
            <div className="print-bill-container">
                <div id="watermark">
                    <p>Hey Heye </p>
                </div>
                <div className="print-bill-header-address">
                    <table width="100%">
                        <thead>
                            <tr>

                                <td align="center">

                                    <img
                                        src={require('./../../../assets/logo.png')}
                                        style={{ width: '8%' }}
                                    />

                                    <Typography
                                        variant="h5"
                                    >
                                        বীৰঝৰা মহাবিদ্যালয়
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                    >
                                         BIRJHORA MAHAVIDYALAYA
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        Main Road
                                        Sahid Bedi, Bongaigaon
                                        783380 INDIA
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        TEL: +91-03664 228808
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                    >
                                        Statement of Marks
                                    </Typography>
                                   
                                </td>

                                
                            </tr>
                            
                        </thead>
                    </table>
                </div>

                <Grid container spacing={24}>
                    <Grid item lg={8} md={8} sm={8} xs={8} style={{padding: '20px'}}>
                        <Typography
                            variant="body1"
                        >
                            Name of the student: <b>{this.props.data.name}</b>
                        </Typography>

                        <Typography
                            variant="body1"
                        >
                           Roll No: <b>{this.props.data.roll_no}</b>
                        </Typography>

                        <Typography
                            variant="body1"
                        >
                            Class: <b>{this.props.data.marksheet_title}</b>
                        </Typography>

                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={4} style={{ textAlign: 'right', padding: '20px'}}>
                        <Typography
                            variant="body1"
                        >
                            Serial No: <b>{this.props.data.marksheet_no}</b>
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            Date of Issue: <b>{this.props.data.entry_date}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <div className="bill-body">
                    <table className="bill-table">
                        <thead>
                            <tr>
                                <td>#</td>
                                
                                <td align="left">Subject Code</td>
                                <td align="left">Subject Name</td>
                                <td align="center">Total Marks</td>
                                <td align="center">Pass Marks</td>
                                <td align="center">Marks Obtained</td>
                                <td align="center">Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.data.marks_details.map((el, index) =>

                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    
                                    <td align="left">{el.paper}</td>
                                    <td align="left">{el.sub_title}</td>
                                    <td align="center">{el.total_marks}</td>
                                    <td align="center">{el.pass_marks}</td>
                                    <td align="center">{el.marks_obtained}</td>
                                    <td align="center">{el.result_status}</td>
                                </tr>

                            )}

                        </tbody>
                        <tbody>
                         {k}
                        </tbody>

                        
                    </table>
                </div>

                <div
                    style={{padding: '20px'}}
                >

                    <Grid container spacing={2}>
                        <Grid lg={6} md={6}>
                            <Typography
                                variant="body1"
                            >
                                Total Marks : <b>{this.props.data.grand_marks}</b>
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                Total Pass Mark : <b>{this.props.data.grand_pass}</b>
                            </Typography>

                            <Typography
                                variant="body1"
                            >
                                Total Marks Obtained : <b>{this.props.data.grand_obtained}</b>
                            </Typography>

                            <Typography
                                variant="body1"
                            >
                                Remarks : <b>{this.props.data.result}</b>
                            </Typography>
                        </Grid>

                        <Grid lg={6} md={6}>
                                
                        </Grid>
                    </Grid>
                    
                </div>

                
            </div>
        )

    }
}

