import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import AuthService from './../auth_components/js/AuthService';
import { Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Collapse, Typography } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const drawerWidth = 200;

const styles = theme => ({
    root: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: '#2196F3'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    navIconHide: {
        display: 'block',
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },

    drawerPaper: {
        width: drawerWidth,
        left: 0,
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth
        }
    },
    listSmall: {
        paddingLeft: '35px'
    }
});


const menuListObject = [
    {
        title: "Dasboard",
        link: "/",
        submenu: []
    },
    {
        title: "Master",
        link: "",
        submenu: [
            {
                title: "Course",
                link: "/master/course"
            },
            {
                title: "Department",
                link: "/master/department"
            },
            {
                title: "Subject",
                link: "/master/subject"
            },
            {
                title: "Paper Codes",
                link: "/master/paper"
            },
            {
                title: "Stream",
                link: "/master/stream"
            },
            {
                title: "Class",
                link: "/master/Class"
            },
            {
                title: "Session",
                link: "/master/session"
            },
            {
                title: "Holiday",
                link: "/master/holiday"
            },
            {
                title: "Routine",
                link: "/master/routine"
            },
            {
                title: "Routine View",
                link: "/master/routineview"
            },
            {
                title: "Section",
                link: "/master/section"
            },
            {
                title: "Force Change Password",
                link: "/master/passwordchange"
            },
            {
                title: "Access Control",
                link: "/master/access"
            }
        ]
    },
    {
        title: "Employee",
        link: "/employee",
        submenu: []
    },
    {
        title: "Student",
        link: "/student",
        submenu: []
    },
    {
        title: "Student Import",
        link: "/student/import",
        submenu: []
    },
    {
        title: "Feedback",
        link: "",
        submenu: [
            {
                title: "Category",
                link: "/feedback/category"
            },
            {
                title: "Session",
                link: "/feedback/session"
            },
            {
                title: "Questions",
                link: "/feedback/questions"
            },
            {
                title: "Reports",
                link: "/feedback/reports"
            }
        ]
    },
    /* {
        title: "Leave",
        link: "",
        submenu: [
            {
                title: "Status",
                link: "/leave/status"
            },
            {
                title: "Reports",
                link: "/leave/reports"
            }
        ]
    }, */
    {
        title: "Attendance",
        link: "/attendancereport",
        submenu: []
    },
    {
        title: "Reports",
        link: "",
        submenu: [
            {
                title: "Student Report",
                link: "/studentreport"
            },
            {
                title: "Class Attendance",
                link: "/areport"
            },
            {
                title: "Student Attendance",
                link: "/sattreport"
            }
        ]
    },
    {
        title: "Class Tests",
        link: "",
        submenu: [
            {
                title: "Subject Wise",
                link: "/classtest/subject"
            },
            {
                title: "Student Wise",
                link: "/classtest/student"
            }
        ]
    },
    {
        title: "Notifications",
        link: "/notifications",
        submenu: []
    },
    {
        title: "Marks Entry",
        link: "",
        submenu: [
            {
                title: "Entry",
                link: "/entryform"
            },
            {
                title: "View",
                link: "/viewform"
            }
        ]
    },

    {
        title: "Certificate Generation",
        link: "",
        submenu: [
            {
                title: "Certificate Type",
                link: "/certificate/certificatetype"
            },
            {
                title: "View/Entry",
                link: "/certificate/certificateview"
            }
        ]
    },

   /*  {
        title: "Admission",
        link: "",
        submenu: [
            {
                title: "Fees Structure",
                link: "/fees",
                submenu: []
            },
            {
                title: "Subject Combination",
                link: "/scombination"
            }
        ]
    }, */
    /* {
        title: "Promotion",
        link: "/promotion",
        submenu: []
    }, */
    {
        title: "Logs",
        link: "/logs",
        submenu: []
    },
    
]


class DrawerMain extends React.Component {
    constructor(props) {
        super(props);

    }

    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
        open: [false, false, false, false]
    };

    handleClick = (e) => {
        let open = this.state.open;
        open[e] = !this.state.open[e];
        this.setState({
            open
        })
    }


    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    render() {
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const { classes } = this.props;

        let menu = [];

        menu = menuListObject.map((el, index) => {

            let i = [];
            let j = [];
            if (el.submenu.length > 0) {
                if (this.state.open[index]) {
                    i = <ExpandLess />
                    j =
                        <Collapse in={this.state.open[index]} timeout="auto" unmountOnExit>
                            <List>
                                {el.submenu.map((el, index) =>
                                    <Link to={el.link} key={index}>
                                        <ListItem button className={classes.listSmall}>
                                            <ListItemText>
                                                <Typography variant="subtitle2">{el.title}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Link>
                                )}
                            </List>
                        </Collapse>
                } else {
                    i = <ExpandMore />
                    j = [];
                }
            }
            return <List disablePadding key={index} >
                {(el.link !== "") ? <Link to={el.link}>
                    <ListItem button onClick={this.handleClick.bind(this, index)}>
                        <ListItemText >
                            <Typography variant="subtitle2">{el.title}</Typography>
                        </ListItemText>
                        {(el.submenu.length > 0) ? i : null}
                    </ListItem>
                </Link> : <ListItem button onClick={this.handleClick.bind(this, index)}>
                        <ListItemText >
                            <Typography variant="subtitle2">{el.title}</Typography>
                        </ListItemText>
                        {(el.submenu.length > 0) ? i : null}
                    </ListItem>}
                {j}
            </List>
        }


        )



        return (
            <div >

                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >
                    <div className={classes.toolbar} />
                    <Divider />
                    {menu}
                </Drawer>
            </div>
        );
    }
}

DrawerMain.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrawerMain);
