import React, { Component } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class StudentWiseReportTable extends Component {
    getData = (data, id, value) => {

        let r = data.filter(el => parseInt(el.id) == parseInt(id));
        if (r.length > 0) {
            return r[0][value];
        } else {
            return '';
        }
    }
    render() {
        return (
            <div>
                <div align="center">
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename={`Classtest Report-${this.props.course}-${this.props.stream}-${this.props.course_type}-${this.props.sub_code}-${this.props.semester}`}
                        sheet="tablexls"
                        buttonText="Download as XLS" />

                </div>


                <Table size="small" id="table-to-xls" style={{ backgroundColor: 'white', padding: '10px' }}>
                    <TableHead>
                        <TableRow>
                            
                            <TableCell>Roll No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Class</TableCell>
                            <TableCell align="center">Tests</TableCell>


                        </TableRow>

                    </TableHead>
                    <TableBody>
                   

                            <TableRow>
                                
                                <TableCell>{this.props.data.roll_no}</TableCell>
                                <TableCell>{this.props.data.name}</TableCell>
                                <TableCell>
                                    {this.props.course} {this.props.stream}, {this.props.course_type}
                                </TableCell>
                                <TableCell>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Test</TableCell>
                                                <TableCell>Subject</TableCell>
                                                <TableCell>Total Marks</TableCell>
                                                <TableCell>Obtained</TableCell>
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.props.data.test.map((el1, index1) =>
                                            <TableRow key={index1}>
                                                <TableCell>{el1.test} on {el1.date}</TableCell>
                                                <TableCell>{el1.sub_code}</TableCell>
                                                <TableCell>{el1.total_marks}</TableCell>
                                                <TableCell>{el1.marks_obtained}</TableCell>
                                                <TableCell>{el1.status}</TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                    
                                </TableCell>

                            </TableRow>

           
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default StudentWiseReportTable;