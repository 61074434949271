import React, { Component } from 'react'
import CourseTable from './EmployeeTable';
import CourseAdd from './EmployeeAdd';
import { LinearProgress } from '@material-ui/core';
import Snack from '../../Snackbar/Snack';
import { PostData, GetData, PutData, DeleteData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import EmployeeModal from './EmployeeModal';



export default class Employee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoading: false,
            status: false,
            message: '',
            table_data: [],
            edit: false,
            edit_data: [],
            departments: [],
            classes_list: [],
            streams: [],
            courses: [],
            isDeptLoaded: false,
            view: false,
            view_data: []
        }

    }

    __loadByLimit = (low_limit, high_limit) => {
        
        GetData(`/${low_limit}/${high_limit}/getstudentbylimit`)
            .then((resp) => {
                //console.log(resp.length)
                 if (resp.length > 0) {
                    let table_data = this.state.table_data;
                    let n = table_data.concat(resp)
                    this.setState({
                        table_data: n,
                        isLoading: false
                    })
                }
            })
    }

    __getData(count) {

        let c = parseInt(parseFloat(count) / 500);
        
        let r = parseFloat(count) - c*500;
        

        for(let i = 0; i < c; i++){
            setTimeout(() => {
                this.__loadByLimit(i * 500, (i + 1) * 500);
            }, 500);
            
        }

        
        this.__loadByLimit(c*500,parseFloat(count));
        
        
    }

    componentDidMount() {
        GetData(`/activestudentcount`)
            .then((count) => {
                this.__getData(count);
            })
        

        GetData('/getdepartment')
            .then((resp) => {
                if (resp.length > 0) {
                    this.setState({
                        departments: resp
                    })
                } else {
                    this.setState({
                        departments: []
                    })
                }
            })
            .then(() => {
                GetData('/getstream')
                    .then((resp) => {
                        this.setState({
                            streams: resp
                        })
                    })
                    .then(() => {
                        GetData('/getcourse')
                            .then((resp) => {
                                this.setState({
                                    courses: resp
                                })
                            })
                            .then(() => {
                                GetData('/getclass')
                                    .then((resp) => {

                                        this.setState({
                                            classes_list: resp,
                                        })
                                    })
                                    .then(() => {
                                        GetData('/getsession')
                                            .then((resp) => {
                                                this.setState({
                                                    sessions: resp,
                                                    isDeptLoaded: true
                                                })
                                            })
                                    })
                            })
                    })
            })
    }


    onAddClick() {
        this.setState({
            show: !this.state.show,
            edit: false,
            status: false,
            message: ''
        })
    }

    setData(data) {
        //send the data to the server

        PostData(`/${this.props.apikey}/addstudent`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp,
                    edit: false
                })
            }).then(() => {
                this.__getData();
            }
            )
    }

    actionClick(id) {
        //fetch edit data

        GetData(`/${id}/getstudentsingle`)
        .then((resp) => {
            console.log(resp)
            if(Array.isArray(resp) && resp.length > 0){
                this.setState({
                    edit: true,
                    edit_data: resp[0],
                    show: true
                })
            }
        })
    }

    esetData(data) {
        //send the data to the server
        //console.log(data)

        PutData(`/${this.props.apikey}/${data.id}/updatestudent`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }

    onDeleteClick(id) {
        this.setState({
            show: false
        })
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm(id) {
        DeleteData(`/${this.props.apikey}/${id}/deletestudent`)
            .then((resp) => {
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }

    onViewClick(el) {
        this.setState({
            view: true,
            view_data: el
        })
    }

    render() {
        return (
            <div>
                {this.state.status ? <Snack
                    open={this.state.status}
                    message={this.state.message}
                    handleClose={() =>
                        this.setState({
                            status: false,
                            message: ''
                        })
                    } /> : null}
                {this.state.isLoading ? <LinearProgress color="secondary" /> : null}


                <CourseAdd
                    show={this.state.show}
                    handleClose={this.onAddClick.bind(this)}
                    setData={this.setData.bind(this)}
                    edit={this.state.edit}
                    edit_data={this.state.edit_data}
                    esetData={this.esetData.bind(this)}
                    onDeleteClick={this.onDeleteClick.bind(this)}
                    departments={this.state.departments}
                    courses={this.state.courses}
                    classes_list={this.state.classes_list}
                    streams={this.state.streams}
                    sessions={this.state.sessions}
                    isDeptLoaded={this.state.isDeptLoaded}
                    apikey={this.props.apikey}
                />

                {this.state.view ? (<EmployeeModal
                    open={this.state.view}
                    handleClose={() => this.setState({
                        view: false,
                        view_data: []
                    })}
                    view_data={this.state.view_data}
                />) : null}

                {!this.state.isLoading ? <CourseTable
                    table_data={this.state.table_data}
                    isLoading={this.state.isLoading}
                    onAddClick={this.onAddClick.bind(this)}
                    actionClick={this.actionClick.bind(this)}
                    onViewClick={this.onViewClick.bind(this)} /> : null}
            </div>
        )
    }
}
