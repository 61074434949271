import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import ExcelUploadLanding from '../Components/ExcelUpload/ExcelUploadLanding';

class ExcelIploadContainer extends Component {
  render() {
    return (
      <div>
        <Navbar history={this.props.history}/>
        <div className="main-body">

          <ExcelUploadLanding />
        
        </div>
      </div>
    )
  }
}

export default withAuth(ExcelIploadContainer);