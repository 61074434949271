import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid, OutlinedInput } from '@material-ui/core';
import { GetData } from '../../../api/service';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        width: '100%',
        margin: theme.spacing.unit
    },
    bottomButton: {
        padding: theme.spacing.unit
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            user_id: '',
            date1: '',
            product: '',
            products: [],
            isProductLoaded: false,
            amount: '',
            discount: '',
            discount_amount: '',
            total: '',
            is_repurchase: 0,
            errors: []
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getInitialState(){
        this.setState({
            id: '',
            user_id: '',
            date1: '',
            product: '',
            amount: '',
            discount: '',
            discount_amount: '',
            total: '',
            is_repurchase: 0,
            errors: []
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })


        if (e.target.name === 'product') {
            // get the mrp and fill it in the amount

            let products = this.state.products;
            let results = products.filter(el => el.id === parseInt(e.target.value));
            if (results.length > 0) {
                this.setState({
                    amount: results[0].mrp
                })
            }
        }

        if (e.target.name === 'discount') {
            let amount = parseFloat(this.state.amount);
            let discount = parseFloat(e.target.value);

            //calculation

            let discount_amount = amount * discount / 100;
            let total = amount - discount_amount;

            this.setState({
                discount_amount: discount_amount.toFixed(2),
                total: total.toFixed(2)
            })
        }

        if (e.target.name === 'discount_amount') {
            let amount = parseFloat(this.state.amount);
            let discount_amount = parseFloat(e.target.value);

            //calculation

            let discount = discount_amount * 100 / amount;
            let total = amount - discount_amount;

            this.setState({
                discount: discount.toFixed(2),
                total: total.toFixed(2),
            })

        }
    }

    componentDidMount() {

        GetData(`/${this.props.apikey}/${this.props.username}/getproductssale`)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    products: resp,
                    isProductLoaded: true
                })
            }).then(() => {

                if (this.props.edit) {
                    this.setState({
                        ...this.props.edit_data
                    })
                } else {
                    this.__getInitialState();
                }

            })
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.__getInitialState();
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        let data = {
            user_id: this.state.user_id,
            date1: this.state.date1,
            product: this.state.product,
            amount: this.state.amount,
            discount: this.state.discount,
            discount_amount: this.state.discount_amount,
            total: this.state.total,
            is_repurchase: 0,
            id: this.state.id
        }
       

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            
        }
        
        this.__getInitialState();

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        let i = [];
        
        if (this.state.isProductLoaded) {
            let p = this.state.products;
            if (Array.isArray(p) && p.length > 0) {
                i = p.map((el, index) =>
                    <MenuItem key={index} value={el.id}>{el.p_name}</MenuItem>
                )
            }
        }

        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>


                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel

                                >
                                    Bill Type
                                </InputLabel>
                                <Select
                                    name="is_repurchase"
                                    value={this.state.is_repurchase}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="is_repurchase"

                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={0}>FRESH</MenuItem>
                                    <MenuItem value={1}>RE-PURCHASE</MenuItem>
                                </Select>
                            </FormControl>



                            <TextField
                                label="Distributor ID"
                                fullWidth
                                className={classes.textField}
                                name="user_id"
                                value={this.state.user_id}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                                error={this.state.errors['user_id']}
                            />


    
                            <TextField
                                label="Date"
                                type="date"
                                variant="outlined"
                                name="date1"
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    },
                                    shrink: true
                                }}
                                value={this.state.date1}
                                onChange={this.onChange}
                            />

                           
                            

                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel
                                    
                                >
                                    Select Product
                                </InputLabel>
                                <Select
                                    name="product"
                                    value={this.state.product}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="product"
                                            
                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {i}
                                </Select>
                            </FormControl>

                            <TextField
                                label="Amount"
                                fullWidth
                                className={classes.textField}
                                name="amount"
                                value={this.state.amount}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                            />

                            <TextField
                                label="Discount (%)"
                                fullWidth
                                className={classes.textField}
                                name="discount"
                                value={this.state.discount}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                            />

                            <TextField
                                label="Discount Amount"
                                fullWidth
                                className={classes.textField}
                                name="discount_amount"
                                value={this.state.discount_amount}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                            />

                            <TextField
                                label="Total"
                                fullWidth
                                className={classes.textField}
                                name="total"
                                value={this.state.total}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                            />
                            <div className={classes.bottomButton}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)