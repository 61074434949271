import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import Delete from '@material-ui/icons/Delete';



export default class StockTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onPrintClick(id){
        this.props.onPrintClick(id)
    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id)
    }

    onClick() {
        console.log("hey");
    }


    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Student Id",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Result Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Semester",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Result",
                options: {
                    filter: true,
                    sort: true,
                }
            },
           
            
            {
                name: "Print",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            {
                name: "Edit",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            {
                name: "Delete",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [index+1,el.student_id, el.type, el.semester, el.result, <Print onClick={this.onPrintClick.bind(this, el.id)} />, <Edit onClick={this.onActionClick.bind(this, el)} />, <Delete onClick={this.onDeleteClick.bind(this, el.id)} />]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Marksheets"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
