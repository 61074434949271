import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import Sel from 'react-select';
import { GetData } from '../../api/service';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        minWidth: 300,
        marginTop: '10px'
    }
})

class SubjectAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            course: '',
            stream: '',
            subjects: [],
            subject_list: [],
            id: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getSubjects = (course, stream) => {

        if (course !== '' && stream !== '') {

            GetData(`/${course}/${stream}/getadmissionsubject`)
                .then((resp) => {
                    console.log(resp)
                    if (Array.isArray(resp)) {

                        resp.map((el, index) => {
                            resp[index]['label'] = el.sub_name;
                            resp[index]['value'] = el.sub_code;
                        })

                        this.setState({
                            subject_list: resp
                        })
                    }
                })
        }

    }

    handleSChange = (name, sub_code) => {
        this.setState({
            [name]: sub_code
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.__getSubjects(this.state.course,this.state.stream)
        })
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {

            this.setState({
                course: '',
                stream: '',
                subjects: [],
                id: ''
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.edit !== this.props.edit) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {

                this.setState({
                    course: '',
                    stream: '',
                    subjects: [],
                    id: ''
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            this.setState({
                course: '',
                stream: '',
                subjects: [],
                subject_list: [],
                id: ''
            })
        }

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        let i = [];
        let course_list = [];
        let stream_list = [];
        if (this.props.isDeptLoaded) {

            let courses = this.props.courses;
            if (Array.isArray(courses) && courses.length > 0) {
                course_list = courses.map((el, index) =>
                    <MenuItem key={index} value={el.name}>{el.name}</MenuItem>
                )
            }

            let streams = this.props.streams;
            if (Array.isArray(streams) && streams.length > 0) {
                stream_list = streams.map((el, index) =>
                    <MenuItem key={index} value={el.stream_name}>{el.stream_name}</MenuItem>
                )
            }
        }


        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <FormControl className={classes.formControl}>
                                <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                <Select
                                    value={this.state.course}
                                    onChange={this.onChange}
                                    name="course"
                                    inputProps={{
                                        name: 'course'
                                    }}

                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {course_list}
                                </Select>
                            </FormControl>
                            <br />
                            <br />

                            <FormControl className={classes.formControl}>
                                <InputLabel shrink className={classes.textStyle}>Select Stream</InputLabel>
                                <Select
                                    value={this.state.stream}
                                    onChange={this.onChange}
                                    name="stream"
                                    inputProps={{
                                        name: 'stream'
                                    }}

                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {stream_list}
                                </Select>
                            </FormControl>
                            <br />
                            <br />

                            <div>
                                <InputLabel shrink className={classes.textStyle}>Select Subject</InputLabel>
                                <Sel
                                    isMulti
                                    value={this.state.subjects}
                                    onChange={this.handleSChange.bind(this, "subjects")}
                                    options={this.state.subject_list}
                                />
                            </div>

                            <br />





                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(SubjectAdd)