import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';
import Done from '@material-ui/icons/Done'
import { lightGreen } from '@material-ui/core/colors';



export default class SubjectTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    showData(value) {
        if (Array.isArray(value) && value.length > 0) {
            let new_arr = value.map((el, index) => el.label);

            return new_arr.join(",");
        } else {
            return '';
        }
    }

    checkStatus = (value) => {
        if (value === 0) {
            return 'NO';
        } else if (value === 1) {
            return 'YES';
        }
    }

    checkAdmission = (value) => {
        if (value === 1) {
            return <Done style={{ color: lightGreen[600] }} />
        } else {
            return null;
        }
    }


    render() {
        const columns = [
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Department",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Discipline",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course Code",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Theory/Practical",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Mark",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Is MIL ?",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Is Admission ?",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Is MAJOR ?",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Is Compulsory ?",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Is Generic ?",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: 'none',
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton><Add onClick={this.props.onAddClick} /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.course + ' - ' + this.showData(el.stream) ,
                    el.dept_code,
                    
                    el.sub_name,
                    el.sub_code,
                    el.sub_type,
                    el.sub_tp_type,
                    `Total: ${el.total_marks} \r\n Pass: ${el.pass_marks}`,
                    this.checkStatus(el.is_MIL),
                    this.checkAdmission(el.is_admission),
                    this.checkAdmission(el.is_major),
                    this.checkAdmission(el.is_compulsory),
                    this.checkAdmission(el.is_generic),
                    <Launch onClick={this.onActionClick.bind(this, el)} />
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Subject List"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        )


    }
}
