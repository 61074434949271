import React, { Component } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class SubjectWiseReportTable extends Component {
    getData = (data, id, value) => {

        let r = data.filter(el => parseInt(el.id) == parseInt(id));
        if (r.length > 0) {
            return r[0][value];
        } else {
            return '';
        }
    }
    render() {
        return (
            <div>
                <div align="center">
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename={`Classtest Report-${this.props.course}-${this.props.stream}-${this.props.course_type}-${this.props.sub_code}-${this.props.semester}`}
                        sheet="tablexls"
                        buttonText="Download as XLS" />

                </div>


                <Table size="small" id="table-to-xls" style={{backgroundColor: 'white', padding: '10px'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan="2">#</TableCell>
                            <TableCell rowSpan="2">Roll No</TableCell>
                            <TableCell rowSpan="2">Name</TableCell>
                            <TableCell rowSpan="2">Class</TableCell>
                            <TableCell rowSpan="2">Subject</TableCell>
                            {this.props.test_label.map((el, index) =>
                                <TableCell colSpan={3} key={index} align="center">{el.name}</TableCell>
                            )}
                            <TableCell rowSpan="2">Overall Marks</TableCell>

                        </TableRow>
                        <TableRow>
                            {this.props.test_label.map((el, index) =>
                                [
                                    <TableCell key={index + 1}>Total</TableCell>,
                                    <TableCell key={index + 2}>Obtained</TableCell>,
                                    <TableCell key={index + 3}>Overall</TableCell>,
                                ]
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.map((el, index) =>

                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{el.roll_no}</TableCell>
                                <TableCell>{el.name}</TableCell>
                                <TableCell>
                                    {this.props.course} {this.props.stream}, {this.props.course_type}
                                </TableCell>
                                <TableCell>{this.props.sub_code}</TableCell>
                                {this.props.test_label.map((el1, index1) =>
                                    [
                                        <TableCell key={index1 + 1}>{this.getData(el.test, el1.id, 'total_marks')}</TableCell>,
                                        <TableCell key={index1 + 2}>{this.getData(el.test, el1.id, 'marks_obtained')}</TableCell>,
                                        <TableCell key={index1 + 3} style={{color: 'red'}}>{this.getData(el.test, el1.id, 'status')}</TableCell>,
                                    ]
                                )}
                                <TableCell style={{ color: 'red' }}>{el.overall_marks}</TableCell>
                            </TableRow>

                        )}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default SubjectWiseReportTable;