import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class CourseTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el){
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    getSmallPortion = (value) => {
        return value.substr(0, 30);
    }



    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Notification",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "To All",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Deactivate",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Department",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Semester",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Stream",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            
            {
                name: "Username",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton><Add onClick={this.props.onAddClick} /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if(table_data.length > 0){
            data = table_data.map((el,index)=>
                [index+1,this.getSmallPortion(el.notification_text),el.to_all,el.is_active,el.deactivate_on,el.to_type,el.to_course,el.to_dept,el.to_sem,el.to_stream,el.by_username,<Launch onClick={this.onActionClick.bind(this,el)} />]
            )
        }else{
            data= [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Notifications"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        )


    }
}
