import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import Notice from '../Components/Notice/Notice';

class NoticeContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            college_code: 'AEC',
            branch: '',
            apikey: '',
            isLoading: true
        }

    }

    componentDidMount() {
        //get Branchcode
        let user = JSON.parse(localStorage.getItem('user'));
        let details = user.user;
        this.setState({
            branch: details.branch_code,
            apikey: details.api_key,
            isLoading: false
        })

    }

    render() {
        return (
            <div>
                <Navbar history={this.props.history} title="DashBoard" breadcumb="HOME / DashBoard" />
                <div className="main-body">

                    {this.state.isLoading ? null :<Notice
                        history={this.props.history}
                        apikey={this.state.api_key}
                        branch={this.state.branch}
                        college={this.state.college_code}
                        />}

                </div>
            </div>
        )
    }
}

export default withAuth(NoticeContainer);