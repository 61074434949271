import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '80%'
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            on_whom: '',
            name_en: '',
            name_as: '',
            description: '',
            by_whom: '',
            on_teacher: 0,
            id: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data,
                name_en: this.props.edit_data.name_en.replace(new RegExp("\\\\", "g"), ""),
                description: this.props.edit_data.description.replace(new RegExp("\\\\", "g"), ""),
            })
        } else {
            this.setState({
                on_whom: '',
                name_en: '',
                name_as: '',
                description: '',
                by_whom: '',
                on_teacher: 0,
                id: ''
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.edit !== this.props.edit) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data,
                    name_en: this.props.edit_data.name_en.replace(new RegExp("\\\\", "g"), ""),
                    description: this.props.edit_data.description.replace(new RegExp("\\\\", "g"), ""),
                })
            } else {
                this.setState({
                    on_whom: '',
                    name_en: '',
                    name_as: '',
                    description: '',
                    by_whom: '',
                    on_teacher: 0,
                    id: ''
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            this.setState({
                on_whom: '',
                name_en: '',
                name_as: '',
                description: '',
                by_whom: '',
                on_teacher: 0,
                id: ''
            })
        }

    }

    onDeleteClick(id){
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>
                            {/* <TextField
                                label="On whom"
                                name="on_whom"
                                className={classes.textField}
                                type="text"
                                autoComplete="current-password"
                                margin="normal"
                                
                                helperText="eg. Teacher, Course etc."
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.on_whom}
                                onChange={this.onChange}
                            /> */}

                            <TextField
                                label="Name (in English)"
                                className={classes.textField}
                                type="text"
                                name="name_en"
                                autoComplete="current-password"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.name_en}
                                onChange={this.onChange}
                            />


                            {/* <TextField
                                label="Name (in Assamese)"
                                className={classes.textField}
                                type="text"
                                name="name_as"
                                autoComplete="current-password"
                                margin="normal"
                                
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.name_as}
                                onChange={this.onChange}
                            /> */}


                            <TextField
                                label="Description"
                                className={classes.textField}
                                type="text"
                                name="description"
                                autoComplete="current-password"
                                margin="normal"
                                
                                multiline
                                rows="4"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.description}
                                onChange={this.onChange}
                            /> 

                            <br />

                            <FormControl className={classes.formControl}>
                                <InputLabel shrink className={classes.textStyle}>By Whom</InputLabel>
                                <Select
                                    value={this.state.by_whom}
                                    onChange={this.onChange}
                                    name="by_whom"
                                    inputProps={{
                                        name: 'by_whom',
                                        className: classes.textField
                                    }}
                                    className={classes.textField}

                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    <MenuItem value="STUDENT">STUDENT</MenuItem>
                                    <MenuItem value="TEACHER">TEACHER</MenuItem>
                                    <MenuItem value="GUARDIAN">GUARDIAN</MenuItem>
                                    <MenuItem value="ALUMNI">ALUMNI</MenuItem>
                                </Select>
                            </FormControl>
                                    
                            <br />

                            <FormControl className={classes.formControl}>
                                <InputLabel shrink className={classes.textStyle}>List Needed</InputLabel>
                                <Select
                                    value={this.state.on_teacher}
                                    onChange={this.onChange}
                                    name="on_teacher"
                                    inputProps={{
                                        name: 'on_teacher',
                                        className: classes.textField
                                    }}
                                    className={classes.textField}

                                >
                                    <MenuItem value="0"><em>NONE</em></MenuItem>
                                    <MenuItem value="1">TEACHER LIST</MenuItem>
                                    <MenuItem value="2">LIBRARY LIST</MenuItem>
                                </Select>
                            </FormControl>


                            <br />
                            <br />
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this,this.props.edit_data.id)}
                                            >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)