import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, TextField, CardActions, Button } from '@material-ui/core';
import Build from '@material-ui/icons/Build'
import { PostData } from '../../../api/service';
import Snack from '../../Snackbar/Snack';
import PasswordChangeMultiple from './PasswordChangeMultiple'

const styles = theme => ({
    buttonCard: {
        float: 'right'
    }
})

class PasswordChange extends Component {
    state = {
        email: '',
        errors: [],
        open: false,
        message: ''
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: this.state.email
        }

        PostData(`/${this.props.apikey}/forcechagepassword`, data)
        .then((resp) => {
            
                this.setState({
                    open: true,
                    message: resp,
                    email: ''
                })
            
        })

    }



    render() {
        let { classes } = this.props;
        return (
            <div>

                {this.state.open ? <Snack
                    open={this.state.open}
                    message={this.state.message}
                    handleClose={()=> {
                        this.setState({
                            open: false,
                            message: ''
                        })
                    }}
                />: null}


                <form onSubmit={this.onSubmit}>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <Build />
                            </Avatar>
                        }
                        title={`Force Password Change`}
                    />

                    <CardContent>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Type the Username/Email here"
                            name="email"
                            className={classes.textField}
                            type="text"
                            margin="normal"
                            required
                            InputLabelProps={{
                                classes: {
                                    root: classes.textStyle
                                }
                            }}
                            value={this.state.email}
                            onChange={this.onChange}
                            helperText={this.state.errors['email']}
                            FormHelperTextProps={{
                                error: true
                            }}
                        />
                    </CardContent>
                    <CardActions className={classes.buttonCard}>
                        <Button
                            variant='outlined'
                            color="primary"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </CardActions>
                </Card>
                </form>

                <br />

                <PasswordChangeMultiple />
            </div>
        )
    }
}

export default withStyles(styles)(PasswordChange)