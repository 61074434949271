import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';



export default class CourseTable extends Component {

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    padding: "2px"
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    padding: "2px"
                }
            }
        }
    })


    state = {
        tableState: this.props.table_data,
        filterList: [[], [], [], [], [], [], [], [], [], []]
    }

    constructor(props, context, state) {
        super(props, context);
    }


    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }


    onFilterChange = (changedColumn, filterList, type) => {
        console.log(filterList)
    }

    handleOnClick(rowObject){
        
        this.setState({
            ...this.state
        });
    }

    onChange(tableState) {
        this.setState({
            tableState: tableState
        });
    }


    render() {

        const {filterList } = this.state;
        
        const columns = [
            {
                name: "Day",
                options: {
                    filter: true,
                    filterList: filterList[0].length ? filterList[0] : null,
                    sort: true,
                }
            },
            {
                name: "Class",
                options: {
                    filter: true,
                    filterList: filterList[1].length ? filterList[1] : null,
                    sort: true,
                }
            },
            {
                name: "Type",
                options: {
                    filter: true,
                    filterList: filterList[2].length ? filterList[2] : null,
                    sort: true,
                }
            },
            {
                name: "Subject Code",
                options: {
                    filter: true,
                    filterList: filterList[3].length ? filterList[3] : null,
                    sort: true,
                }
            },
            {
                name: "Semester",
                options: {
                    filter: true,
                    filterList: filterList[4].length ? filterList[4] : null,
                    sort: true,
                }
            },
            {
                name: "Teacher",
                options: {
                    filter: true,
                    filterList: filterList[5].length ? filterList[5] : null,
                    sort: true,
                }
            },
            {
                name: "Room Number",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Period",
                options: {
                    filter: true,
                    filterList: filterList[7].length ? filterList[7] : null,
                    sort: true,
                }
            },
            {
                name: "Start Time",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false,
                    customBodyRender: (value) => {
                            return  <Launch onClick={this.onActionClick.bind(this, value)} />;
                    }
                }
            }
        ];

        let options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onFilterChange: (changedColumn, filterList, type) => {
                console.log(filterList)
                this.setState({filterList})
            },
            customToolbar: () => {
                return (
                    <IconButton onClick={this.props.onAddClick}><Add /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.day,
                    el.course + ' - ' + el.stream,
                    el.course_type,
                    el.sub_code,
                    el.semester,
                    el.teacher_name,
                    el.room_number,
                    el.period_number,
                    el.start_time + '-' + el.end_time,
                    el
                ]
            )
        } else {
            data = [];
        }

        // let options = {
        //     filter: true,
        //     selectableRows: true,
        //     usePaperPlaceholder: false,
        //     filterType: 'multiselect',
        //     responsive: 'stacked',
        //     rowCursorHand: true,
        //    /* rowsSelected: [1],*/
        //     showSelectedRowsToolbar: true,
        //     onlyOneRowCanBeSelected: false,
        //     componentWillReceiveProps: true,
        //     page: 0,
        //     sortColumnIndex: 2,
        //     sortColumnDirection: "desc",
        //     filterList: [["Mon"], [], [], [], [], [], []],
        //     onTableChange: (action, state) => this.props.onChange(state),
        //     onRowClick: (rowObject) => this.handleOnClick(rowObject)
        // };

        // if (this.props.tableState !== undefined && this.props.tableState !== null) {
        //     options.filterList = this.props.tableState.filterList;
        //     options.searchText = this.props.tableState.searchText;
        //     options.page = this.props.tableState.page;
        //     options.rowsPerPage = this.props.tableState.rowsPerPage;
        //     options.sortColumnDirection = this.props.tableState.sortColumnDirection;
        //     options.sortColumnIndex = this.props.tableState.sortColumnIndex;
        //     options.rowsSelected = this.props.tableState.rowsSelected;
        // }

        

        return (
            <div>


                <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                        title={"Routine"}
                        data={data}
                        columns={columns}
                        options={options}

                    />
                </MuiThemeProvider>
            </div>
        )


    }
}
