import React, { Component } from 'react'
import { withStyles, Grid, Fade, CircularProgress } from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';
import NoticeCard from './NoticeCard';
import { GetData } from './../../api/service';

const styles = theme => ({
    container: {
        width: '100%',
        minHeight: '100vh',
        width: '100%',
        padding: '20px'
    },
    gridStyle: {
        backgroundColor: 'white',
        padding: '15px'
    },
    type: {
        fontSize: 15
    },
    loaderContainer:{
        width: '100%',
        textAlign: 'center',
        display:'flex',
        flexDirection: 'column',
        height: '80vh',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

class Notice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notices: [],
            isLoading: true
        }

    }

    componentDidMount() {
        GetData(`/${this.props.college}/${this.props.branch}/getnotificationcollege`)
            .then((resp) => {
                if (resp.length > 0) {
                    this.setState({
                        notices: resp,
                        isLoading: false
                    })
                }
            })
    }

    render() {
        let { classes } = this.props;

        let i = [];
        if (!this.state.isLoading) {
            let notices = this.state.notices;
            if(notices.length > 0){
                i = notices.map((el, index) =>
                    <Fade in={true} key={index}>
                        <Grid item xs={12} lg={12} md={12} >
                            <NoticeCard
                                {...el}
                            />
                        </Grid>
                    </Fade>
                )
            }
            
        } else {
            i = <div key="0" className={classes.loaderContainer}>
                <CircularProgress />
                </div>;
        }
        return (
            <div className={classes.container}>
                <Grid container spacing={24}>
                    {i}
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Notice);