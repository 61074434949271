import React, { Component } from 'react'
import CourseTable from './EmployeeTable';
import CourseAdd from './EmployeeAdd';
import { LinearProgress } from '@material-ui/core';
import Snack from '../../Snackbar/Snack';
import { PostData, GetData, PutData, DeleteData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import EmployeeModal from './EmployeeModal';



export default class Employee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoading: true,
            status: false,
            message: '',
            table_data: [],
            edit: false,
            edit_data: [],
            departments: [],
            isDeptLoaded: false,
            view: false,
            view_data: [],
            subject_list: []
        }

    }

    __getData() {
        GetData('/getemployee')
            .then((resp) => {
                if (resp.length > 0) {
                    this.setState({
                        table_data: resp,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        table_data: [],
                        isLoading: false
                    })
                }
            })
    }

    componentDidMount() {
        this.__getData();

        GetData('/getdepartment')
            .then((resp) => {
                if (resp.length > 0) {
                    this.setState({
                        departments: resp,
                        isDeptLoaded: true
                    })
                } else {
                    this.setState({
                        departments: [],
                        isDeptLoaded: true
                    })
                }
            })
    }


    onAddClick() {
        this.setState({
            show: !this.state.show,
            edit: false
        })
    }

    setData(data) {
        //send the data to the server

        PostData(`/${this.props.apikey}/addemployee`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp,
                    edit: false
                })
            }).then(() => {
                this.__getData();
            }
            )
    }

    actionClick(el) {
        this.setState({
            edit: true,
            edit_data: el,
            show: true
        })
    }

    esetData(data) {
        //send the data to the server

        PutData(`/${this.props.apikey}/${data.id}/updateemployee`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }

    onDeleteClick(id) {
        this.setState({
            show: false
        })
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm(id) {
        DeleteData(`/${this.props.apikey}/${id}/deleteemployee`)
            .then((resp) => {
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }

    onViewClick(el) {
        this.setState({
            view: true,
            view_data: el
        })
    }

    render() {
        return (
            <div>
                {this.state.status ? <Snack
                    open={this.state.status}
                    message={this.state.message}
                    handleClose={() =>
                        this.setState({
                            status: false,
                            message: ''
                        })
                    } /> : null}
                {this.state.isLoading ? <LinearProgress color="secondary" /> : null}


                <CourseAdd
                    show={this.state.show}
                    handleClose={this.onAddClick.bind(this)}
                    setData={this.setData.bind(this)}
                    edit={this.state.edit}
                    edit_data={this.state.edit_data}
                    esetData={this.esetData.bind(this)}
                    onDeleteClick={this.onDeleteClick.bind(this)}
                    departments={this.state.departments}
                    isDeptLoaded={this.state.isDeptLoaded}
                    apikey={this.props.apikey}
                />

                <EmployeeModal
                    open={this.state.view}
                    handleClose={() => this.setState({
                        view: false,
                        view_data: []
                    })}
                    view_data={this.state.view_data}
                />

                {!this.state.isLoading ? <CourseTable
                    table_data={this.state.table_data}
                    isLoading={this.state.isLoading}
                    onAddClick={this.onAddClick.bind(this)}
                    actionClick={this.actionClick.bind(this)}
                    onViewClick={this.onViewClick.bind(this)} /> : null}
            </div>
        )
    }
}
