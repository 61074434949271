import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { Typography, withStyles, Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import PieChart from '@material-ui/icons/PieChart'
import Style from '@material-ui/icons/Style'
import { purple, lightBlue } from '@material-ui/core/colors';
import { GetData } from './../../../api/service';
import ViewModule from '@material-ui/icons/ViewModule'
import Work from '@material-ui/icons/Work'
import TableChart from '@material-ui/icons/TableChart'

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    details: {
        display: 'flex',
        flexDirection: 'row'
    },
    content: {
        flex: '1 0 auto'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 22,
    },
    pos: {
        marginBottom: 12,
    },
    backPrimary: {
        backgroundColor: '#d500f9',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSecondary: {
        backgroundColor: '#e91e63',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSec: {
        backgroundColor: '#009688',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    approve: {
        backgroundColor: purple['A400']
    },
    approveGrid: {

    },
    bigCard: {
        backgroundColor: lightBlue[600],
    },
    dashCard: {
        position: 'relative'
    },
    headColor: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        width: '100px',
        height: '100px',
        marginLeft: '20px',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: lightBlue[300]
    },
    backpanel: {
        position: 'absolute',
        top: 0,
        left: 0,
        marginTop: '40px',
        width: '100%',
        zIndex: 1,
        minHeight: '25vh',
        textAlign: 'right'

    },
    iconFont: {
        fontSize: 50
    },
    percent: {
        fontSize: 30
    },
    colorRed: {
        color: purple[300],
        fontSize: 12,
    },
    hh: {
        minHeight: '40vh'
    }
})

class ReportsFeedback extends Component {

    state = {
        count: {
            teacher_count: 0,
            student_count: 0
        }
    }

    componentDidMount() {
        GetData(`/${this.props.apikey}/getfeedbackreportcount`)
        .then((resp) => {
           this.setState({
               count: resp
           })
        })
    }
    
    
    render() {
        let {classes} = this.props;
        return (
            <div>

                
                    <CardHeader
                        title={
                            <Typography
                                variant="h5"
                            >
                                Feedback Reports
                            </Typography>
                        }

                    />


                    <CardContent>

                        <Grid container spacing={2}>

                            <CardForDash
                                {...this.props}
                                title="Feedback"
                                value={this.state.count['student_count']}
                                subText="Students"
                                icon={<PieChart className={classes.iconFont} />}
                            />

                            <CardForDash
                                {...this.props}
                                title="Feedback"
                                value={this.state.count['teacher_count']}
                                subText="Teachers"
                                icon={<Style className={classes.iconFont} />}
                            />

                        </Grid>

                        </CardContent>

                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={2} lg={3} sm={6}>
                            <Link to="/feedback/reports/students">
                                <Card>
                                <CardContent align="center">

                                        <TableChart
                                            style={{ fontSize: 50 }}
                                        />
                                    
                                    <Typography
                                        variant="h6"
                                    >
                                        Student Report
                                    </Typography>
                                        <Typography
                                            variant="caption"
                                        >
                                            Click here
                                    </Typography>
                                </CardContent>
                            </Card>
                            </Link>
                        </Grid>

                        <Grid item xs={6} md={2} lg={3} sm={6}>
                            <Link to="/feedback/reports/teachers">
                                <Card>
                                    
                                    <CardContent align="center">
                                        <Work
                                            style={{ fontSize: 50 }}
                                        />
                                        <Typography
                                            variant="h6"
                                        >
                                            Teacher Report
                                    </Typography>
                                        <Typography
                                            variant="caption"
                                        >
                                            Click here
                                    </Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                        <Grid item xs={6} md={2} lg={3} sm={6}>
                            <Link to="/feedback/reports/analysis">
                                <Card>
                                    <CardContent align="center">

                                        <ViewModule
                                            style={{fontSize: 50}}
                                        />
                                        <Typography
                                            variant="h6"
                                        >
                                            Feedback Analysis
                                    </Typography>

                                        <Typography
                                            variant="caption"
                                        >
                                            Click here
                                    </Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                        
                    </Grid>

                </CardContent>



                   


            </div>
        )
    }
}

export default withStyles(styles)(ReportsFeedback);


const CardForDash = (props) => {
    let { classes } = props;
    return (
        <Grid item xs={12} md={4} sm={12} lg={4} className={classes.hh}>

            <div className={classes.dashCard}>
                <Card className={classes.headColor}>
                    {props.icon}
                </Card>

                <Card className={classes.backpanel} raised>
                    <CardHeader
                        title={props.title}
                    />
                    <CardContent>
                        <div className={classes.percent}>{props.value}</div>
                        {props.subText}
                                    </CardContent>
                </Card>
            </div>
        </Grid>
    )
}