import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom'
import Employee from './Add/Employee';
import StudentImport from './import/StudentImport';
import StudentQuickForm from './import/StudentQuickForm';

export default class StudentLanding extends Component {
    constructor(props) {
        super(props);

        let ap = JSON.parse(localStorage.getItem('apikey'));

        this.state = {
            apikey: ap
        }

    }
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/student" render={(props) => <Employee apikey={this.state.apikey} {...props} />} />
          <Route exact path="/student/import" render={(props) => <StudentImport apikey={this.state.apikey} {...props} />} />
          <Route exact path="/student/form" render={(props) => <StudentQuickForm apikey={this.state.apikey} {...props} />} />
        </Switch>
        
      </div>
    )
  }
}
