import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Button } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
import Add from '@material-ui/icons/Add';



export default class PromotionTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false,
            page: 0,
            limit_low: 101
        }
    }

    componentDidMount() {
        this.setState({
            data: this.props.table_data
        })
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }


    render() {

        let keys = Object.keys(this.props.table_data[0]);

        let columns = [];

        keys.map((el,index) => {
            let a = {};
            a = {
                name: el,
                options: {
                    filter: true,
                    sort: true,
                }
            }

            columns.push(a);
        })

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
        };

        let data = [];

        if (this.props.table_data.length > 0) {
            this.props.table_data.map((el, index) =>
                {
                    let a = [];
                    keys.map((el1,index1) => {
                        a.push(el[el1]);
                    })
                    data.push(a);
                }
            )
        } else {
            data = [];
        }

        return (
            <div>

                

                <MUIDataTable
                    title={"Student List"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        )


    }
}
