import React, { Component } from 'react'
import ReactFileReader from 'react-file-reader'
import { Button } from '@material-ui/core';
import { PostData } from '../../api/service';

import Done from '@material-ui/icons/Done';
import Warning from '@material-ui/icons/Warning';


class ExcelUploadLanding extends Component {

    constructor(props) {
        super(props);

        this.state = {
            students: [],
            isLoaded: false
        }

    }


    setValue = (value) => {
        this.setState({
            students: value,
            isLoaded: true
        })
    }


    handleFiles = (files) => {
        var reader = new FileReader();

        reader.onload = (e) => {
            // Use reader.result
            let b = [];

            let r = reader.result;
            let a = r.split('\n');
            b = a.map(el => {
                let e = el.split(',');
                if (e[0] !== undefined && e[1] !== undefined && e[2] !== undefined) {
                    let ph = e[2];
                    let ph1 = ph.split(',');

                    return {
                        email: e[1],
                        name: e[0],
                        phone: parseInt(ph1[0].replace(/['"]+/g, '')),
                        status: 0
                    }
                }

            });
            
            this.setValue(b)



            
        }

        reader.readAsText(files[0]);
    }

    onSubmit = () => {
        let b = this.state.students;

        if(this.state.isLoaded){
             b.map((el, index) => {
                if (el !== undefined) {

                    /* PostData(`/123/addstudentsort`, el)
                        .then((resp) => {
                            console.log(resp, index)
                        }) */
                }

            }) 
            

            b.map((el,index) => {
                if(el !== undefined){
                    if(this.validatePhone(el.phone)){
                        el['status'] = 1;
                        this.setState({
                            students: b
                        })
                    }
                }
                
            })

            

            
        }
        
    }

    checkPhone = (value) => {

        if (value.toString().length === 10){
            return value;
        }else{
            return <span style={{color: 'red'}}>{value}</span>
        }
        
    }

    validatePhone = (value) => {

        if (value.toString().length === 10) {
            return true;
        } else {
            return false;
        }

    }


    getStatus = (value) => {
        if(value === 0){
            return <Warning style={{color:'red'}} />;
        }else if(value === 1){
            return <Done style={{color: 'green'}} />;
        }
    }
    render() {
        let i = [];
        if (this.state.isLoaded) {
            let students = this.state.students;
            if (Array.isArray(students) && students.length > 0) {
                i = students.map((el, index) => {
                    if (el !== undefined) {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.name}</td>
                                <td>{el.email}</td>
                                <td>{this.checkPhone(el.phone)}</td>
                                <td>{this.getStatus(el.status)}</td>
                            </tr>
                        )
                    }
                }

                )
            }
        }
        return (
            <div>
                <ReactFileReader handleFiles={this.handleFiles.bind(this)} fileTypes={'.csv'}>
                    <Button className='btn'>Upload</Button>
                </ReactFileReader>

                {this.state.isLoaded ? <div style={{float: 'right'}}>
                    <Button 
                    type="button" 
                    color="primary"
                    variant="contained"
                    onClick={this.onSubmit}
                    >
                        Submit List
                    </Button>
                </div> : null}
                <br />
                <br />
                <br />
                   

                <table width="100%" border="1px">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {i}
                    </tbody>
                </table>


            </div>
        )
    }
}


export default ExcelUploadLanding;