import React, { useState, useEffect } from 'react'
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core'
import { GetData, PostData } from '../../../api/service'
import readXlsxFile from 'read-excel-file'

import {
  GENDER_LIST,
  CASTE_LIST,
  RELIGION_LIST,
  COURSE_TYPE_LIST,
} from '../../../Helper/GlobalConstants'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

export default function StudentBulkLanding({ apikey }) {
  const [courses, setCourses] = useState([])
  const [isCoursesLoaded, setIsCoursesLoaded] = useState(false)
  const [subjects, setSubjects] = useState([])
  const [isSubjectsLoaded, setIsSubjectsLoaded] = useState(false)



  const [data, setData] = useState([])
  const [resultData, setResultData] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [resultLoaded, setResultLoaded] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [errorList, setErrorList] = useState([])

  React.useEffect(() => {
    if (!isSubjectsLoaded) {
      GetData('/getsubject').then((resp) => {
        if (resp.length > 0) {
          setSubjects(resp)
        }
        setIsSubjectsLoaded(true)
      })
    }

    if (!isCoursesLoaded) {
      GetData('/getcourse').then((resp) => {
        if (resp.length > 0) {
          setCourses(resp)
        }
        setIsCoursesLoaded(true)
      })
    }
  }, [isCoursesLoaded, isSubjectsLoaded])

  function loadFromExcel() {
    const input = document.getElementById('input')
    input.addEventListener('change', () => {
      readXlsxFile(input.files[0]).then((rows) => {
        let temp_rows = rows
        temp_rows.splice(0, 1) // remove the first line as it contains only headers
        let dd = []
        let err = []

        let courses_short = []
        courses.map((el) => {
          courses_short.push(el.name)
        })

        let subjects_short = []
        subjects.map((el) => {
          subjects_short.push(el.sub_code)
        })

        temp_rows.map((el) => {
          console.log("🚀 ~ file: StudentImport.js ~ line 81 ~ temp_rows.map ~ el", el)
          if (el[0] != '') {
            let ok = 0
            if (courses_short.includes(el[2])) {
              ok = 1
            } else {
              ok = 0
              console.log('loadFromExcel -> courses_short', courses_short)
              err.push(
                `Error: in Course line with Name ${el[5]}. Course is not listed.`,
              )
            }

            if (el[4] != '' && el[4] != null) {
              if (COURSE_TYPE_LIST.includes(el[4])) {
                ok = 1
              } else {
                ok = 0
                err.push(
                  `Error: in Course Type line with Name ${el[5]}. Course Type (Honours/Regular) is not listed.`,
                )
              }
            }

            if (el[9] != '' && el[9] != null) {
              if (GENDER_LIST.includes(el[9])) {
                ok = 1
              } else {
                ok = 0
                err.push(
                  `Error: in Gender line with Name ${el[5]}. Gender is not listed.`,
                )
              }
            }

            if (el[10] != '' && el[10] != null) {
              if (CASTE_LIST.includes(el[10])) {
                ok = 1
              } else {
                ok = 0
                err.push(
                  `Error: in Caste line with Name ${el[5]}. Caste is not listed.`,
                )
              }
            }

            // ready the subjects el[11],el[12],el[13],el[14],el[15],el[16],el[17]

            let subjectsList = []
            ;[11, 12, 13, 14, 15, 16, 17].map((el1) => {
              if (el[el1] != '' && el[el1] != undefined) {
                if (subjects_short.includes(el[el1])) {
                  ok = 1
                  subjectsList.push(el[el1])
                } else {
                  ok = 0
                  err.push(
                    `Error: in Subject line with Name ${el[5]}. No subject found with that code`,
                  )
                }
              }
            })

            // ready the address el[18] - 1,el[19] - district,el[20] - state,el[21] - pin,el[22] - po,el[23] - ps
            let present_address = []
            ;[18, 19, 20, 21, 22, 23].map((el1) => {
              if (el[el1] != '' && el[el1] != undefined) {
                present_address.push(el[el1])
              }
            })

            // ready the permanent address el[24] - 1,el[25] - district,el[26] - state,el[27] - pin,el[28] - po,el[29] - ps
            let permanent_address = []
            ;[24, 25, 26, 27, 28, 29].map((el1) => {
              if (el[el1] != '' && el[el1] != undefined) {
                permanent_address.push(el[el1])
              }
            })

            if (el[30] != '' && el[30] != undefined && el[30] != null) {
              if (RELIGION_LIST.includes(el[30])) {
                ok = 1
              } else {
                ok = 0
                err.push(
                  `Error: in Religion line with Name ${el[5]}. Religion is not listed.`,
                )
              }
            }

            if (ok == 1) {
              dd.push({
                year_of_admission: el[0],
                roll_no: el[1],
                course: el[2],
                stream: 'SCIENCE',
                term: el[3],
                course_type: el[4],
                name: el[5],
                father_name: el[6],
                phone: el[7],
                email: el[8],
                gender: el[9],
                caste: el[10],
                subjects: subjectsList,
                present_address: present_address,
                permanent_address: permanent_address,
                religion: el[30],
                is_phd: el[31],
                is_ews: el[32],
                account_holder: el[33],
                account_no: el[34],
                bank_branch: el[35],
                bank: el[36],
                bank_ifsc: el[37],
                profile_photo: el[38],
                signature_photo: el[39],
                educational_data: el[40],
                department: el[41],
                student_id: 'BMB20'+zeroPad(el[42], 4),
                status: 'not inserted',
              })
            }
          }
        })
        //console.log(dd);
        setErrorList(err)

        setData(dd)

        setIsLoaded(true)
      })
    })
  }

  useEffect(() => {
    if (
      Array.isArray(courses) &&
      courses.length > 0 &&
      Array.isArray(subjects) &&
      subjects.length > 0
    ) {
      loadFromExcel()
    }

    return () => {
      const input = document.getElementById('input')
      input.removeEventListener('change', () => null)
    }
  }, [courses, subjects])

  const finalSubmit = () => {
    setIsSubmitting(true)
    PostData(`/${apikey}/addstudentexcel`, data).then((resp) => {
      setIsSubmitting(false)
      setIsLoaded(false)
      setData([])
      setResultData(resp)
      setResultLoaded(true)
    })
  }

  const zeroPad = (num, places) => String(num).padStart(places, '0')
  return (
    <div>
      <Typography variant="h4">Import Student from Excel</Typography>

      <Typography variant="body1" gutterBottom>
        The excel file must be in a specific format. To download the format,
        please{' '}
        <a
          href={require('./../../../assets/excel_format_for_import.xlsx')}
          download
          style={{ color: 'blue', fontWeight: 'bold', cursor: 'pointer' }}
        >
          Click Here
        </a>
      </Typography>
      <Alert severity="Info">
        <AlertTitle>Note</AlertTitle>
        <ul>
          <li>
            Ensure that you serialise the data accroding to the file uploaded
            above.
          </li>
          <li>
            You must insert all the subject codes in their respective fields in
            the excel.
          </li>
          <li>
            List of errors in the document will be shown a Error tab along with
            the name of the student. Correct the data and then upload again
          </li>
          <li>
            Double entry will be discared by server, we check double entry by
            roll_no, name, course, semester. So please provide a unique roll
            numbers to all the students.
          </li>
          <li>
            Donot delete any column which fields are empty. Leave it blank if
            you don't want to add it.
          </li>
        </ul>
      </Alert>

      <Typography variant="body1">Please upload the file here</Typography>
      <input className="form-control" type="file" id="input" />

      <br />

      {isLoaded ? (
        <div>
          {/* Error List */}

          {Array.isArray(errorList) && errorList.length > 0 ? (
            <Alert severity="error">
              <AlertTitle>Errors</AlertTitle>
              <ul>
                {errorList.map((el, index) => (
                  <li key={index}>{el}</li>
                ))}
              </ul>
            </Alert>
          ) : null}

          {Array.isArray(data) && data.length > 0 ? (
            <React.Fragment>
              <Paper style={{ overflowX: 'scroll' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Student ID</TableCell>
                      <TableCell>Year of Admission</TableCell>
                      <TableCell>Roll No</TableCell>
                      <TableCell>Course</TableCell>
                      <TableCell>Term</TableCell>
                      <TableCell>Course Type</TableCell>
                      <TableCell>Major Subject</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Father Name</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Gender</TableCell>
                      <TableCell>Caste</TableCell>
                      <TableCell>Religion</TableCell>
                      <TableCell>Subjects</TableCell>
                      <TableCell>Present Address</TableCell>
                      <TableCell>Permanent Address</TableCell>
                      <TableCell>Account Holder</TableCell>
                      <TableCell>Account No</TableCell>
                      <TableCell>Bank </TableCell>
                      <TableCell>Branch</TableCell>
                      <TableCell>IFSC</TableCell>
                      <TableCell>Profile Photo</TableCell>
                      <TableCell>Signature Photo</TableCell>
                      <TableCell>Educational Details</TableCell>
                      <TableCell>Department</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((el, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{el.student_id}</TableCell>
                        <TableCell>{el.year_of_admission}</TableCell>
                        <TableCell>{el.roll_no}</TableCell>
                        <TableCell>{el.course} {el.stream}</TableCell>
                        <TableCell>{el.term}</TableCell>
                        <TableCell>{el.course_type}</TableCell>
                        <TableCell>{el.major_subject}</TableCell>
                        <TableCell>{el.name}</TableCell>
                        <TableCell>{el.father_name}</TableCell>
                        <TableCell>{el.phone}</TableCell>
                        <TableCell>{el.email}</TableCell>
                        <TableCell>{el.gender}</TableCell>
                        <TableCell>{el.caste}</TableCell>
                        <TableCell>{el.religion}</TableCell>
                        <TableCell>{JSON.stringify(el.subjects)}</TableCell>
                        <TableCell>
                          {JSON.stringify(el.present_address)}
                        </TableCell>
                        <TableCell>
                          {JSON.stringify(el.permanent_address)}
                        </TableCell>
                        <TableCell>{el.account_holder}</TableCell>
                        <TableCell>{el.account_no}</TableCell>
                        <TableCell>{el.bank}</TableCell>
                        <TableCell>{el.bank_branch}</TableCell>
                        <TableCell>{el.bank_ifsc}</TableCell>
                        <TableCell>{el.profile_photo}</TableCell>
                        <TableCell>{el.signature_photo}</TableCell>
                        <TableCell>
                          {JSON.stringify(el.educational_data)}
                        </TableCell>
                        <TableCell>{el.department}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>

              <br />
              <br />

              <Button
                variant="contained"
                color="primary"
                onClick={finalSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? `Please wait . .. ` : `Final Submit`}
              </Button>
            </React.Fragment>
          ) : null}
        </div>
      ) : null}

      {resultLoaded ? (
        <div>
          {Array.isArray(resultData) && resultData.length > 0 ? (
            <Alert severity="success">
              <AlertTitle>Results</AlertTitle>
              <ul>
                {resultData.map((el, index) => (
                  <li key={index}>{el}</li>
                ))}
              </ul>
            </Alert>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
