import React, { Component } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import { withStyles, Card, CardContent, Typography } from '@material-ui/core';
import Report from '@material-ui/icons/Report';
import { lightBlue, red } from '@material-ui/core/colors';

import CertificateType from './CertificateType/CertificateType';
import CertificateView from './CertificateView/CertificateView';

import PrintCer from './CertificateView/Print/PrintCer';

const styles = theme => ({
    smallItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'all 0.1s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    ava: {
        width: 80,
        height: 80,
        backgroundColor: lightBlue[300],
        marginBottom: '8px',
    },
    icon: {
        fontSize: 35
    },
    noAccessDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: red[700]
    },
    noAccessIcon: {
        color: red[300]
    },
    navBarSmall: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 14,
        marginTop: '10vh',
        marginLeft: 240,
        backgroundColor: 'rgba(0,0,0,0.8)',
        height: '7vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: '7vh',
            height: '10vh',
            overflowX: 'scroll',
        }
    },
    navBarItem: {
        color: 'white',
        paddingLeft: '10px',
        paddingRight: '10px',
        '&:hover': {
            color: 'blue'
        },
        borderRight: 'solid thin white',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '40%',
            paddingLeft: '10px',
            paddingRight: '10px',
        }
    },
    
});

// const newNavs = [

//     {
//         title: 'Certificate Type',
//         path: '/certificate/certificatetype'
//     },
//     {
//         title: 'Certificate View',
//         path: '/certificate/certificateview'
//     }
    
// ]

class CertificateLanding extends Component {
    constructor(props) {
        super(props);

        let stat = false;

        let user = JSON.parse(localStorage.getItem('user'));
        let access = user.user.access;
        if (Array.isArray(access) && access.length > 0) {
            access.map((el, index) => {
                if (el.access_field = 'ACC') {//change here ===
                    stat = true;
                }
            })
        }

        this.state = {
            access_egov: stat
        }
    }

    render() {
        let { classes } = this.props;
  
        
            return (
                <div>
                    
                
                    <div className={classes.container}>
                        <Switch>
                            

                            <Route exact path="/certificate/certificatetype"
                                render={() => <CertificateType
                                    link={`getcertificate`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/certificate/certificateview"
                                render={() => <CertificateView
                                    link={`getcertificate`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />


                            <Route exact path="/certificate/print"
                                render={() => <PrintCer
                                    link={`getcertificate`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />


                        </Switch>
                    </div>

                    

                    <br />

                </div>
            )
        

    }
}

export default withStyles(styles)(CertificateLanding);

