import React, { Component } from 'react'
import { Card, CardHeader, Avatar, Typography, CardContent, FormControl, InputLabel, Select, MenuItem, OutlinedInput, CardActions, Button, withStyles, TextField, CircularProgress, Grid } from '@material-ui/core';
import Receipt from '@material-ui/icons/Receipt'
import { lightGreen } from '@material-ui/core/colors';
import Search from '@material-ui/icons/Search'
import { PostData, GetData } from '../../api/service';
import AttendanceReportTable from './AttendanceReportTable';
import Sel from 'react-select'
import AttendanceViewStudent from './AttendanceViewStudent';
import ReactToPrint from 'react-to-print';


const styles = theme => ({
    actions: {
        justifyContent: 'flex-end'
    },
    condtionDiv: {
        padding: theme.spacing.unit * 2,
        backgroundColor: lightGreen[50]
    },
    textField: {
        width: '50%'
    }
})

class StudentWiseAttendanceReport extends Component {
    state = {
        start_date: '',
        end_date: '',
        course: '',
        course_type: '',
        stream: '',
        semester: '',
        student_id: '',
        student_obj: {},
        students: [],
        isLoaded: false,
        data: [],
        loading: false,
        isStudentLoaded: false
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.__getStudentList(this.state.course, this.state.stream, this.state.course_type, this.state.semester)
        })
    }

    setTable = (name, value) => {

        this.setState({
            [name]: value
        })
    }

    __getStudentList = (course, stream, course_type, semester) => {
        if (course != '' && stream != '' && semester != '') {
            let d = {
                course, stream, course_type, semester
            }
            PostData(`/getshortstudentlist`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        students: resp,
                        isStudentLoaded: true
                    })
                })

        }

    }




    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        })

        let data = {
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            course: this.state.course,
            stream: this.state.stream,
            course_type: this.state.course_type,
            semester: this.state.semester,
            id: this.state.student_id
        }


        PostData(`/getstudentattendancereportsstudent`, data)
            .then((resp) => {
                //console.log(resp)
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp[0],
                        isLoaded: true,
                        loading: false
                    })
                }

            })

    }

    onSubjectChange = (type, value) => {
        this.setState({
            [type]: value
        })

        if (type == 'student_obj') {
            this.setState({
                student_id: value.value
            })
        }

    }
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar style={{ backgroundColor: lightGreen[300] }}>
                                    <Receipt />
                                </Avatar>
                            }
                            title={
                                <Typography
                                    variant="h5"
                                >

                                    Student Attendance Report

                    </Typography>
                            }
                        />

                        <CardContent style={{ minHeight: '40vh' }}>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography>Starting Date</Typography>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="start_date"
                                        value={this.state.start_date}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography>Ending Date</Typography>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="end_date"
                                        value={this.state.end_date}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography>Course</Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="course"
                                        value={this.state.course}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {['TDC'].map((el, index) =>
                                            <option key={index} value={el}>{el}</option>
                                        )}
                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography>Stream</Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="stream"
                                        value={this.state.stream}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {['SCIENCE'].map((el, index) =>
                                            <option key={index} value={el}>{el}</option>
                                        )}
                                    </select>
                                </Grid>
                                {this.state.course == 'TDC' ? (<Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography>Course Type</Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="course_type"
                                        value={this.state.course_type}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {['HONOURS', 'REGULAR'].map((el, index) =>
                                            <option key={index} value={el}>{el}</option>
                                        )}
                                    </select>
                                </Grid>) : null}
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography>{this.state.course == 'HS' ? 'Year' : 'Semester'}</Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="semester"
                                        value={this.state.semester}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {[1, 2, 3, 4, 5, 6].map((el, index) =>
                                            <option key={index} value={el}>{el}</option>
                                        )}
                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6} md={6} style={{ zIndex: 1000 }}>
                                    <Typography>Select Student</Typography>
                                    <Sel
                                        value={this.state.student_obj}
                                        options={this.state.students}
                                        isLoading={!this.state.isStudentLoaded}
                                        onChange={this.onSubjectChange.bind(this, 'student_obj')}
                                    />
                                </Grid>
                            </Grid>







                        </CardContent>

                        <CardActions className={classes.actions}>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="secondary"
                            >
                                {this.state.loading ? <span>Loading . . . </span> : <span>Perform Advance Search</span>}
                            </Button>
                        </CardActions>
                    </Card>
                </form>

                <br />

                {this.state.isLoaded ? <div>

                    <ReactToPrint
                        trigger={() => <Button variant="contained">Print</Button>}
                        content={() => this.componentRef}
                    />

                    <AttendanceViewStudent
                        ref={el => (this.componentRef = el)}
                        {...this.state.data}
                        isLoaded={this.state.isLoaded}

                    />
                </div> : null}
            </div>
        )
    }
}

export default withStyles(styles)(StudentWiseAttendanceReport)