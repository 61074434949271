import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import Sel from 'react-select';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        width: '100%',
        marginTop: '10px'
    }
})

class SubjectAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            course: '',
            stream: [],
            dept_code: '',
            sub_code: '',
            sub_name: '',
            sub_title: '',
            sub_type: '',
            sub_tp_type: '',
            semester: '',
            total_marks: '',
            pass_marks: '',
            is_MIL: 0,
            is_major: 0,
            is_admission: 0,
            is_compulsory: 0,
            is_generic: 0,
            id: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    handleSChange = (name, sub_code) => {
        this.setState({
            [name]: sub_code
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.setState({
                course: '',
                stream: [],
                dept_code: '',
                sub_code: '',
                sub_name: '',
                sub_title: '',
                sub_type: '',
                sub_tp_type: '',
                semester: '',
                total_marks: '',
                pass_marks: '',
                is_MIL: 0,
                is_admission: 0,
                is_compulsory: 0,
                is_generic: 0,
                id: ''
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.setState({
                    course: '',
                    stream: [],
                    dept_code: '',
                    sub_code: '',
                    sub_name: '',
                    sub_title: '',
                    sub_type: '',
                    sub_tp_type: '',
                    semester: '',
                    total_marks: '',
                    pass_marks: '',
                    is_MIL: 0,
                    is_major: 0,
                    is_admission: 0,
                    is_compulsory: 0,
                    is_generic: 0,
                    id: ''
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            this.setState({
                course: '',
                stream: [],
                dept_code: '',
                sub_code: '',
                sub_name: '',
                sub_title: '',
                sub_type: '',
                sub_tp_type: '',
                semester: '',
                total_marks: '',
                pass_marks: '',
                is_MIL: 0,
                is_major: 0,
                is_admission: 0,
                is_compulsory: 0,
                is_generic: 0,
                id: ''
            })
        }

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        let i = [];
        let course_list = [];
        let stream_list = [];
        if (this.props.isDeptLoaded) {
            let departments = this.props.departments;
            if (Array.isArray(departments) && departments.length > 0) {
                i = departments.map((el, index) =>
                    <MenuItem key={index} value={el.dept_code}>{el.dept_name}</MenuItem>
                )
            }

            let courses = this.props.courses;
            if (Array.isArray(courses) && courses.length > 0) {
                course_list = courses.map((el, index) =>
                    <MenuItem key={index} value={el.name}>{el.name}</MenuItem>
                )
            }
        }


        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <Grid container spacing={2}>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                        <Select
                                            value={this.state.course}
                                            onChange={this.onChange}
                                            name="course"
                                            inputProps={{
                                                name: 'course'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {course_list}
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    
                                        <InputLabel className={classes.textStyle}>Select Streams</InputLabel>
                                        <Sel
                                            isMulti
                                            value={this.state.stream}
                                            onChange={this.handleSChange.bind(this, "stream")}
                                            options={this.props.streams}
                                        />
                                    
                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Department</InputLabel>
                                        <Select
                                            value={this.state.dept_code}
                                            onChange={this.onChange}
                                            name="dept_code"
                                            inputProps={{
                                                name: 'dept_code'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {i}
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Semester</InputLabel>
                                        <Select
                                            value={this.state.semester}
                                            onChange={this.onChange}
                                            name="semester"
                                            inputProps={{
                                                name: 'semester'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            
                                            {[1,2,3,4,5,6].map((el,index) => 
                                                    <MenuItem key={index} value={el}>{el}</MenuItem>
                                                )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} lg={12} md={12}>
                                    <TextField
                                        label="Descipline Name"
                                        className={classes.textField}
                                        type="text"
                                        name="sub_name"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.sub_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12} md={12}>
                                    <TextField
                                        label="Course Code"
                                        name="sub_code"
                                        className={classes.textField}
                                        type="text"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.sub_code}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={12} md={12}>
                                    <TextField
                                        label="Course Title"
                                        name="sub_title"
                                        className={classes.textField}
                                        type="text"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.sub_title}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Course Type</InputLabel>
                                        <Select
                                            value={this.state.sub_type}
                                            onChange={this.onChange}
                                            name="sub_type"
                                            inputProps={{
                                                name: 'sub_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="HC">Honours Core</MenuItem>
                                            <MenuItem value="RC">Regular Core</MenuItem>
                                            <MenuItem value="HG">Honours Generic</MenuItem>
                                            <MenuItem value="AE">Ability Enhencement Compulsory</MenuItem>
                                            <MenuItem value="PR">Practical</MenuItem>
                                            <MenuItem value="Lab">Laboratory</MenuItem>
                                            <MenuItem value="TT">Tutorial</MenuItem>
                                            <MenuItem value="SE">Skill Enhancement Course</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Theory/Practical</InputLabel>
                                        <Select
                                            value={this.state.sub_tp_type}
                                            onChange={this.onChange}
                                            name="sub_tp_type"
                                            inputProps={{
                                                name: 'sub_tp_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Theory">Theory</MenuItem>
                                            <MenuItem value="Practical">Practical</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                

                                <Grid item xs={12} lg={6} md={6}>

                                    <TextField
                                        label="Total Marks"
                                        className={classes.textField}
                                        type="number"
                                        name="total_marks"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.total_marks}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <TextField
                                        label="Pass Marks"
                                        className={classes.textField}
                                        type="number"
                                        name="pass_marks"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.pass_marks}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>is MIL ?</InputLabel>
                                        <Select
                                            value={this.state.is_MIL}
                                            onChange={this.onChange}
                                            name="is_MIL"
                                            inputProps={{
                                                name: 'is_MIL'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={1}>YES</MenuItem>
                                            <MenuItem value={0}>NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>is a major subject ?</InputLabel>
                                        <Select
                                            value={this.state.is_major}
                                            onChange={this.onChange}
                                            name="is_major"
                                            inputProps={{
                                                name: 'is_major'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={1}>YES</MenuItem>
                                            <MenuItem value={0}>NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>

                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Should be listed in the admission ?</InputLabel>
                                        <Select
                                            value={this.state.is_admission}
                                            onChange={this.onChange}
                                            name="is_admission"
                                            inputProps={{
                                                name: 'is_admission'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={1}>YES</MenuItem>
                                            <MenuItem value={0}>NO</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>is this a compulsory subject ?</InputLabel>
                                        <Select
                                            value={this.state.is_compulsory}
                                            onChange={this.onChange}
                                            name="is_compulsory"
                                            inputProps={{
                                                name: 'is_compulsory'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={1}>YES</MenuItem>
                                            <MenuItem value={0}>NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>is this a generic subject ?</InputLabel>
                                        <Select
                                            value={this.state.is_generic}
                                            onChange={this.onChange}
                                            name="is_generic"
                                            inputProps={{
                                                name: 'is_generic'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={1}>YES</MenuItem>
                                            <MenuItem value={0}>NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                

                            </Grid>

                            <br />

                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.sub_code)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(SubjectAdd)