import React, { Component } from 'react';
import { withStyles, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import ReactToPrint from 'react-to-print';

const styles = theme => ({

})

const day = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const periods = [
    {
        period: 1,
        start_time: '09:00',
        end_time: '10:00'
    },
    {
        period: 2,
        start_time: '10:00',
        end_time: '11:00'
    },
    {
        period: 3,
        start_time: '11:00',
        end_time: '12:00'
    },
    {
        period: 4,
        start_time: '12:00',
        end_time: '13:00'
    },
    {
        period: 5,
        start_time: '13:00',
        end_time: '14:00'
    },
    {
        period: 6,
        start_time: '14:00',
        end_time: '15:00'
    },
    {
        period: 7,
        start_time: '15:00',
        end_time: '16:00'
    },
    {
        period: 8,
        start_time: '16:00',
        end_time: '17:00'
    }
]

class RoutineViewer extends Component {

    getRoutineData = (day, period_number) => {

        let i = [];
        let data = this.props.data;
        let r = data.filter(el => el.day == day && el.period_number == period_number)
        if (r.length > 0) {
            i = r.map((el, index) => {
                let ct= '';
                if(el.course_type != null){
                    ct = el.course_type
                }
                return el.sub_code + '(' + el.semester + ')' + ' ' + ct + '\r\n' + el.name
            })
        }

        return i
    }

    render() {
        let { classes } = this.props;
        return (
            <div style={{backgroundColor: 'white', padding: '10px'}}>

                <ReactToPrint
                    trigger={() => <Button>Print</Button>}
                    content={() => this.componentRef}
                />

                <div ref={el => (this.componentRef = el)}>

                <Typography>
                    Subject Code: {this.props.sub_code}
                </Typography>

                <br />

                <Table size="small">

                    <TableHead>
                        <TableRow>
                            <TableCell>
                                &nbsp;
                            </TableCell>
                            {periods.map((el, index) =>
                                <TableCell key={index} padding="none">
                                    <Typography>

                                        {el.start_time} - {el.end_time}
                                    </Typography>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {day.map((el, index) =>

                            <TableRow key={index}>
                                <TableCell>
                                    {el}
                                </TableCell>
                                {periods.map((el1, index1) =>
                                    <TableCell key={index1} padding="none">
                                       
                                        {this.getRoutineData(el, el1.period).map((el2,index2) => 

                                                <div key={index2} >
                                                    <Typography gutterBottom variant="caption">{el2}</Typography>
                                                </div>
                                            
                                            )}
                                       
                                        
                                    </TableCell>
                                )}
                            </TableRow>

                        )}
                    </TableBody>

                </Table>

                </div>

            </div>
        );
    }
}

export default withStyles(styles)(RoutineViewer);