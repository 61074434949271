import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, FormGroup, Switch, FormControlLabel, Chip } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const drawerWidth = 800;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        minWidth: '100%',
        marginTop: '15px'
    },
    addButton: {
        marginTop: theme.spacing.unit * 5
    },
    chip: {
        margin: theme.spacing.unit,
    }
})

class EmployeeAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            course: '',
            stream: '',
            course_type: '',
            for_girls: 0,
            payment_head: '',
            payment_amount: 0,
            payment_table: [],
            sm_head: '',
            sm_amount: '',
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

    }



    componentDidMount() {

        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.setState({
                id: '',
                course: '',
                stream: '',
                course_type: '',
                for_girls: 0,
                payment_head: '',
                payment_amount: '',
                payment_table: [],
                sm_head: '',
                sm_amount: '',
            })
        }


    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {

                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.setState({
                    id: '',
                    course: '',
                    stream: '',
                    course_type: '',
                    for_girls: 0,
                    payment_head: '',
                    payment_amount: '',
                    payment_table: [],
                    sm_head: '',
                    sm_amount: '',
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            this.setState({
                id: '',
                course: '',
                stream: '',
                course_type: '',
                for_girls: 0,
                payment_head: '',
                payment_amount: '',
                payment_table: [],
                sm_head: '',
                sm_amount: '',
            })
        }

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    onSetDp(dp) {
        this.setState({
            dp: dp
        })
    }

    onSetCv(dp) {
        this.setState({
            cv: dp
        })
    }

    setQualification(data) {
        this.setState({
            qualification: data
        })
    }

    setAchievements(data) {
        this.setState({
            details: data
        })
    }

    addSubject = () => {
        let payment_table = this.state.payment_table;
        if (this.state.sm_head !== '' && this.state.sm_amount !== '') {
            let payment_amount = parseFloat(this.state.payment_amount) || 0;
            let data = {
                sm_head: this.state.sm_head,
                sm_amount: this.state.sm_amount
            }

            payment_amount = payment_amount + parseFloat(this.state.sm_amount)

            payment_table.push(data);

            this.setState({
                payment_table,
                sm_head: '',
                sm_amount: '',
                payment_amount
            })
        }
    }

    onDeleteSub = (index, sm_amount) => {

        let payment_table = this.state.payment_table;
        let payment_amount = parseFloat(this.state.payment_amount) || 0;
        payment_amount = payment_amount - parseFloat(sm_amount)
        payment_table.splice(index, 1);
        this.setState({
            payment_table,
            payment_amount
        })

    }

    render() {
        let { classes } = this.props;


        let k = [];
        let payment_table = this.state.payment_table;
        if (Array.isArray(payment_table) && payment_table.length > 0) {
            k = payment_table.map((el, index) =>
                <Chip
                    key={index}
                    label={`${el.sm_head}: Rs. ${el.sm_amount}`}
                    onDelete={this.onDeleteSub.bind(this, index, el.sm_amount)}

                />
            )
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <Grid container spacing={24}>


                                <Grid item xs={12} lg={12}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                        <Select
                                            value={this.state.course}
                                            onChange={this.onChange}
                                            name="course"
                                            inputProps={{
                                                name: 'course'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="TDC">TDC</MenuItem>
                                            <MenuItem value="HS">HS</MenuItem>
                                            <MenuItem value="BSC-BIOTECHNOLOGY">BSC-BIOTECHNOLOGY</MenuItem>
                                            <MenuItem value="BCA">BCA</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select stream</InputLabel>
                                        <Select
                                            value={this.state.stream}
                                            onChange={this.onChange}
                                            name="stream"
                                            inputProps={{
                                                name: 'stream'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="ARTS">ARTS</MenuItem>
                                            <MenuItem value="SCIENCE">SCIENCE</MenuItem>
                                            <MenuItem value="COMMERCE">COMMERCE</MenuItem>
                                            
                                        </Select>
                                    </FormControl>

                                </Grid>

                                {this.state.course === 'TDC' ? (<Grid item xs={12} lg={12}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Course Type</InputLabel>
                                        <Select
                                            value={this.state.course_type}
                                            onChange={this.onChange}
                                            name="course_type"
                                            inputProps={{
                                                name: 'course_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="HONOURS">HONOURS</MenuItem>
                                            <MenuItem value="REGULAR">REGULAR</MenuItem>

                                        </Select>
                                    </FormControl>

                                </Grid>) : null}


                                <Grid item xs={12} lg={12}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>For Girls ? </InputLabel>
                                        <Select
                                            value={this.state.for_girls}
                                            onChange={this.onChange}
                                            name="for_girls"
                                            inputProps={{
                                                name: 'for_girls'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="0">No</MenuItem>
                                            <MenuItem value="1">YES</MenuItem>
            

                                        </Select>
                                    </FormControl>

                                </Grid>


                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Payment Head"
                                        name="payment_head"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.payment_head}
                                        onChange={this.onChange}
                                    />

                                </Grid>


                                <Grid item xs={5} lg={5}>
                                    <FormControl className={classes.formControl}>

                                        <TextField
                                            variant="outlined"
                                            label="Amount Head"
                                            name="sm_head"
                                            className={classes.textField}
                                            type="text"
                                            margin="normal"

                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.textStyle
                                                }
                                            }}
                                            value={this.state.sm_head}
                                            onChange={this.onChange}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={5} lg={5}>
                                    <FormControl className={classes.formControl}>

                                        <TextField
                                            variant="outlined"
                                            label="Amount"
                                            type="number"
                                            name="sm_amount"
                                            className={classes.textField}

                                            margin="normal"

                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.textStyle
                                                }
                                            }}
                                            value={this.state.sm_amount}
                                            onChange={this.onChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} lg={2}>
                                    <Button
                                        className={classes.addButton}
                                        variant="outlined"
                                        type="button"
                                        onClick={this.addSubject}
                                    >Add</Button>
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    {k}
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Payment Amount"
                                        name="payment_amount"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.payment_amount}
                                        onChange={this.onChange}
                                    />

                                </Grid>







                            </Grid>


                            <br />
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(EmployeeAdd)