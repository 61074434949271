import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Container, Typography, Grid } from '@material-ui/core';

const styles = theme => ({

})

class StudentQuickForm extends Component {
    state = {
        name: '',
        year_of_admission: '',
        father_name: '',
        mother_name: '',
        address: '',
        roll_no: '',
        student_id: '',
        phone: '',
        email: '',
        gender: '',
        caste: '',
        course: '',
        stream: '',
        course_type: '',
        
    }
    render() {
        let { classes } = this.props;
        return (
            <div>
                <Container>
                    <Typography
                        variant="h6"
                    >
                        Add Previous Year's Students
                    </Typography>

                    <Grid container spaing={2}>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <label>Student's Name</label>
                            <input
                                className="form-control"
                                name="name"
                                value={this.state.name}
                                onChange={this.onChange}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default withStyles(styles)(StudentQuickForm)