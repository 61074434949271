import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import MasterLanding from '../Components/Master/MasterLanding';
import FeedbackLanding from '../Components/Feedback/FeedbackLanding';

class FeedbackContainer extends Component {
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">

                    <FeedbackLanding />

                </div>
            </div>
        )
    }
}

export default withAuth(FeedbackContainer);