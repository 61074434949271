import React, { Component } from 'react'
import { withStyles, Grid, Typography, Button, IconButton, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@material-ui/core';

import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import { GetData, PostData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const styles = theme => ({
    container: {
        padding: '10px'
    },
    errorText: {
        fontSize: '1em',
        color: 'red'
    }
})

class EntryForm extends Component {

    constructor(props) {
        super(props);

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;

        this.state = {
            sl_no: '',
            marks_title: '',
            semester: '',
            date: today,
            paper: '',
            student_name: '',
            student_id: '',
            sub_code: '',
            sub_tp_type: '',
            sub_title: '',
            paper_code: '',
            total_marks: '',
            pass_marks: '',
            marks_obtained: '',
            grand_marks: '',
            grand_pass: '',
            grand_obtained: '',
            temp: [],
            papers: [],
            isPaperLoaded: false,
            type: 1,
            userIdIsValid: false,
            result_status: 'PASS',
            sub_title: '',
            semester: '',
            overall_status: 'PASS'
        }

    }

    __getInitialState = () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;

        this.setState({
            sl_no: '',
            marks_title: '',
            semester: '',
            date: today,
            paper: '',
            roll_no: '',
            student_name: '',
            student_id: '',
            sub_code: '',
            sub_tp_type: '',
            sub_title: '',
            paper_code: '',
            total_marks: '',
            pass_marks: '',
            marks_obtained: '',
            grand_marks: '',
            grand_pass: '',
            grand_obtained: '',
            temp: [],
            papers: [],
            isPaperLoaded: false,
            type: 1,
            userIdIsValid: false,
            item_status: 'PASS',
            sub_title: '',
            semester: '',
            overall_status: 'PASS'
        })
    }


    componentDidMount() {
        GetData(`/${this.props.apikey}/${this.props.username}/getpaperall`)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    papers: resp,
                    isPaperLoaded: true
                })
            })

    }


    onChange = (e) => {
        if (e.target.name === 'paper') {
            this.setState({
                [e.target.name]: e.target.value
            }, () => this.smallCalculation())
        } else {
            this.setState({
                [e.target.name]: e.target.value
            }, () => this.onAddClick())
        }

        if (e.target.name === 'paper') {
            let paper = e.target.value;
            let papers = this.state.papers;
            let r = papers.filter(el => el.paper_code === paper);
            if (Array.isArray(r) && r.length > 0) {
                this.setState({
                    sub_code: r[0].sub_code,
                    sub_tp_type: r[0].sub_tp_type,
                    sub_title: r[0].sub_title,
                    paper_code: r[0].paper_code,
                    total_marks: r[0].total_marks,
                    pass_marks: r[0].pass_marks

                })
            }
        }

        if (e.target.name == 'marks_obtained'){
            let v = e.target.value;
            let result_status = 'PASS';
            if(parseFloat(v) >= parseFloat(this.state.pass_marks)){
                result_status = 'PASS';
            }else{
                result_status = 'FAIL';
            }

            this.setState({
                result_status: result_status
            })
        }


    }



    __getStudentDetails = (student_id) => {
        GetData(`/${this.props.apikey}/${this.props.username}/${student_id}/getstudentbystudentid`)
            .then((resp) => {

                if (Array.isArray(resp) && resp.length > 0) {

                    this.setState({
                        student_name: resp[0].name,
                        roll_no: resp[0].roll_no,
                        userIdIsValid: true
                    })
                }
            })
    }

    getStudentName = (e) => {

        let student_id = e.target.value;

        if (student_id != '')
            this.__getStudentDetails(student_id);


    }

    smallCalculation = () => {


        let total_marks = parseFloat(this.state.total_marks) || 0;
        let pass_marks = parseFloat(this.state.pass_marks) || 0;
        let marks_obtained = parseFloat(this.state.marks_obtained) || 0;


        let grand_marks = parseFloat(this.state.grand_marks) || 0;
        let grand_pass = parseFloat(this.state.grand_pass) || 0;
        let grand_obtained = parseFloat(this.state.grand_obtained) || 0;

        grand_marks += total_marks;
        grand_pass += pass_marks;
        grand_obtained += marks_obtained;

        this.setState({
            grand_marks: grand_marks,
            grand_pass: grand_pass,
            grand_obtained: grand_obtained
        })
    }



    onAddClick = () => {

        let grand_marks = parseFloat(this.state.grand_marks) || 0;
        let grand_pass = parseFloat(this.state.grand_pass) || 0;
        let grand_obtained = parseFloat(this.state.grand_obtained) || 0;


        this.setState({
            grand_marks: grand_marks,
            grand_pass: grand_pass,
            grand_obtained: grand_obtained
        })
    }

    onSmallSubmit = () => {

        if (this.state.paper != '' && this.state.total_marks != '' && this.state.pass_marks != '' && this.state.marks_obtained != '') {



            let papers = this.state.papers;

            let paper = this.state.paper;
            let r = papers.filter(el => el.paper_code === paper);

            //check stock now

            let data = {
                paper: this.state.paper,
                sub_code: this.state.sub_code,
                sub_tp_type: this.state.sub_tp_type,
                sub_title: this.state.sub_title,
                paper_code: this.state.paper_code,
                total_marks: this.state.total_marks,
                pass_marks: this.state.pass_marks,
                marks_obtained: this.state.marks_obtained,
                result_status: this.state.result_status
            }

            
            if(this.state.result_status == 'FAIL'){
                this.setState({
                    overall_status: 'FAIL'
                })
            }


            let temp = this.state.temp;

            //check for duplicate data
            let r1 = temp.filter(el => el.paper === this.state.paper);
            if (r1.length === 0) {
                temp.push(data);
            }

            let grand_marks = 0;
            let grand_pass = 0;
            let grand_obtained = 0;
            if (temp.length > 0) {
                temp.map((el, index) => {

                    grand_marks += parseFloat(el.total_marks);
                    grand_pass += parseFloat(el.pass_marks);
                    grand_obtained += parseFloat(el.marks_obtained);

                })
            }


            this.setState({
                temp,
                paper: '',
                total_marks: '',
                pass_marks: '',
                marks_obtained: '',
                result_status: '',
                grand_marks: grand_marks,
                grand_pass: grand_pass,
                grand_obtained: grand_obtained
            })
        }

    }

    handleDelete = (index, total_marks, pass_marks, marks_obtained) => {
        let temp = this.state.temp;


        temp.splice(index, 1);

        let grand_marks = 0;
        let grand_pass = 0;
        let grand_obtained = 0;
        if (temp.length > 0) {
            temp.map((el, index) => {

                grand_marks += parseFloat(el.total_marks);
                grand_pass += parseFloat(el.pass_marks);
                grand_obtained += parseFloat(el.marks_obtained);

            })
        }

        this.setState({
            temp,
            grand_marks: grand_marks,
            grand_pass: grand_pass,
            grand_obtained: grand_obtained

        })

    }

    onSubmit = (e) => {
        e.preventDefault();


        // ask for confirmation

        confirmAlert({
            title: "Are your sure to submit ?",
            message: ``,
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.finalSubmit.bind(this)
                },
                {
                    label: 'No'
                }
            ]
        });

    }

    finalSubmit = () => {
        if (this.state.userIdIsValid && this.state.type != ''
            && this.state.student_id != '' && this.state.semester != '' && this.state.date != '') {
            let data = {
                marks_title: this.state.marks_title,
                type: this.state.type,
                sl_no: this.state.sl_no,
                date: this.state.date,
                student_id: this.state.student_id,
                semester: this.state.semester,
                table_data: this.state.temp,
                grand_marks: this.state.grand_marks,
                grand_pass: this.state.grand_pass,
                grand_obtained: this.state.grand_obtained,
                overall_status: this.state.overall_status
            }

            //console.log(data)

            //post data
            PostData(`/${this.props.apikey}/${this.props.username}/addmarks`, data)
                .then((resp) => {
                    confirmAlert({
                        title: resp.message,
                        message: `Do you want to print the marksheet ? `,
                        buttons: [
                            {
                                label: 'Yes',
                                onClick: this.printMarks.bind(this, resp.id)
                            },
                            {
                                label: 'No',
                                onClick: () => this.props.history.replace('/entryform')
                            }
                        ]
                    });
                })
        }
    }

    printMarks = (id) => {
        this.props.history.push('/printform', id);
    }


    render() {
        let { classes } = this.props;
        let i = [];
        if (this.state.isPaperLoaded) {
            let papers = this.state.papers;
            if (Array.isArray(papers) && papers.length > 0) {
                i = papers.map((el, index) =>
                    <option key={index} value={el.paper_code} >{el.sub_code} -  - [{el.sub_title}]</option>
                )
            }
        }


        return (
            <div>


                <div className={classes.container}>

                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        <u>End Semester Marksheet -- Entry Form</u>
                    </Typography>
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Marksheet Title e.g (BA 1st Semester)"
                                    name="marks_title"
                                    required
                                    value={this.state.marks_title}
                                    onChange={this.onChange}

                                />
                            </Grid>


                            <Grid item xs={12} lg={3}>
                                <select
                                    className="form-control"
                                    placeholder="Select Type"
                                    name="type"
                                    value={this.state.type}
                                    onChange={this.onChange}
                                    required

                                >
                                    <option value="">Select Type</option>
                                    <option value={'Fresh'}>Fresh</option>
                                    <option value={'Compartmental'}>Compartmental</option>

                                </select>
                            </Grid>

                            <Grid item xs={12} lg={3}>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder=""
                                    name="date"
                                    required
                                    value={this.state.date}
                                    onChange={this.onChange}
                                />
                            </Grid>



                            <Grid item xs={12} lg={3}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Student Id"
                                    name="student_id"
                                    required
                                    value={this.state.student_id}
                                    onChange={this.onChange}
                                    onBlur={this.getStudentName}

                                />
                            </Grid>

                            <Grid item xs={12} lg={3}>
                                <select
                                    className="form-control"
                                    placeholder="Select Term"
                                    name="semester"
                                    value={this.state.semester}
                                    onChange={this.onChange}
                                    required

                                >
                                    <option value="">Select Term</option>
                                    <option value={1}>First</option>
                                    <option value={2}>Second</option>
                                    <option value={3}>Third</option>
                                    <option value={4}>Fourth</option>
                                    <option value={5}>Fifth</option>
                                    <option value={6}>Sixth</option>

                                </select>
                            </Grid>

                            <Grid item xs={12} lg={6} md={6}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Student Name"
                                    name="student_name"
                                    required
                                    readOnly
                                    value={this.state.student_name}
                                    onChange={this.onChange}
                                />
                            </Grid>

                            <Grid item xs={12} lg={3} md={6}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Roll No"
                                    name="roll_no"
                                    required
                                    readOnly
                                    value={this.state.roll_no}
                                    onChange={this.onChange}
                                />
                            </Grid>




                        </Grid>


                        <br />

                        <Table size="small" style={{backgroundColor: 'white'}}>
                            <TableHead>
                                <TableRow>

                                    <TableCell width="40%" colSpan="2">Subject</TableCell>
                                    <TableCell width="15%">Total Marks</TableCell>
                                    <TableCell width="15%">Pass Marks</TableCell>
                                    <TableCell width="15%">Marks Obtained</TableCell>
                                    <TableCell width="15%">Result Status</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow style={{ verticalAlign: 'top' }}>
                                    <TableCell colSpan="2">
                                        <select
                                            className="form-control"
                                            placeholder="Subject"
                                            name="paper"
                                            value={this.state.paper}
                                            onChange={this.onChange}
                                        >
                                            <option value="">Select Subject</option>
                                            {i}
                                        </select>
                                    </TableCell>


                                    <TableCell>
                                        <input
                                            className="form-control"
                                            placeholder="Total Marks"
                                            name="total_marks"
                                            value={this.state.total_marks}
                                            onChange={this.onChange}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            className="form-control"
                                            placeholder="Pass Marks"
                                            name="pass_marks"
                                            value={this.state.pass_marks}
                                            onChange={this.onChange}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            className="form-control"
                                            placeholder="Marks Obtained"
                                            name="marks_obtained"
                                            value={this.state.marks_obtained}
                                            onChange={this.onChange}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <select
                                            className="form-control"
                                            placeholder="Result Status"
                                            name="result_status"
                                            value={this.state.result_status}
                                            onChange={this.onChange}

                                        >
                                            <option value="">Select Status</option>
                                            <option value="PASS">PASS</option>
                                            <option value="FAIL">FAIL</option>

                                        </select>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            size="small"
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            onClick={this.onSmallSubmit}
                                        >
                                            <Add />
                                        </Button>
                                    </TableCell>

                                </TableRow>
                                {this.state.temp.map((el, index) =>

                                    <TableRow key={index}>

                                        <TableCell colSpan="2">{index + 1} - {el.paper_code} - {el.sub_tp_type} - {el.sub_title}</TableCell>
                                        <TableCell>{el.total_marks}</TableCell>
                                        <TableCell>{el.pass_marks}</TableCell>
                                        <TableCell>{el.marks_obtained}</TableCell>

                                        <TableCell>{el.result_status}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={this.handleDelete.bind(this, index, el.total_marks, el.pass_marks, el.marks_obtained)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>

                                )}
                            </TableBody>

                            <br />
                            <br />
                            <br />

                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan="2">
                                        <input
                                            className="form-control"
                                            placeholder="Grand Total Marks"
                                            name="grand_marks"
                                            required
                                            readOnly
                                            value={this.state.grand_marks}
                                            onChange={this.onChange}
                                        />
                                    </TableCell>

                                    <TableCell colSpan="2">
                                        <input
                                            className="form-control"
                                            placeholder="Grand Total Pass Marks"
                                            name="grand_pass"
                                            required
                                            readOnly
                                            value={this.state.grand_pass}
                                            onChange={this.onChange}
                                        />
                                    </TableCell>
                                    <TableCell colSpan="2">
                                        <input
                                            className="form-control"
                                            placeholder="Grand Total Marks Obtained"
                                            name="grand_obtained"
                                            required
                                            readOnly
                                            value={this.state.grand_obtained}
                                            onChange={this.onChange}
                                        />
                                    </TableCell>
                                    <TableCell colSpan="2">
                                        <input
                                            className="form-control"
                                            placeholder="Overall Status"
                                            name="overall_status"
                                            required
                                            readOnly
                                            value={this.state.overall_status}
                                            onChange={this.onChange}
                                        />
                                    </TableCell>
                                </TableRow>

                            </TableFooter>
                        </Table>

                        <br />

                        <div style={{ textAlign: 'right' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Generate
                                </Button>
                        </div>
                    </form>
                </div>


            </div>
        )
    }

}
export default withStyles(styles)(EntryForm)