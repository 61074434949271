import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, FormGroup, Switch, FormControlLabel, Chip } from '@material-ui/core';
import FileUpload from '../../FileUpload/FileUpload';
import Details from '../../Details/Details';
import { GetData } from '../../../api/service';
import Delete from '@material-ui/icons/Delete'

const drawerWidth = 800;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        minWidth: '100%',
        marginTop: '15px'
    },
    addButton: {
        marginTop: theme.spacing.unit * 5
    },
    chip: {
        margin: theme.spacing.unit,
    }
})

class EmployeeAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            empid: '',
            type: '',
            dept_code: '',
            name: '',
            phone: '',
            email: '',
            gender: '',
            caste: '',
            date_of_appointment: '',
            date_of_birth: '',
            designation: '',
            specialisation: '',
            teaching_mode: '',
            present_address: '',
            permanent_address: '',
            dp: [],
            cv: [],
            qualification: [],
            details: [],
            is_HOD: 0,
            chec: false,
            el_leave: 0,
            ccl: 0,
            subjects: [],
            students: [],
            subject_list: [],
            semester: '',
            sub_code: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeS = this.onChangeS.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'dept_code') {
            GetData(`/${e.target.value}/getdepartmentsubject`)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        subject_list: resp
                    })
                })
        }

    }

    onChangeS(e) {
        let is_HOD = this.state.is_HOD;
        if (e.target.checked) {
            is_HOD = 1
        } else {
            is_HOD = 0
        }

        this.setState({
            chec: e.target.checked,
            is_HOD
        })

    }

    componentDidMount() {

        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })

            GetData(`/${this.props.edit_data.dept_code}/getdepartmentsubject`)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        subject_list: resp
                    })
                })
        } else {
            this.setState({
                empid: '',
                type: '',
                dept_code: '',
                name: '',
                phone: '',
                email: '',
                gender: '',
                caste: '',
                date_of_appointment: '',
                date_of_birth: '',
                designation: '',
                specialisation: '',
                teaching_mode: '',
                present_address: '',
                permanent_address: '',
                dp: [],
                cv: [],
                qualification: [],
                details: [],
                is_HOD: 0,
                chec: false,
                el_leave: 0,
                ccl: 0,
                subjects: [],
                students: [],
                semester: '',
                sub_code: ''
            })
        }


    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {

                this.setState({
                    ...this.props.edit_data
                })

                GetData(`/${this.props.edit_data.dept_code}/getdepartmentsubject`)
                    .then((resp) => {
                        //console.log(resp)
                        this.setState({
                            subject_list: resp
                        })
                    })
            } else {
                this.setState({
                    empid: '',
                    type: '',
                    dept_code: '',
                    name: '',
                    phone: '',
                    email: '',
                    gender: '',
                    caste: '',
                    date_of_appointment: '',
                    date_of_birth: '',
                    designation: '',
                    specialisation: '',
                    teaching_mode: '',
                    present_address: '',
                    permanent_address: '',
                    dp: [],
                    cv: [],
                    qualification: [],
                    details: [],
                    is_HOD: 0,
                    chec: false,
                    el_leave: 0,
                    ccl: 0,
                    subjects: [],
                    students: [],
                    semester: '',
                    sub_code: ''
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            this.setState({
                empid: '',
                type: '',
                dept_code: '',
                name: '',
                phone: '',
                email: '',
                gender: '',
                caste: '',
                date_of_appointment: '',
                date_of_birth: '',
                designation: '',
                specialisation: '',
                teaching_mode: '',
                present_address: '',
                permanent_address: '',
                dp: [],
                cv: [],
                qualification: [],
                details: [],
                is_HOD: 0,
                chec: false,
                el_leave: 0,
                ccl: 0,
                subjects: [],
                students: [],
                semester: '',
                sub_code: ''
            })
        }

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    onSetDp(dp) {
        this.setState({
            dp: dp
        })
    }

    onSetCv(dp) {
        this.setState({
            cv: dp
        })
    }

    setQualification(data) {
        this.setState({
            qualification: data
        })
    }

    setAchievements(data) {
        this.setState({
            details: data
        })
    }

    addSubject = () => {
        let subjects = this.state.subjects;
        if(this.state.sub_code !== '' && this.state.semester !== ''){
            let data = {
                sub_code: this.state.sub_code,
                semester: this.state.semester
            }

            subjects.push(data);

            this.setState({
                subjects,
                sub_code: '',
                semester: ''
            })
        }
    }

    onDeleteSub = (index) => {

        let subjects = this.state.subjects;
        subjects.splice(index,1);
        this.setState({
            subjects
        })

    }

    render() {
        let { classes } = this.props;
        let i = [];
        if (this.props.isDeptLoaded) {
            let departments = this.props.departments;
            if (Array.isArray(departments) && departments.length > 0) {
                i = departments.map((el, index) =>
                    <MenuItem key={index} value={el.dept_code}>{el.dept_name}</MenuItem>
                )
            }
        }

        let j = [];
        let sub = this.state.subject_list;
        if (Array.isArray(sub) && sub.length > 0) {
            j = sub.map((el, index) =>
                <MenuItem key={index} value={el.sub_code}>{el.sub_name}</MenuItem>
            )
        }

        let k = [];
        let subb = this.state.subjects;
        if(Array.isArray(subb) && subb.length > 0){
            k = subb.map((el,index) =>
                <Chip
                    key={index}
                    label={`${el.sub_code}: (${el.semester})`}
                    onDelete={this.onDeleteSub.bind(this,index)}

                />
            )
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <Grid container spacing={2}>


                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Employee ID"
                                        name="empid"
                                        className={classes.textField}
                                        type="text"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.empid}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Employee Type</InputLabel>
                                        <Select
                                            value={this.state.type}
                                            onChange={this.onChange}
                                            name="type"
                                            inputProps={{
                                                name: 'type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="TEACHER">TEACHER</MenuItem>
                                            <MenuItem value="ADMINISTRATION">ADMINISTRATION</MenuItem>
                                            <MenuItem value="LIBRARY">LIBRARY</MenuItem>
                                            <MenuItem value="LAB">LAB ASSISTANT</MenuItem>
                                            <MenuItem value="OFFICE">OFFICE STAFF</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Department</InputLabel>
                                        <Select
                                            value={this.state.dept_code}
                                            onChange={this.onChange}
                                            name="dept_code"
                                            inputProps={{
                                                name: 'dept_code'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {i}
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Employee Name"
                                        name="name"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.name}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Employee Phone"
                                        name="phone"
                                        className={classes.textField}
                                        type="number"

                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.phone}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Employee Email"
                                        name="email"
                                        className={classes.textField}
                                        type="email"

                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.email}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Gender</InputLabel>
                                        <Select
                                            value={this.state.gender}
                                            onChange={this.onChange}
                                            name="gender"
                                            inputProps={{
                                                name: 'gender'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>Choose Gender</em>
                                            </MenuItem>

                                            <MenuItem value="MALE">MALE</MenuItem>
                                            <MenuItem value="FEMALE">FEMALE</MenuItem>
                                            <MenuItem value="OTHER">OTHER</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Caste</InputLabel>
                                        <Select
                                            value={this.state.caste}
                                            onChange={this.onChange}
                                            name="caste"
                                            inputProps={{
                                                name: 'caste'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>Choose Caste</em>
                                            </MenuItem>

                                            <MenuItem value="GENERAL">GENERAL</MenuItem>
                                            <MenuItem value="ST(p)">ST(P)</MenuItem>
                                            <MenuItem value="ST(H)">ST(H)</MenuItem>
                                            <MenuItem value="SC">SC</MenuItem>
                                            <MenuItem value="OBC">OBC</MenuItem>
                                            <MenuItem value="MOBC">MOBC</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Date of Birth"
                                        name="date_of_birth"
                                        className={classes.textField}
                                        type="date"
                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.date_of_birth}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Date of Appointment"
                                        name="date_of_appointment"
                                        className={classes.textField}
                                        type="date"
                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.date_of_appointment}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Designation"
                                        name="designation"
                                        className={classes.textField}
                                        type="text"

                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.designation}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <TextField
                                        label="Specialisation"
                                        name="specialisation"
                                        className={classes.textField}
                                        type="text"

                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.specialisation}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={4} lg={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Teaching Mode</InputLabel>
                                        <Select
                                            value={this.state.teaching_mode}
                                            onChange={this.onChange}
                                            name="teaching_mode"
                                            inputProps={{
                                                name: 'teaching_mode'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>Choose Teaching Mode</em>
                                            </MenuItem>
                                            <MenuItem value="REGULAR">REGULAR</MenuItem>
                                            <MenuItem value="CONTACTUAL">CONTACTUAL</MenuItem>
                                            <MenuItem value="GUEST">GUEST</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        label="Present Address"
                                        name="present_address"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"

                                        multiline
                                        rows="2"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.present_address}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        label="Permanent Address"
                                        name="permanent_address"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"

                                        multiline
                                        rows="2"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.permanent_address}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <InputLabel shrink className={classes.textStyle}>Upload Display picture</InputLabel>
                                    <FileUpload
                                        dp={this.state.dp}
                                        apikey={this.props.apikey}
                                        type="DP"
                                        setDp={this.onSetDp.bind(this)}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <InputLabel shrink className={classes.textStyle}>Upload CV (PDF ONLY)</InputLabel>
                                    <FileUpload
                                        dp={this.state.cv}
                                        apikey={this.props.apikey}
                                        type="CV"
                                        setDp={this.onSetCv.bind(this)}
                                    />
                                </Grid>


                                <Grid item xs={12} lg={12}>
                                    <InputLabel shrink className={classes.textStyle}>Add Qualification</InputLabel>
                                    <Details
                                        dp={this.state.qualification}
                                        type={['QUALIFICATION']}
                                        setDp={this.setQualification.bind(this)}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <InputLabel shrink className={classes.textStyle}>Add Acheivements</InputLabel>
                                    <Details
                                        dp={this.state.details}
                                        type={['BOOK PUBLISH', 'JOURNAL PUBLISH']}
                                        setDp={this.setAchievements.bind(this)}
                                    />
                                </Grid>


                                <Grid item xs={12} lg={4}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="is_HOD"
                                                    checked={this.state.chec}
                                                    onChange={this.onChangeS}

                                                />
                                            }
                                            label="is HOD ?"
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        label="No of EL left"
                                        name="el_leave"
                                        className={classes.textField}
                                        type="number"
                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                min: 0
                                            }
                                        }}
                                        value={this.state.el_leave}
                                        onChange={this.onChange}
                                    />

                                </Grid>


                                <Grid item xs={12} lg={4}>
                                    {(this.state.gender === 'FEMALE') ? <TextField
                                        label="No of CCL left"
                                        name="ccl"
                                        className={classes.textField}
                                        type="number"
                                        margin="normal"

                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                min: 0
                                            }
                                        }}
                                        value={this.state.ccl}
                                        onChange={this.onChange}
                                    /> : null}

                                </Grid>



                                <Grid item xs={5} lg={5}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Semester</InputLabel>
                                        <Select
                                            value={this.state.semester}
                                            onChange={this.onChange}
                                            name="semester"
                                            inputProps={{
                                                name: 'semester'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>Choose Semester</em>
                                            </MenuItem>
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={6}>6</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={5} lg={5}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Subjects</InputLabel>
                                        <Select
                                            value={this.state.sub_code}
                                            onChange={this.onChange}
                                            name="sub_code"
                                            inputProps={{
                                                name: 'sub_code'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>Choose Subject</em>
                                            </MenuItem>
                                            {j}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} lg={2}>
                                    <Button
                                        className={classes.addButton}
                                        variant="outlined"
                                        type="button"
                                        onClick={this.addSubject}
                                    >Add</Button>
                                </Grid>

                                
                                {k}
                                




                            </Grid>
                                            

                            <br />
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(EmployeeAdd)