import React, { Component } from 'react'
import { Card, CardHeader, Avatar, Typography, CardContent, FormControl, InputLabel, Select, MenuItem, OutlinedInput, CardActions, Button, withStyles, TextField, CircularProgress, Grid } from '@material-ui/core';
import Receipt from '@material-ui/icons/Receipt'
import { lightGreen, lightBlue, red } from '@material-ui/core/colors';
import Search from '@material-ui/icons/Search'
import { PostData, GetData } from '../../api/service';
import AttendanceReportTable from './AttendanceReportTable';
import Sel from 'react-select'


const styles = theme => ({
  actions: {
    justifyContent: 'flex-end'
  },
  condtionDiv: {
    padding: theme.spacing.unit * 2,
    backgroundColor: lightGreen[50]
  },
  textField: {
    width: '50%'
  }
})

class AttendanceReportLanding extends Component {
  state = {
    start_date: '',
    end_date: '',
    course: '',
    course_type: '',
    stream: '',
    semester: '',
    sub_code: '',
    paper_code: '',
    sub_code_obj: {},
    subjects: [],
    papers: [],
    f_papers: [],
    isLoaded: false,
    data: [],
    loading: false,
    isSubjectLoaded: false
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  setTable = (name, value) => {
    this.setState({
      [name]: value
    })





  }

  componentDidMount() {
    GetData(`/getsubject`)
      .then((resp) => {
        //console.log(resp)
        this.setState({
          subjects: resp,
          isSubjectLoaded: true
        })
      })

    GetData(`/getpapercode`)
      .then((resp) => {
        //console.log(resp)
        this.setState({
          papers: resp
        })
      })
  }


  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    })

    let data = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      course: this.state.course,
      stream: this.state.stream,
      course_type: this.state.course_type,
      semester: this.state.semester,
      sub_code: this.state.sub_code,
      paper_code: this.state.paper_code
    }


    PostData(`/getstudentattendancereportsprevious`, data)
      .then((resp) => {
        //console.log(resp)
        this.setState({
          data: resp,
          isLoaded: true,
          loading: false
        })
      })

  }

  onSubjectChange = (type, value) => {
    this.setState({
      [type]: value
    })

    if (type == 'sub_code_obj') {



      let papers = this.state.papers;
      let r = papers.filter(el => el.sub_code == value.value)
      if (r.length > 0) {
        this.setState({
          f_papers: r
        })
      }


      this.setState({
        sub_code: value.value
      })
    }

  }
  render() {
    let { classes } = this.props;
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Card>
            <CardHeader
              avatar={
                <Avatar style={{ backgroundColor: lightGreen[300] }}>
                  <Receipt />
                </Avatar>
              }
              title={
                <Typography
                  variant="h5"
                >

                  Class Attendance Report

                    </Typography>
              }
            />

            <CardContent style={{ minHeight: '40vh' }}>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography>Starting Date</Typography>
                  <input
                    className="form-control"
                    type="date"
                    name="start_date"
                    value={this.state.start_date}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography>Ending Date</Typography>
                  <input
                    className="form-control"
                    type="date"
                    name="end_date"
                    value={this.state.end_date}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography>Course</Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="course"
                    value={this.state.course}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    {['TDC'].map((el, index) =>
                      <option key={index} value={el}>{el}</option>
                    )}
                  </select>
                </Grid>
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography>Stream</Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="stream"
                    value={this.state.stream}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    {['SCIENCE'].map((el, index) =>
                      <option key={index} value={el}>{el}</option>
                    )}
                  </select>
                </Grid>
                {this.state.course == 'TDC' ? (<Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography>Course Type</Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="course_type"
                    value={this.state.course_type}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    {['HONOURS', 'REGULAR'].map((el, index) =>
                      <option key={index} value={el}>{el}</option>
                    )}
                  </select>
                </Grid>) : null}
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography>{this.state.course == 'HS' ? 'Year' : 'Semester'}</Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="semester"
                    value={this.state.semester}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    {[1, 2, 3, 4, 5, 6].map((el, index) =>
                      <option key={index} value={el}>{el}</option>
                    )}
                  </select>
                </Grid>
                <Grid item xs={12} sm={12} lg={3} md={3} style={{ zIndex: 1000 }}>
                  <Typography>Select Subject</Typography>
                  <Sel
                    value={this.state.sub_code_obj}
                    options={this.state.subjects}
                    isLoading={!this.state.isSubjectLoaded}
                    onChange={this.onSubjectChange.bind(this, 'sub_code_obj')}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography>Paper Code</Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="paper_code"
                    value={this.state.paper_code}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    {this.state.f_papers.map((el, index) =>
                      <option key={index} value={el.paper_code}>{el.paper_code}</option>
                    )}
                  </select>
                </Grid>
              </Grid>



              {/* <div className={classes.condtionDiv}>
                <AttendanceCondtions
                  setTable={this.setTable.bind(this, 'conditions')}
                />
              </div> */}




            </CardContent>

            <CardActions className={classes.actions}>
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
              >
                {this.state.loading ? <span>Loading . . . </span> : <span>Perform Advance Search</span>}
              </Button>
            </CardActions>
          </Card>
        </form>

        <br />

        {this.state.isLoaded &&
          <div>
            <Grid container spacing={2}>
              {this.state.data[1].map((el, index) =>
                <Grid item xs={12} sm={12} md={2} lg={2} key={index}>
                  <Card
                    style={{ backgroundColor: lightBlue[50] }}
                  >
                    <CardHeader
                      subheader={el.title}
                      title={el.value}
                      subheaderTypographyProps={{
                        style: {
                          color: red[700]
                        }
                      }}
                    />
                  </Card>
                </Grid>)}
            </Grid>
            <br />
            <AttendanceReportTable

              data={this.state.data[0]}
              isLoaded={this.state.isLoaded}
              paper_code={this.state.paper_code}

            />
          </div>}


      </div>
    )
  }
}

export default withStyles(styles)(AttendanceReportLanding)