import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid, OutlinedInput } from '@material-ui/core';
import { GetData } from '../../../api/service';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    footerButton: {
        padding: theme.spacing.unit
    },
    formControl: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            type:[],
            type_id: '',
            from_no:'',
            slr_no: '',
            entry_date:'',
            name:'',
            roll:'',
            no:'',
            course:'',
            part:'',
            year: '',
            major: '',
            division:''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getInitialState = () => {
        this.setState({
            id: '',
            type_id: '',
            from_no:'',
            slr_no: '',
            entry_date:'',
            name:'',
            roll:'',
            no:'',
            course:'',
            part:'',
            year: '',
            major: '',
            division:''
        })
    }

    __getInitialData = () => {

        GetData(`/getcertificatetype`)
            .then((resp) => {

                this.setState({
                    type: resp,

                    isLoaded: true
                })



            })

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {

        this.__getInitialData();

        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.__getInitialState();
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.edit !== this.props.edit) {
            this.__getInitialData();
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.__getInitialState();
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;


        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);

        }

        this.__getInitialState();

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;

        let i = [];
        if (this.state.isLoaded) {
            let type = this.state.type;
            if (Array.isArray(type) && type.length > 0) {
                i = type.map((el, index) =>
                    <MenuItem value={el.id} key={index}>{el.type_name}</MenuItem>
                )
            }

            return (
                <div>
                    <Drawer
                        className={classes.root}
                        anchor="right"
                        open={this.props.show}
                        onClose={this.props.handleClose}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >

                        <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                            <Toolbar>
                                <Typography variant="h6" color="inherit" >
                                    {this.props.edit ? "Edit DATA" : "Add New"}
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <div className={classes.paddingForm}>

                            <form onSubmit={this.onSubmit}>
                                <FormControl required variant="outlined" className={classes.formControl}>
                                    <InputLabel>Certificate Type</InputLabel>
                                    <Select
                                        name="type_id"

                                        value={this.state.type_id}
                                        onChange={this.onChange}
                                        input={
                                            <OutlinedInput
                                                labelWidth={200}
                                                name="type_id"
                                            />
                                        }
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {i}
                                    </Select>
                                </FormControl>

                                <TextField
                                    variant="outlined"
                                    label="From No"
                                    name="from_no"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.from_no}
                                    onChange={this.onChange}
                                />

                                <TextField
                                    variant="outlined"
                                    label="Sl No"
                                    name="slr_no"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.slr_no}
                                    onChange={this.onChange}
                                />

                                <TextField
                                    variant="outlined"
                                    label="Entry Date"
                                    name="entry_date"
                                    className={classes.textField}
                                    type="date"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.entry_date}
                                    onChange={this.onChange}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Student Name"
                                    name="name"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.name}
                                    onChange={this.onChange}
                                />

                                <TextField
                                    variant="outlined"
                                    label="Roll"
                                    name="roll"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.roll}
                                    onChange={this.onChange}
                                />

                                <TextField
                                    variant="outlined"
                                    label="No"
                                    name="no"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.no}
                                    onChange={this.onChange}
                                />

                                <TextField
                                    variant="outlined"
                                    label="Course"
                                    name="course"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.course}
                                    onChange={this.onChange}
                                />

                                <TextField
                                    variant="outlined"
                                    label="Part"
                                    name="part"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.part}
                                    onChange={this.onChange}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Year"
                                    name="year"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.year}
                                    onChange={this.onChange}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Major"
                                    name="major"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.major}
                                    onChange={this.onChange}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Division"
                                    name="division"
                                    className={classes.textField}
                                    type="text"
                                    margin="normal"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textStyle
                                        }
                                    }}
                                    value={this.state.division}
                                    onChange={this.onChange}
                                />

                                <div className={classes.footerButton}>

                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        color={this.props.edit ? "secondary" : "primary"}
                                        className={classes.button}>
                                        {this.props.edit ? "Update" : "Submit"}
                                    </Button>

                                    {this.props.edit ?
                                        <span>&nbsp;
                                    <Button
                                                type="button"
                                                variant="outlined"
                                                color="secondary"
                                                className={classes.button}
                                                onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                            >
                                                Delete
                                    </Button>
                                        </span>

                                        : null}
                                </div>
                            </form>

                        </div>



                    </Drawer>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
}

export default withStyles(styles)(CourseAdd)