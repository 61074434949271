import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import withAuth from '../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import SubjectWiseReport from '../Components/ClasstestReport/SubjectWiseReport';
import StudentWiseClassTestReport from '../Components/ClasstestReport/StudentWiseClassTestReport';

class ClasstestReportContainer extends Component {
    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem('user')).user;

        this.state = {
            apikey: user.apikey,
            username: user.email,
            role: user.role
        }
    }
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">

                    <Switch>

                        <Route exact path="/classtest/subject" render={(props) => <SubjectWiseReport {...props} {...this.props} apikey={this.state.apikey} />} />
                        <Route exact path="/classtest/student" render={(props) => <StudentWiseClassTestReport {...props} {...this.props} apikey={this.state.apikey} />} />
                        
                        

                    </Switch>


                </div>
            </div>
        )
    }
}

export default withAuth(ClasstestReportContainer);