import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';

import InitialisationContainer from './Containers/InitialisationContainer';
import NoticeContainer from './Containers/NoticeContainer';
import MasterContainer from './Containers/MasterContainer';
import EmployeeContainer from './Containers/EmployeeContainer';
import SettingsContainer from './Containers/SettingsContainer';
import FeedbackContainer from './Containers/FeedbackContainer';
import StudentContainer from './Containers/StudentContainer';
import ExcelUploadContainer from './Containers/ExcelUploadContainer';
import LogContainer from './Containers/LogContainer';
import NotificationsContainer from './Containers/NotificationsContainer';
import AttendanceReportContainer from './Containers/AttendanceReportContainer';
import PromotionContainer from './Containers/PromotionContainer';
import FeesContainer from './Containers/FeesContainer';
import SubjectCombinationContainer from './Containers/SubjectCombinationContainer';
import MarksContainer from './Containers/MarksContainer';
import CertificateContainer from './Containers/CertificateContainer';
import ClasstestReportContainer from './Containers/ClasstestReportContainer';


class App extends Component {
  render() {
    return (
      <Router history={history} >
      
        <div>
          <Switch>
            <Route path="/login" component={Login} />
            <Route exact path="/" component={HomePage} />
            <Route exact path="/notice" component={NoticeContainer} />
            <Route exact path="/initialisation" component={InitialisationContainer} />     
            <Route exact path="/settings" component={SettingsContainer} />     

            <Route exact path="/master/course" component={MasterContainer} />       
            <Route exact path="/master/department" component={MasterContainer} />       
            <Route exact path="/master/subject" component={MasterContainer} />       
            <Route exact path="/master/paper" component={MasterContainer} />       
            <Route exact path="/master/stream" component={MasterContainer} />       
            <Route exact path="/master/session" component={MasterContainer} />     
            <Route exact path="/master/leave" component={MasterContainer} />     
            <Route exact path="/master/class" component={MasterContainer} />     
            <Route exact path="/master/routine" component={MasterContainer} />     
            <Route exact path="/master/routineview" component={MasterContainer} />     
            <Route exact path="/master/section" component={MasterContainer} />     
            <Route exact path="/master/access" component={MasterContainer} />     
            <Route exact path="/master/doctype" component={MasterContainer} />     
            <Route exact path="/master/passwordchange" component={MasterContainer} />     
            <Route exact path="/master/holiday" component={MasterContainer} />     
            
            
            <Route exact path="/employee" component={EmployeeContainer} />  
            <Route exact path="/student" component={StudentContainer} />     
            <Route exact path="/student/import" component={StudentContainer} />     
            <Route exact path="/student/form" component={StudentContainer} />     

            <Route exact path="/feedback/category" component={FeedbackContainer} />     
            <Route exact path="/feedback/session" component={FeedbackContainer} />     
            <Route exact path="/feedback/questions" component={FeedbackContainer} />     
            <Route exact path="/feedback/reports" component={FeedbackContainer} />   
            <Route exact path="/feedback/reports/students" component={FeedbackContainer} />   
            <Route exact path="/feedback/reports/teachers" component={FeedbackContainer} />   
            <Route exact path="/feedback/reports/analysis" component={FeedbackContainer} />   

           

            <Route exact path="/excel" component={ExcelUploadContainer} /> 
            <Route exact path="/logs" component={LogContainer} /> 
            
            
            <Route exact path="/promotion" component={PromotionContainer} /> 
            <Route exact path="/fees" component={FeesContainer} /> 
            <Route exact path="/scombination" component={SubjectCombinationContainer} /> 


            <Route exact path="/notifications" component={NotificationsContainer} /> 
            <Route exact path="/attendancereport" component={AttendanceReportContainer} />
            <Route exact path="/areport" component={AttendanceReportContainer} />
            <Route exact path="/editattendance/:id" component={AttendanceReportContainer} />
            <Route exact path="/teachersdiary" component={AttendanceReportContainer} />
            <Route exact path="/sattreport" component={AttendanceReportContainer} /> 
            <Route exact path="/studentreport" component={AttendanceReportContainer} /> 
            <Route exact path="/entryform" component={MarksContainer} /> 
            <Route exact path="/viewform" component={MarksContainer} /> 
            <Route exact path="/printform" component={MarksContainer} /> 
            <Route exact path="/editform" component={MarksContainer} /> 


            <Route exact path="/classtest/subject" component={ClasstestReportContainer} /> 
            <Route exact path="/classtest/student" component={ClasstestReportContainer} /> 

            <Route exact path="/certificate" component={CertificateContainer} />
            <Route exact path="/certificate/certificatetype" component={CertificateContainer} />
            <Route exact path="/certificate/certificateview" component={CertificateContainer} />  
            <Route exact path="/certificate/print" component={CertificateContainer} />
          </Switch>
        </div>
       
      </Router>
    );
  }
}

export default App;
