import React, { Component } from 'react'
import { withStyles, Card, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';

const styles = theme => ({
    gridStyle: {
        backgroundColor: 'white',
        padding: '15px'
    },
    type: {
        fontSize: 15
    }
})


class NoticeCard extends Component {

    showFile(blob){
        let pdfWindow = window.open("")
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(blob.data) + "'></iframe>")
    }


  render() {
      let {classes} = this.props;

      let i = [];
      let file = this.props.file;
      if(Array.isArray(file) && file.length > 0){
        i = file.map((el,index) => 
            <Button key={index} variant="contained" onClick={this.showFile.bind(this,el)}>
                <GetApp /> Download
                                </Button>
        )
      }
    return (
      <div>
            <Card className={classes.gridStyle}>
                <CardContent>
                    <Typography
                        variant="h3"
                        component="h3"

                    >
                        {this.props.data.title}
                            </Typography>

                    <Typography
                        variant="h6"
                        component="h6"
                        gutterBottom
                        color="error"
                    >
                        Posted by {this.props.data.created_by}, {this.props.data.created_at}
                            </Typography>

                    <Typography
                        className={classes.type}
                        dangerouslySetInnerHTML={{__html: this.props.data.message}}
                    >
                            </Typography>
                </CardContent>

                <CardActions>
                    {i}
                </CardActions>

            </Card>
      </div>
    )
  }
}

export default withStyles(styles)(NoticeCard);