import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            notification_text: '',
            to_all: '',
            is_active: '',
            by_username: '',
            posted_on: '',
            deactivate_on: '',
            to_type: '',
            to_course: '',
            to_dept: '',
            to_sem: '',
            to_stream: '',
            by_username: this.props.username,
            term_name: 'TERM',
            term_list: [1, 2, 3, 4, 5, 6],
            show_all: true,
            show_student: true,
            show_teacher: true
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getInitialState() {
        this.setState({
            id: '',
            notification_text: '',
            to_all: '',
            is_active: '',
            by_username: '',
            posted_on: '',
            deactivate_on: '',
            by_username: this.props.username,
            to_type: '',
            to_dept: '',
            to_sem: '',
            to_course: '',
            to_stream: '',
            term_name: 'TERM',
            term_list: [1, 2, 3, 4, 5, 6],
            show_all: true,
            show_student: true,
            show_teacher: true
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'course') {
            let term_name = ""; let term_list = [];
            let course = this.props.courses;
            let results = course.filter((el) => el.name === e.target.value);
            if (results.length > 0) {
                term_name = results[0].tenure;
                let no_of_term = parseFloat(results[0].no_of_term);
                for (let i = 1; i <= no_of_term; i++) {
                    term_list.push(i);
                }
                this.setState({
                    term_name,
                    term_list
                })
            }
        }

        if (e.target.name === 'to_all') {
            if (e.target.value === 'YES') {
                this.setState({
                    show_all: false,
                    show_teacher: true,
                    show_student: true,
                    to_type: '',
                    to_dept: '',
                    to_sem: '',
                    to_course: '',
                    to_stream: '',
                })
            } else if (e.target.value === 'NO') {
                this.setState({
                    show_all: true,
                    show_teacher: true,
                    show_student: true
                })
            }
        }

        if (e.target.name === 'to_type') {
            if (e.target.value === 'TEACHER') {
                this.setState({
                    show_all: true,
                    show_teacher: true,
                    show_student: false
                })
            } else if (e.target.value === 'STUDENT') {
                this.setState({
                    show_all: true,
                    show_teacher: true,
                    show_student: true
                })
            }
        }
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.__getInitialState();
        }




    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.__getInitialState();
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;


        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);

        }

        this.__getInitialState();

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        let stream = [];
        let course = [];

        let semester = [];
        let department = [];
        if (this.props.is_all_loaded) {
            let i = this.props.courses;
            if (Array.isArray(i) && i.length > 0) {
                course = i.map((el, index) =>
                    <MenuItem key={index} value={el.name}>{el.name}</MenuItem>
                )
            }

            let j = this.props.streams;
            if (Array.isArray(j) && j.length > 0) {
                stream = j.map((el, index) =>
                    <MenuItem key={index} value={el.stream_name}>{el.stream_name}</MenuItem>
                )
            }

            let k = this.state.term_list;
            if (Array.isArray(k) && k.length > 0) {
                semester = k.map((el, index) =>
                    <MenuItem key={index} value={el}>{el}</MenuItem>
                )
            }

            let l = this.props.departments;
            if (Array.isArray(l) && l.length > 0) {
                department = l.map((el, index) =>
                    <MenuItem key={index} value={el.dept_code}>{el.dept_name}</MenuItem>
                )
            }
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>To All</InputLabel>
                                <Select
                                    value={this.state.to_all}
                                    onChange={this.onChange}
                                    name="to_all"
                                    inputProps={{
                                        name: 'to_all'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="YES">YES</MenuItem>
                                    <MenuItem value="NO">No</MenuItem>
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            {this.state.show_all ? <div>

                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel shrink className={classes.textStyle}>Type</InputLabel>
                                    <Select
                                        value={this.state.to_type}
                                        onChange={this.onChange}
                                        name="to_type"
                                        inputProps={{
                                            name: 'to_type'
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="TEACHER">TEACHER</MenuItem>
                                        <MenuItem value="STUDENT">STUDENT</MenuItem>
                                    </Select>
                                </FormControl>

                                <br />
                                <br />


                                {this.state.show_student ? <div>

                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                        <Select
                                            value={this.state.to_course}
                                            onChange={this.onChange}
                                            name="to_course"
                                            inputProps={{
                                                name: 'to_course'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {course}
                                        </Select>
                                    </FormControl>

                                    <br />
                                    <br />

                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Stream</InputLabel>
                                        <Select
                                            value={this.state.to_stream}
                                            onChange={this.onChange}
                                            name="to_stream"
                                            inputProps={{
                                                name: 'to_stream'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {stream}
                                        </Select>
                                    </FormControl>

                                    <br />
                                    <br />


                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select {this.state.term_name}</InputLabel>
                                        <Select
                                            value={this.state.to_sem}
                                            onChange={this.onChange}
                                            name="to_sem"
                                            inputProps={{
                                                name: 'to_sem'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {semester}
                                        </Select>
                                    </FormControl>




                                    <br />
                                    <br />

                                </div> : null}

                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel shrink className={classes.textStyle}>Select Department</InputLabel>
                                    <Select
                                        value={this.state.to_dept}
                                        onChange={this.onChange}
                                        name="to_dept"
                                        inputProps={{
                                            name: 'to_dept'
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {department}
                                    </Select>
                                </FormControl>


                            </div> : null}


                            <TextField
                                label="Notification"
                                name="notification_text"
                                className={classes.textField}
                                type="text"
                                variant="outlined"
                                multiline
                                rows="3"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.notification_text}
                                onChange={this.onChange}
                            />

                            <br />


                            <TextField
                                label="Deactivate On"
                                name="deactivate_on"
                                className={classes.textField}
                                type="date"
                                variant="outlined"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    },
                                    shrink: true
                                }}
                                value={this.state.deactivate_on}
                                onChange={this.onChange}
                            />

                            <br />






                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)