import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom'
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import AttendanceReport from '../Components/Attendance/AttendanceReport';
import AttendanceReportLanding from '../Components/AttendanceReport/AttendanceReportLanding';
import StudentWiseAttendanceReport from '../Components/AttendanceReport/StudentWiseAttendanceReport';
import EditAttendance from '../Components/Attendance/EditAttendance';
import ReportsLanding from '../Components/Reports/ReportsLanding';

class AttendanceReportContainer extends Component {
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">

                    <Switch>

                        <Route exact path="/attendancereport" component={AttendanceReport} />
                        <Route exact path="/editattendance/:id" component={EditAttendance} />
                        <Route exact path="/areport" component={AttendanceReportLanding} />
                        <Route exact path="/sattreport" component={StudentWiseAttendanceReport} />
                        <Route exact path="/studentreport" component={ReportsLanding} />

                    </Switch>
                    

                </div>
            </div>
        )
    }
}

export default withAuth(AttendanceReportContainer);