import React, { Component } from 'react';
import { withStyles, Card, CardHeader, CardContent, Typography, Table, TableBody, TableRow, TableCell, Grid } from '@material-ui/core';

const styles = theme => ({

})

class AttendanceViewStudent extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div>
                <Card>
                    <CardHeader
                        title={this.props.name}
                        subheader={`Roll No. ${this.props.roll_no}`}
                    />
                    <CardContent>
                        <Table sice="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Course: {this.props.course}
                                    </TableCell>
                                    <TableCell>
                                        Stream: {this.props.stream}
                                    </TableCell>

                                    <TableCell>
                                        {this.props.course == 'TDC' ? (`Course Type: ${this.props.course_type}`) : null}
                                    </TableCell>

                                    <TableCell>
                                        {this.props.course == 'TDC' ? (`Semester: ${this.props.semester}`) : (`Year: ${this.props.semester}`)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        {Array.isArray(this.props.attendance_data) && this.props.attendance_data.length > 0 ? (<Grid container spacing={2}>
                            {this.props.attendance_data.map((el, index) =>

                                <Grid item xs={4} sm={4} md={3} lg={3} key={index}>
                                    <Card>
                                        <CardHeader
                                            title={el.sub_name}
                                            subheader={el.sub_code}
                                        />
                                        <CardContent>

                                            <Grid container spacing={24}>
                                                <Grid item xs={6} sm={6} lg={6} md={6}>
                                                    <Typography
                                                        variant="subtitle2"
                                                    >
                                                        Total
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {el.attendance['total_class']}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={6} lg={6} md={6}>
                                                    <Typography
                                                        variant="subtitle2"
                                                    >
                                                        Attended
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {el.attendance['attended_class']}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={6} lg={6} md={6}>
                                                    <Typography
                                                        variant="subtitle2"
                                                    >
                                                        Not Attended
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {el.attendance['not_attended_class']}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={6} lg={6} md={6}>
                                                    <Typography
                                                        variant="subtitle2"
                                                    >
                                                        Percentage
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {el.attendance['percentage']}%
                                                    </Typography>
                                                </Grid>

                                            </Grid>





                                        </CardContent>
                                    </Card>
                                </Grid>

                            )}
                        </Grid>) : <div><Typography align="center">No data found</Typography></div>}
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AttendanceViewStudent);