import React, { Component } from 'react'
import { withStyles, Modal, Typography, IconButton, Grid, Button, Table, TableBody, TableCell, TableRow, Chip } from '@material-ui/core';
import Close from '@material-ui/icons/Close'
import Print from '@material-ui/icons/Print'
import Call from '@material-ui/icons/Call'
import Email from '@material-ui/icons/Email'
import ReactToPrint from "react-to-print";
import { GetData } from '../../../api/service';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        minHeight: '100vh'
    },
    pullRight: {
        float: 'center'
    },
    image: {
        width: '100%'
    }
});


class EmployeeModal extends Component {
    state = {
        data: [],
        isLoaded: false
    }
    componentDidMount() {
        let id = this.props.view_data;
        GetData(`/${id}/getstudentsingle`)
            .then((resp) => {
                console.log(resp)
                if(Array.isArray(resp) && resp.length > 0){
                    this.setState({
                        data: resp[0],
                        isLoaded: true
                    })
                }
                
            })
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <Modal
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <div className={classes.paper}>
                        <div className={classes.pullRight}>
                            <ReactToPrint
                                trigger={() => <IconButton>
                                    <Print />
                                </IconButton>}
                                content={() => this.componentRef}
                            />


                            <IconButton onClick={this.props.handleClose}>
                                <Close />
                            </IconButton>
                        </div>
                    
                    {this.state.isLoaded ? (
                            <Details
                                {...this.props}
                                {...this.state.data}
                                ref={el => (this.componentRef = el)} />
                    ) : 'Loading . .  '}
                        

                    </div>
                </Modal>
            </div>
        )
    }
}

export default withStyles(styles)(EmployeeModal);


class Details extends Component {
    
    getSubjects = (subjects) => {
        let r = '';
        if(Array.isArray(subjects) && subjects.length > 0){
            r = subjects.map((el,index) => {
                return el.label
            })
        }
        return r.join(',');
    }
    render() {
        let { classes } = this.props;

        return (
            <div>
                <Grid container spacing={24}>

                    <Grid item>
                        <Typography variant="h6" id="modal-title">
                            {this.props.name}
                        </Typography>
                        <Typography variant="subtitle1" id="simple-modal-description">
                            {this.props.course}, {this.props.stream}
                        </Typography>
                        <Typography variant="subtitle1" id="simple-modal-description">
                            <Button>
                                <Call /> {this.props.phone}
                            </Button>

                            <Button>
                                <Email /> {this.props.email}
                            </Button>
                        </Typography>

                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Father's Name
                            </TableCell>
                                    <TableCell>
                                        {this.props.father_name}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Mother's Name
                            </TableCell>
                                    <TableCell>
                                        {this.props.mother_name}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan="2">
                                        Subjects Taken : 

                                        {this.getSubjects(this.props.subjects)}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Gender
                                    </TableCell>
                                    <TableCell>
                                        {this.props.gender}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Caste
                                    </TableCell>
                                    <TableCell>
                                        {this.props.caste}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Date of birth
                            </TableCell>
                                    <TableCell>
                                        {this.props.date_of_birth}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Date of Admission
                            </TableCell>
                                    <TableCell>
                                        {this.props.date_of_admission}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Present Address
                            </TableCell>
                                    <TableCell>
                                        {this.props.present_address}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Permanent Address
                            </TableCell>
                                    <TableCell>
                                        {this.props.permanent_address}
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

            </div>
        )
    }

}