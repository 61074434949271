import React, { Component } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import { withStyles, Card, CardContent, Typography } from '@material-ui/core';
import Report from '@material-ui/icons/Report';
import { lightBlue, red } from '@material-ui/core/colors';

import PostAdmissionData from './View/PostAdmissionData';



const styles = theme => ({
    smallItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'all 0.1s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    ava: {
        width: 80,
        height: 80,
        backgroundColor: lightBlue[300],
        marginBottom: '8px',
    },
    icon: {
        fontSize: 35
    },
    noAccessDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: red[700]
    },
    noAccessIcon: {
        color: red[300]
    },
    navBarSmall: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 14,
        marginTop: '10vh',
        marginLeft: 240,
        backgroundColor: 'rgba(0,0,0,0.8)',
        height: '7vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: '7vh',
            height: '10vh',
            overflowX: 'scroll',
        }
    },
    navBarItem: {
        color: 'white',
        paddingLeft: '10px',
        paddingRight: '10px',
        '&:hover': {
            color: 'blue'
        },
        borderRight: 'solid thin white',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '40%',
            paddingLeft: '10px',
            paddingRight: '10px',
        }
    },
    
});


const newNavs = [
    {
        title: 'Student',
        path: '/reports'
    },
   
]

class ReportsLanding extends Component {


    render() {
        let { classes } = this.props;

        return (
            <div>
                <div className={classes.container}>
                    <Switch>
                        <Route exact path="/studentreport"
                            render={() => <PostAdmissionData
                                link={`getdocuments`}
                                title={`Inbox`}
                                history={this.props.history}
                                username={this.props.username}
                                name={this.props.name}
                                apikey={this.props.apikey}
                            />} />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ReportsLanding);