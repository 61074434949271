import React, { Component } from 'react'
import Employee from './Add/Employee';

export default class EmployeeLanding extends Component {
    constructor(props) {
        super(props);

        let ap = JSON.parse(localStorage.getItem('apikey'));

        this.state = {
            apikey: ap
        }

    }
  render() {
    return (
      <div>
        <Employee apikey={this.state.apikey} />
      </div>
    )
  }
}
