import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import { GetData } from '../../../api/service';
import Sel from 'react-select';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        minWidth: '100%'
    }
})

class SubjectAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assigned_to: '',
            assigned_by: 'superadmin@panducollege.org',
            permission: '',
            access_field: '',
            id: '',
            teachers: [],
            isTeacherLoaded: false
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })


    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.setState({
                assigned_to: '',
                assigned_by: 'superadmin@panducollege.org',
                permission: '',
                access_field: '',
                id: ''
            })
        }

        //get list
        GetData('/getemployeesmalldetails')
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    teachers: resp,
                    isTeacherLoaded: true
                })
            })


    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.setState({
                    assigned_to: '',
                    assigned_by: 'superadmin@panducollege.org',
                    permission: '',
                    access_field: '',
                    id: ''
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        let { id, assigned_to, assigned_by, permission, access_field } = this.state;

        const data = {
            id, assigned_to, assigned_by, permission, access_field
        }

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);

        }

        this.setState({
            assigned_to: '',
            assigned_by: 'superadmin@panducollege.org',
            permission: '',
            access_field: '',
            id: ''
        })

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        let teachers = [];
        if (this.state.isTeacherLoaded) {
            let t = this.state.teachers;
            if (Array.isArray(t) && t.length > 0) {
                teachers = t.map((el, index) =>
                    <MenuItem key={index} value={el.email}>{el.name}</MenuItem>
                )
            }
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <Grid container spacing={24}>

                                <Grid item xs={12} lg={12}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Assigned To</InputLabel>
                                        <Select
                                            value={this.state.assigned_to}
                                            onChange={this.onChange}
                                            name="assigned_to"
                                            inputProps={{
                                                name: 'assigned_to'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {teachers}
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={6} lg={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Permission</InputLabel>
                                        <Select
                                            value={this.state.permission}
                                            onChange={this.onChange}
                                            name="permission"
                                            inputProps={{
                                                name: 'permission'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="R">Read</MenuItem>
                                            <MenuItem value="RW">Read & Write</MenuItem>
                                            <MenuItem value="ALL">READ , WRITE & EXECUTE</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                

                                <Grid item xs={6} lg={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Access Field</InputLabel>
                                        <Select
                                            value={this.state.access_field}
                                            onChange={this.onChange}
                                            name="access_field"
                                            inputProps={{
                                                name: 'access_field'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="EGOV">E-Governance</MenuItem>
                                            <MenuItem value="ACC">Accounts</MenuItem>
                                            <MenuItem value="INV">Inventory</MenuItem>
                                            <MenuItem value="ADM">Admisson</MenuItem>
                                            <MenuItem value="FEE">Fees Collection</MenuItem>
                                            <MenuItem value="REP">Reports</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <br />
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(SubjectAdd)