import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid, Chip } from '@material-ui/core';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '80%'
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category_id: '',
            title_en: '',
            title_as: '',
            question_options: [],
            label_en: '',
            label_as: '',
            value: '',
            id: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.addOption = this.addOption.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.edit) {
            let qo = [];
            if(this.props.edit_data.question_options === null){
                qo = [];
            }
            this.setState({
                ...this.props.edit_data,
                
            })
        } else {
            this.setState({
                category_id: '',
                title_en: '',
                title_as: '',
                question_options: [],
                label_en: '',
                label_as: '',
                value: '',
                id: ''
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.edit !== this.props.edit) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                /* this.setState({
                    category_id: '',
                    title_en: '',
                    title_as: '',
                    question_options: [],
                    label_en: '',
                    label_as: '',
                    value: '',
                    id: ''
                }) */
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            this.setState({
                category_id: '',
                title_en: '',
                title_as: '',
                question_options: [],
                label_en: '',
                label_as: '',
                value: '',
                id: ''
            })
        }

    }

    addOption(){
        let {label_en, label_as, value, question_options} = this.state;

        if(label_en !== "" && value !== ""){
            const data = {
                label_en: label_en,
                label_as: label_as,
                value: value
            }

            question_options.push(data);

            this.setState({
                question_options,
                label_en: '',
                label_as: '',
                value: ''
            })

        }
    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    onDeleteOp(el,index){
        let question_options = this.state.question_options;
        question_options.splice(index,1);
        this.setState({
            question_options
        })
    }

    render() {
        let { classes } = this.props;

        let c = [];
        if (this.props.isCategoryLoaded) {
            let category = this.props.category;
            if (Array.isArray(category) && category.length > 0) {
                c = category.map((el, index) =>
                    <MenuItem key={index} value={el.id}>{el.name_en}</MenuItem>
                )
            }
        }


        let op = [];
        let option = this.state.question_options;
        if(Array.isArray(option) && option.length > 0){
            op = option.map((el,index) =>
                <Chip
                    key={index}
                    label={el.label_en}
                    onDelete={this.onDeleteOp.bind(this,el,index)}
                    color="primary"
                />
            )
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <FormControl className={classes.formControl}>
                                <InputLabel shrink className={classes.textStyle}>Choose Category</InputLabel>
                                <Select
                                    value={this.state.category_id}
                                    onChange={this.onChange}
                                    name="category_id"
                                    inputProps={{
                                        name: 'category_id',
                                        className: classes.textField
                                    }}
                                    className={classes.textField}

                                >
                                    <MenuItem value=""><em>Choose Category</em></MenuItem>
                                    {c}
                                </Select>
                            </FormControl>



                            <br />
                            <br />


                            <TextField
                                label="Question (in English) "
                                name="title_en"
                                className={classes.textField}
                                type="text"
                                multiline
                                rows="4"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.title_en}
                                onChange={this.onChange}
                            />


                            {/* <TextField
                                label="Question (in Assamese) "
                                name="title_as"
                                className={classes.textField}
                                type="text"
                                multiline
                                rows="4"
                                margin="normal"
                                
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.title_as}
                                onChange={this.onChange}
                            /> */}

                            <br />
                            {op}
                            <br/>
                            <Grid container spacing={2} style={{backgroundColor: '#f2f2f2'}}>

                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        label="Label (EN)"
                                        name="label_en"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                   
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.label_en}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                {/* <Grid item xs={12} lg={3}>
                                    <TextField
                                        label="Label (AS)"
                                        name="label_as"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                 
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.label_as}
                                        onChange={this.onChange}
                                    />
                                </Grid> */}

                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        label="Value"
                                        name="value"
                                        className={classes.textField}
                                        type="text"
                                        helperText="eg. A (must be capital)"
                                        margin="normal"
                                  
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.value}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={2}>
                                <br/>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        onClick={this.addOption}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>



                            <br />
                            <br />
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)

