import React, { Component } from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@material-ui/core';
import Sel from 'react-select';

const styles = theme => ({

})

class AddNewStudent extends Component {
    state = {
        student_obj: '',
        students_list: this.props.students_list,
        finalStudents: []
    }


    addNewStudent = () => {

        

        this.props.addNewStudent(this.state.student_obj)
    }

    onStudentChange = (name,value) => {
        this.setState({
            [name]: value
        })
    }
    render() {
        let {classes} = this.props;
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                >
                    <DialogTitle id="alert-dialog-title">{"Select the students you want to add in the attendance"}</DialogTitle>
                    <DialogContent style={{ height: '80vh' }}>
                        <DialogContentText id="alert-dialog-description">
                            This is a multiple select panel. You can choose more than one student att a time. After complete selection please press the add button to continue
                            <br />

                            <Sel
                                options={this.props.students_list}
                                value={this.state.student_obj}
                                onChange={this.onStudentChange.bind(this,'student_obj')}
                                isMulti
                            />
          </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
          </Button>
                        <Button onClick={this.addNewStudent} color="primary" autoFocus>
                            Add
          </Button>
                    </DialogActions>
                    </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AddNewStudent);