import React, { Component } from 'react'
import { withStyles, Button, Divider } from '@material-ui/core';
import { GetData, PostData } from '../../api/service';
import PromotionTable from './PromotionTable';

const styles = theme => ({

})

class PromotionLanding extends Component {

    state = {
        isLoading: false,
        studentData: [],
        isLoaded: false,
    }


    getStudentData = () => {
        this.setState({
            isLoading: true
        })

        GetData(`/getstudentpromotiondata`)
            .then((resp) => {
                console.log(resp)
                this.setState({
                    studentData: resp,
                    isLoading: false,
                    isLoaded: true
                })
            })

    }

    promoteAll = () => {
        
        PostData(`/promotestudentall`)
            .then((resp) => {
                console.log(resp)
            })

    }
    render() {
        let { classes } = this.props;
        return (
            <div>
                <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={this.getStudentData}
                >
                    Analayze Data
        </Button>


                <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={this.promoteAll}
                >
                    Promote All
        </Button>

                <br />
                <br />

                {this.state.isLoaded ?
                    <PromotionTable
                        table_data={this.state.studentData}
                    />
                    : null
                }

            </div>
        )
    }
}

export default withStyles(styles)(PromotionLanding)