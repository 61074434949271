import React, { Component } from 'react'
import { withStyles, Grid, FormControl, InputLabel, Select, MenuItem, Button, TextField, Chip } from '@material-ui/core';

const styles = theme => ({
    formControl: {
        width: '100%',
        marginTop: '17px'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    button: {
        marginTop: '17px'
    }
})

class Details extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'QUALIFICATION',
            details: '',
            qualification: []
        }

        this.onChange = this.onChange.bind(this);
        this.addFile = this.addFile.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onDpDelete(index) {
        let dp = this.state.qualification;
        dp.splice(index, 1);
        this.props.setDp(dp);
        this.setState({
            qualification: dp
        })
    }

    addFile() {
        let { type, details } = this.state;
        let qualification = this.state.qualification;
        if (type !== '' && details !== '') {
            qualification.push({
                type: type,
                details: details
            })

            this.setState({
                qualification,
                details: ''
            })
        }

    }

    render() {
        let { classes } = this.props;


        let d = [];
        let dp = this.state.qualification;
        if (Array.isArray(dp) && dp.length > 0) {

            d = dp.map((el, index) =>
                <Chip
                    key={index}
                    label={el.type + ': ' + el.details}
                    onDelete={this.onDpDelete.bind(this, index)}
                />
            )

        }

        let d1 = [];
        let type = this.props.type;
        d1 = type.map((el,index) =>
            <MenuItem key={index} value={el}>{el}</MenuItem>
        )
        return (
            <div>
                <Grid container>
                    <Grid item xs={3} lg={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink className={classes.textStyle}>Type</InputLabel>
                            <Select
                                value={this.state.type}
                                onChange={this.onChange}
                                name="type"
                                inputProps={{
                                    name: 'type'
                                }}

                            >

                                {d1}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={7} lg={7}>
                        <TextField
                            label="Details"
                            name="details"
                            className={classes.textField}
                            type="text"

                            margin="normal"

                            InputLabelProps={{
                                classes: {
                                    root: classes.textStyle
                                }
                            }}
                            value={this.state.details}
                            onChange={this.onChange}
                        />
                    </Grid>

                    <Grid item xs={2} lg={2}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={this.addFile}
                        >
                            Add
                        </Button>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        {d}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Details)