import React, { Component } from 'react'
import { withStyles, Button, Typography } from '@material-ui/core';
import { GetData } from '../../../../api/service';
//import SmallBread from '../../../../SmallBread';
import ReactToPrint from 'react-to-print';
import Print from '@material-ui/icons/Print'
import './print.css'

const styles = theme => ({
    printContainer: {
        padding: '20px',
        textAlign: 'center'
    },
    billContainer: {
        border: 'solid thin lightgrey',
        width: '100%',
        minHeight: '100vh'
    },
    certiText: {
        fontSize: '1.3em',
        padding: '10px 0px 10px 0px',
        fontStyle: 'italic',
        align: "center",
        lineHeight: '2em',
        align: 'justify'
    }
})

class PrintCer extends Component {
    constructor(props) {
        super(props);

        let id = this.props.history.location.state;


        this.state = {
            id: id,
            cer_data: [],
            isLoadded: false
        }

    }

    getDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return today = dd + '/' + mm + '/' + yyyy;
        //document.write(today);
    }

    componentDidMount() {
        let id = this.props.history.location.state;
        //console.log(id);
        if (id !== undefined) {
            GetData(`/${this.props.apikey}/${this.props.username}/${id}/getcertificatesingle`)
                .then((resp) => {
                    console.log(resp);
                    this.setState({
                        cer_data: resp[0],
                        isLoadded: true
                    })
                })
        } else {
            this.props.history.replace('/certificate')
        }

    }



    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>


                <div className={classes.printContainer}>
                    <ReactToPrint
                        trigger={() => <Button color="primary" variant="outlined"><Print /> Print </Button>}
                        content={() => this.componentRef}
                        closeAfterPrint={true}
                        pageStyle="margin: 10px"
                    />

                    <br />
                    <br />

                    {this.state.isLoadded ? <PrintFormat
                        {...this.props}
                        ref={el => (this.componentRef = el)}
                        data={this.state.cer_data}
                        isLoaded={this.state.isLoaded}
                        getDate={this.getDate}

                    /> : 'Loading....'}


                </div>

            </div>
        )
    }
}


export default withStyles(styles)(PrintCer);



class PrintFormat extends Component {


    render() {

        let { classes } = this.props;


        return (
            <div className="print-bill-container">

                <table width="100%" style={{ padding: '20px' }}>
                    <tbody>
                        <tr >
                            
                            <td align="center" colSpan="3">

                                <img
                                    src={require('./../../../../assets/logo.png')}
                                    style={{ width: '10%' }}
                                />

                                <Typography
                                    variant="h5"
                                >
                                    বীৰঝৰা মহাবিদ্যালয়
                                    </Typography>

                                <Typography
                                    variant="h5"
                                >
                                    BIRJHORA MAHAVIDYALAYA
                                    </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Main Road
                                    Sahid Bedi, Bongaigaon
                                    783380 INDIA
                                    </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    TEL: +91-03664 228808
                                    </Typography>
                            </td>
                            
                        </tr>




                        <tr>
                            <td align="left">
                                <Typography>
                                    Sl. No. {this.props.data.slr_no}
                                </Typography>
                            </td>
                            <td>&nbsp;</td>
                            <td align="right">
                                <Typography>
                                    Date: {this.props.data.entry_date}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">

                                <Typography
                                    className={classes.certiText}
                                >

                                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Certified that Shri / Miss / Mrs <b>{this.props.data.name} &nbsp;&nbsp;&nbsp;</b> Roll - <b>{this.props.data.roll}</b> &nbsp; No  -
<b>&nbsp;{this.props.data.no}</b> &nbsp; has passed  the <b>{this.props.data.course}</b> &nbsp; Part - <b>{this.props.data.part}</b>
                                    &nbsp;Examination Of the University of Gauhati from this College in the year - <b>{this.props.data.year}</b>&nbsp;
                               with  Major in the Subject - <b>{this.props.data.major}</b> and was placed in  the <b>{this.props.data.division}</b>
                                    &nbsp;Class / division.<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I know nothing against him/her character.<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I wish him/her success in life.
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <Typography>
                                    Place: Birjhora, Bongaigaon<br />
                                    Date: {this.props.getDate()}
                                </Typography>
                            </td>
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                <Typography align="center">
                                    Principal<br />
                                    Birjhora Mahavidyalaya<br />
                                    Main Road Sahid Bedi, Bongaigaon 783380
                                </Typography>

                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        )

    }
}
