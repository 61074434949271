import React, { Component } from 'react'
import { withStyles, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

const styles = theme => ({

})

class ViewTableModifiedPostAdmission extends Component {
    render() {
        return (
            <div>
                <TableData
                    data={this.props.data}
                    {...this.props}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ViewTableModifiedPostAdmission);



class TableData extends Component {

    subjectsView = (subjects) => {
        let a = [];

        if (Array.isArray(subjects) && subjects.length > 0) {
            a = subjects.map((el, index) => {
                return (
                    <tr key={index}>
                        <td>
                            {el.sub_name} (<b>
                                {
                                    el.is_compulsory === '1' ? 'Compulsory' : null
                                }

                                {
                                    el.is_MIL === '1' ? 'MIL' : null
                                }
                                {
                                    el.is_major === '1' ? 'Major' : null
                                }
                                {
                                    el.is_generic === '1' ? 'Generic' : null
                                }
                            </b>)
                        </td>
                    </tr>
                )
            }

            )
        }

        return <table>
            <tbody>
                {a}
            </tbody>
        </table>;
    }

    bestSubjectView = (data) => {

        let a = [];



        if (Array.isArray(data) && data.length > 0) {
            data.map((el, index) => {
                if (el.is_major !== '1' || el.type != 'major') {
                    if (el.sub_name != undefined) {
                        a.push(el.sub_name)
                    } else {
                        a.push(el.label)
                    }
                }


            }

            )
        } else {
            data = JSON.parse(data);
            if (Array.isArray(data) && data.length > 0) {
                data.map((el, index) => {
                    if (el.is_major !== '1' || el.type != 'major') {
                        if (el.sub_name != undefined) {
                            a.push(el.sub_name)
                        } else {
                            a.push(el.label)
                        }
                    }
                }

                )
            }

        }



        return a.join(',');
    }

    bestSubjectView1 = (data) => {

        let a = [];




        if (Array.isArray(data) && data.length > 0) {
            data.map((el, index) => {
                if (el.is_major === '1' || el.type == 'major') {
                    if (el.sub_name != undefined) {
                        a.push(el.sub_name)
                    } else {
                        a.push(el.label)
                    }
                }
            }

            )
        } else {
            data = JSON.parse(data);
            if (Array.isArray(data) && data.length > 0) {
                data.map((el, index) => {
                    if (el.is_major === '1' || el.type == 'major') {
                        if (el.sub_name != undefined) {
                            a.push(el.sub_name)
                        } else {
                            a.push(el.label)
                        }
                    }
                }

                )
            }

        }



        return a.join(',');
    }


    render() {
        return (
            <div>

                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Birjhora Mahavidyalaya
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Bongaigaon, Assam
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                    Student List
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                    Course: <b>{this.props.course}</b> &nbsp;&nbsp; Stream: <b>{this.props.stream}</b>
                                </Typography>

                                {this.props.course === 'TDC' && this.props.course_type !== '' ? (<Typography
                                    variant="body1"
                                >
                                    Course Type: <b>{this.props.course_type}</b>
                                </Typography>) : null}



                                {this.props.sub_name !== '' ? (<Typography
                                    variant="body1"
                                >
                                    Subject: <b>{this.props.sub_name}</b>
                                </Typography>) : null}
                            </td>

                            <td width="20%" align="center">
                                No of Students: {this.props.data.length}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ overflowX: 'scroll' }}>

                    <Table width="100%" className="table" id="table-to-xls" >
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Roll No</TableCell>
                                <TableCell>University Roll No</TableCell>
                                <TableCell>Student Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Major (if any)</TableCell>
                                <TableCell>Subjects Taken</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Father's Name</TableCell>
                                <TableCell>Address</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map((el, index) =>

                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{el.roll_no}</TableCell>
                                    <TableCell>{el.university_roll_no}</TableCell>
                                    <TableCell>{el.student_id}</TableCell>
                                    <TableCell>{el.name}</TableCell>
                                    <TableCell>{this.bestSubjectView1(el.subjects)}</TableCell>
                                    <TableCell>{this.bestSubjectView(el.subjects)}</TableCell>
                                    <TableCell>{el.phone}</TableCell>
                                    <TableCell>{el.gender}</TableCell>
                                    <TableCell>{el.father_name}</TableCell>
                                    <TableCell>{el.permanent_address}</TableCell>
                                </TableRow>

                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        )
    }
}