import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import MasterLanding from '../Components/Master/MasterLanding';
import EmployeeLanding from '../Components/Employee/EmployeeLanding';
import StudentLanding from '../Components/Student/StudentLanding';

class StudentContainer extends Component {
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">

                    <StudentLanding />

                </div>
            </div>
        )
    }
}

export default withAuth(StudentContainer);