import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Button } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
import Add from '@material-ui/icons/Add';
import { GetData } from '../../../api/service';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';



export default class CourseTable extends Component {

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    padding: "2px"
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    padding: "2px"
                }
            }
        }
    })

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false,
            page: 0,
            limit_low: 101
        }
    }

    componentDidMount() {
        this.setState({
            data: this.props.table_data
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.table_data.length !== this.props.table_data.length) {
            this.setState({
                data: this.props.table_data
            })
        }
    }





    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    onViewClick(el) {
        this.props.onViewClick(el);
    }

    loadMoreData = () => {
        GetData(`/${this.state.limit_low}/${this.state.limit_low + 100}/getstudentbylimit`)
            .then((resp) => {
                //console.log(resp);
                let data = this.state.data;
                data.push(...resp)
                this.setState({
                    data,
                    limit_low: data.length
                })
            })
    }

    bestSubjectView = (data) => {

        let a = [];




        if (Array.isArray(data) && data.length > 0) {
            data.map((el, index) => {

                a.push(el.label)

            }

            )
        }





        return a.join(',');
    }

    render() {
        const columns = [
            {
                name: "Year of Admission",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Roll No",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "GU Roll No",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Department",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Semester",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Name",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Contact",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Subject",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "View",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        let { page, count, limit_low } = this.state;

        let data = [];
        let table_data = this.state.data;

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            rowsPerPage: 100,
            customToolbar: () => {
                return (
                    <IconButton onClick={this.props.onAddClick}><Add /></IconButton>
                )
            }
        };


        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.year_of_admission,
                    el.roll_no,
                    el.university_roll_no,
                    el.course,
                    el.dept_code,
                    el.semester,
                    el.name,
                    el.phone + '\n' + el.email,
                    this.bestSubjectView(el.subjects),
                    <ZoomOutMap onClick={this.onViewClick.bind(this, el.id)} />,
                    <Launch onClick={this.onActionClick.bind(this, el.id)} />
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>

                {/* <div style={{ textAlign: 'center', padding: '10px' }}>
                    <Button
                        onClick={this.loadMoreData}
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Load More Data ({this.state.limit_low})
                </Button>
                </div> */}
                <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                        title={"Student List"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        )


    }
}
