import React, { Component } from 'react'
import { Card, CardHeader, Avatar, Typography, CardContent, FormControl, InputLabel, Select, MenuItem, OutlinedInput, CardActions, Button, withStyles, TextField, CircularProgress, Grid, Divider } from '@material-ui/core';
import Receipt from '@material-ui/icons/Receipt'
import { lightGreen, blue } from '@material-ui/core/colors';
import Search from '@material-ui/icons/Search'
import { PostData, GetData } from '../../api/service';

import Sel from 'react-select'
import SubjectWiseReportTable from './SubjectWiseReportTable';


const styles = theme => ({
    actions: {
        justifyContent: 'flex-end'
    },
    condtionDiv: {
        padding: theme.spacing.unit * 2,
        backgroundColor: lightGreen[50]
    },
    textField: {
        width: '50%'
    }
})

class SubjectWiseReport extends Component {
    state = {
        start_date: '',
        end_date: '',
        course: '',
        course_type: '',
        stream: '',
        semester: '',
        sub_code: '',
        sub_code_obj: {},
        subjects: [],
        isLoaded: false,
        data: [],
        test_label: [],
        loading: false,
        isSubjectLoaded: false
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setTable = (name, value) => {

        this.setState({
            [name]: value
        })
    }

    componentDidMount() {
        GetData(`/getpapercode`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    subjects: resp,
                    isSubjectLoaded: true
                })
            })
    }


    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        })

        let r = this.state.subjects.filter(el => el.paper_code == this.state.sub_code);

        if (r.length > 0) {
            let data = {
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                course: this.state.course,
                stream: this.state.stream,
                course_type: this.state.course_type,
                semester: this.state.semester,
                sub_code: this.state.sub_code,
                subject_code: r[0]['sub_code']
            }


            PostData(`/${this.props.apikey}/getclasstestreportsubjectwise`, data)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        test_label: resp[0],
                        data: resp[1],
                        isLoaded: true,
                        loading: false
                    })
                })
        }



    }

    onSubjectChange = (type, value) => {
        this.setState({
            [type]: value
        })

        if (type == 'sub_code_obj') {
            this.setState({
                sub_code: value.value
            })
        }

    }
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>

                    <Typography
                        variant="h6"
                    >
                        <u>Subject wise test report</u>
                    </Typography>





                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography>Starting Date</Typography>
                            <input
                                className="form-control"
                                type="date"
                                name="start_date"
                                value={this.state.start_date}
                                onChange={this.onChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography>Ending Date</Typography>
                            <input
                                className="form-control"
                                type="date"
                                name="end_date"
                                value={this.state.end_date}
                                onChange={this.onChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography>Course</Typography>
                            <select
                                className="form-control"
                                type="text"
                                name="course"
                                value={this.state.course}
                                onChange={this.onChange}
                            >
                                <option value="">Select</option>
                                {['TDC'].map((el, index) =>
                                    <option key={index} value={el}>{el}</option>
                                )}
                            </select>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography>Stream</Typography>
                            <select
                                className="form-control"
                                type="text"
                                name="stream"
                                value={this.state.stream}
                                onChange={this.onChange}
                            >
                                <option value="">Select</option>
                                {['SCIENCE'].map((el, index) =>
                                    <option key={index} value={el}>{el}</option>
                                )}
                            </select>
                        </Grid>
                        {this.state.course == 'TDC' ? (<Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography>Course Type</Typography>
                            <select
                                className="form-control"
                                type="text"
                                name="course_type"
                                value={this.state.course_type}
                                onChange={this.onChange}
                            >
                                <option value="">Select</option>
                                {['HONOURS', 'REGULAR'].map((el, index) =>
                                    <option key={index} value={el}>{el}</option>
                                )}
                            </select>
                        </Grid>) : null}
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography>{this.state.course == 'HS' ? 'Year' : 'Semester'}</Typography>
                            <select
                                className="form-control"
                                type="text"
                                name="semester"
                                value={this.state.semester}
                                onChange={this.onChange}
                            >
                                <option value="">Select</option>
                                {[1, 2, 3, 4, 5, 6].map((el, index) =>
                                    <option key={index} value={el}>{el}</option>
                                )}
                            </select>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} style={{ zIndex: 1000 }}>
                            <Typography>Select Subject</Typography>
                            <Sel
                                value={this.state.sub_code_obj}
                                options={this.state.subjects}
                                isLoading={!this.state.isSubjectLoaded}
                                onChange={this.onSubjectChange.bind(this, 'sub_code_obj')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <br />
                            <Button
                                type="submit"
                                variant="outlined"
                                color="secondary"
                            >
                                {this.state.loading ? <span>Loading . . . </span> : <span>Perform Advance Search</span>}
                            </Button>
                        </Grid>
                    </Grid>





                </form>
                <br />

                <Divider />

                {this.state.isLoaded ? (
                    <SubjectWiseReportTable
                        test_label={this.state.test_label}
                        data={this.state.data}
                        course={this.state.course}
                        stream={this.state.stream}
                        semester={this.state.semester}
                        course_type={this.state.course_type}
                        sub_code={this.state.sub_code}
                    />
                ) : null}




            </div>
        )
    }
}

export default withStyles(styles)(SubjectWiseReport)