import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { PostData, GetData } from './../../api/service'
import { Grid, Card, CardContent, Typography, CardActions, Button, withStyles, Divider } from '@material-ui/core';
import Grade from '@material-ui/icons/Grade';
import Schedule from '@material-ui/icons/Schedule';
import Commute from '@material-ui/icons/Commute';
import Person from '@material-ui/icons/Person';
const styles = {
    card: {
        minWidth: 275,
    },
    details: {
        display: 'flex',
        flexDirection: 'row'
    },
    content: {
        flex: '1 0 auto'
    }, 
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 22,
    },
    pos: {
        marginBottom: 12,
    },
    backPrimary: {
        backgroundColor: '#d500f9',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSecondary: {
        backgroundColor: '#e91e63',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSec: {
        backgroundColor: '#009688',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    }
};


class TopLabel extends Component {
    state = {
        student: 0,
        employee: 0,
        isLoaded: false,
        small_data: [],
        smallLoaded: false
    }

    componentDidMount() {
            
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //As January is 0.
        var yyyy = today.getFullYear();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        let t = dd + '-' + mm + '-' + yyyy;

        let sd = {
            date: t,
            teacher: ''
        }
        PostData(`/getattendancereports`, sd)
            .then((resp) => {
                
                this.setState({
                    small_data: resp,
                    smallLoaded: true
                })
            })



            //get student and employee count

            GetData('/count/init')
            .then((resp) => {
                this.setState({
                    employee: resp.employee,
                    student: resp.student
                })
            })
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ padding: '30px' }}>
                <Grid container spacing={2}>
                    {/* <Grid item xs={12} lg={4}>
                        <Link to="/notifications">
                            <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Notice Management
                                    </Typography>
                                        <Typography variant="h6" component="h2">

                                        </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backPrimary}>
                                        <Grade fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>
                        </Link>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <Link to="/master/routine">
                            <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Class Management
                                    </Typography>
                                        <Typography variant="h6" component="h2">

                                        </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backSecondary}>
                                        <Schedule fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>
                        </Link>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <Link to="/leave/status">
                            <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Leave Management
                                    </Typography>
                                        <Typography variant="h6" component="h2">

                                        </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backSec}>
                                        <Commute fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>
                        </Link>
                    </Grid> */}

                    <Grid item xs={12} lg={4}>
                        <Link to="/student">
                            <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Students
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            {this.state.student}
                                        </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backSecondary}>
                                        <Person fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Link to="/employee">
                            <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Employees
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            {this.state.employee}
                                        </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backPrimary}>
                                        <Person fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
                <br />

                <Divider />

                <br />



                {this.state.smallLoaded ? (

                    <Grid container spacing={2}>

                        <Grid item xs={12} lg={4}>

                            <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Allotted Classes
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            {this.state.small_data.alloted}
                                        </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backPrimary}>
                                        <Grade fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>

                        </Grid>

                        <Grid item xs={12} lg={4}>

                            <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Held Classes
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            {this.state.small_data.held}
                                        </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backPrimary}>
                                        <Grade fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>

                        </Grid>

                        <Grid item xs={12} lg={4}>

                            <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Canceled Classes
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            {this.state.small_data.canceled}
                                        </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backPrimary}>
                                        <Grade fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>

                        </Grid>

                        <Grid item xs={12} lg={4}>

                            <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Not Attended Classes
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            {this.state.small_data.not_attended}
                                        </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backPrimary}>
                                        <Grade fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>

                        </Grid>




                    </Grid>
                ) : null}



            </div>
        )
    }
}


export default withStyles(styles)(TopLabel);