import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Button } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import InsertPhoto from '@material-ui/icons/InsertPhoto';
import Add from '@material-ui/icons/Add';



export default class AttendanceReportTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
        //hey
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onPhotoClick(el) {
        this.props.photoClick(el);
    }

    onClick() {
        console.log("hey");
    }

    showData(value) {
        if (Array.isArray(value) && value.length > 0) {
            let new_arr = value.map((el, index) => el.label);

            return new_arr.join(",");
        } else {
            return '';
        }
    }



    render() {
        const columns = [
            {
                name: "Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Day",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Department",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Teacher",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "In Time",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Subject",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Subject Code",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Semester",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Section",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Edit",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "View",
                options: {
                    filter: false,
                    sort: false,
                }
            },

        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton><Add onClick={this.props.onAddClick} /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.date1,
                    el.day,
                    el.dept_name,
                    el.name,
                    el.time_stamp,
                    el.sub_name,
                    el.sub_code,
                    el.semester,
                    el.section,
                    <Button
                        size="small"
                        color="primary"
                        href={`/editattendance/${el.id}`}
                    >
                        Edit
                    </Button>,
                    <Launch onClick={this.onActionClick.bind(this, el.id)} />]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Attendance Report"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        )


    }
}
