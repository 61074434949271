import React, { Component } from 'react';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { GetData } from '../../../api/service';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class FeedbackAnalysisStudent extends Component {
    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        GetData(`/${this.props.apikey}/getfeedbackanalysisofstudent`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    getValue = (data, value) => {
        let r = data.filter(el => el.value == value);
        return r[0].count;
    }
    render() {
        return (
            <div>
                <Typography
                    variant="h6"
                    gutterBottom
                >
                    <u>Student's Feedback Analysis --- Current Session</u>
                </Typography>
                <Typography
                    variant="caption"
                >
                    This is the actual analysis report of every question of this session. Feedbacks on teachers will be displayed for every teacher. You can export it and later segregate it as required.
                </Typography>
                <br />

                {this.state.isLoaded ? (<div>
                    <ReactHTMLTableToExcel
                        table="table-to-xls"
                        filename="Student Feedback Analysis Current Session"
                        sheet="tablexls"
                        buttonText="Download as XLS" />
                    <br />
                    <br />
                    <Table style={{ backgroundColor: 'white' }} size="small" id="table-to-xls">
                        <TableHead>
                            <TableRow>
                                <TableCell >#</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell width="50%">Question</TableCell>
                                <TableCell align="center">Answers</TableCell>
                                
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {this.state.data.map((el, index) =>
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{el.name_en}</TableCell>
                                    <TableCell>{el.title_en}</TableCell>
                                    <TableCell>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="none">&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
                                                    {el.question_options.map((el1, index1) =>
                                                        <TableCell padding="none" key={index1}>{el1}</TableCell>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {el.data.map((el1, index1) =>

                                                    <TableRow key={index1}>
                                                        <TableCell width="60%" padding="none">{el1.teacher_name}</TableCell>
                                                        {el.question_options.map((el2, index2) =>
                                                            <TableCell padding="none" key={index2}>{this.getValue(el1.data, el2)}</TableCell>
                                                        )}

                                                    </TableRow>

                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>

                            )}
                        </TableBody>



                    </Table>
                   
                </div>) : (<div>
                    <Typography>
                        Loading . . . .
                   </Typography>
                </div>)}

            </div>
        );
    }
}

export default FeedbackAnalysisStudent;