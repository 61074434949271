import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import Sel from 'react-select';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        width: '100%',
        marginTop: '10px'
    }
})

class PaperAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sub_codes: [],
            isSubLoaded: false,
            sub_id: '',
            sub_type: '',
            paper_code: '',
            sub_title: '',
            sub_tp_type: '',
            total_marks: '',
            pass_marks: '',
            semester: '',
            id: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    handleSChange = (name, sub_code) => {
        this.setState({
            [name]: sub_code
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.setState({
                sub_id: '',
                paper_code: '',
                sub_title: '',
                sub_tp_type: '',
                sub_type: '',
                total_marks: '',
                pass_marks: '',
                semester: '',
                id: ''
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.setState({
                    sub_id: '',
                    paper_code: '',
                    sub_title: '',
                    sub_tp_type: '',
                    sub_type: '',
                    total_marks: '',
                    pass_marks: '',
                    semester: '',
                    id: ''
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            this.setState({
                sub_id: '',
                paper_code: '',
                sub_title: '',
                sub_type: '',
                sub_tp_type: '',
                total_marks: '',
                pass_marks: '',
                semester: '',
                id: ''
            })
        }

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        let i = [];
        if (this.props.isSubLoaded) {
            let sub_codes = this.props.subjects;
            if (Array.isArray(sub_codes) && sub_codes.length > 0) {
                i = sub_codes.map((el, index) =>
                    <MenuItem key={index} value={el.id}>{el.sub_code} - {el.sub_name}</MenuItem>
                )
            }


        }


        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <Grid container spacing={2}>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Subejct</InputLabel>
                                        <Select
                                            value={this.state.sub_id}
                                            onChange={this.onChange}
                                            name="sub_id"
                                            inputProps={{
                                                name: 'sub_id'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {i}
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Semester</InputLabel>
                                        <Select
                                            value={this.state.semester}
                                            onChange={this.onChange}
                                            name="semester"
                                            inputProps={{
                                                name: 'semester'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {[1, 2, 3, 4, 5, 6].map((el, index) =>
                                                <MenuItem key={index} value={el}>{el}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>

                                </Grid>




                                <Grid item xs={12} lg={12} md={12}>
                                    <TextField
                                        label="Paper Code"
                                        className={classes.textField}
                                        type="text"
                                        name="paper_code"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.paper_code}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12} md={12}>
                                    <TextField
                                        label="Paper Name"
                                        name="sub_title"
                                        className={classes.textField}
                                        type="text"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.sub_title}
                                        onChange={this.onChange}
                                    />

                                </Grid>



                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Course Type</InputLabel>
                                        <Select
                                            value={this.state.sub_type}
                                            onChange={this.onChange}
                                            name="sub_type"
                                            inputProps={{
                                                name: 'sub_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="HC">Honours Core</MenuItem>
                                            <MenuItem value="RC">Regular Core</MenuItem>
                                            <MenuItem value="HG">Honours Generic</MenuItem>
                                            <MenuItem value="AE">Ability Enhencement Compulsory</MenuItem>
                                            <MenuItem value="PR">Practical</MenuItem>
                                            <MenuItem value="Lab">Laboratory</MenuItem>
                                            <MenuItem value="TT">Tutorial</MenuItem>
                                            <MenuItem value="SE">Skill Enhancement Course</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Theory/Practical</InputLabel>
                                        <Select
                                            value={this.state.sub_tp_type}
                                            onChange={this.onChange}
                                            name="sub_tp_type"
                                            inputProps={{
                                                name: 'sub_tp_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Theory">Theory</MenuItem>
                                            <MenuItem value="Practical">Practical</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>



                                <Grid item xs={12} lg={6} md={6}>

                                    <TextField
                                        label="Total Marks"
                                        className={classes.textField}
                                        type="number"
                                        name="total_marks"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.total_marks}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <TextField
                                        label="Pass Marks"
                                        className={classes.textField}
                                        type="number"
                                        name="pass_marks"
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.pass_marks}
                                        onChange={this.onChange}
                                    />
                                </Grid>





                            </Grid>

                            <br />

                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(PaperAdd)