import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import Sel from 'react-select'

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    }
})

const periods = [
    {
        period: 1,
        start_time: '08:30',
        end_time: '09:30'
    },
    {
        period: 2,
        start_time: '09:30',
        end_time: '10:15'
    },
    {
        period: 3,
        start_time: '10:15',
        end_time: '11:00'
    },
    {
        period: 4,
        start_time: '11:00',
        end_time: '11:45'
    },
    {
        period: 5,
        start_time: '11:45',
        end_time: '12:45'
    },
    {
        period: 6,
        start_time: '12:45',
        end_time: '13:45'
    },
    {
        period: 7,
        start_time: '13:45',
        end_time: '14:30'
    },
    {
        period: 8,
        start_time: '14:30',
        end_time: '15:15'
    },
    {
        period: 9,
        start_time: '15:15',
        end_time: '16:00'
    },
    {
        period: 10,
        start_time: '16:00',
        end_time: '16:45'
    }
]

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            session: '',
            course: '',
            stream: '',
            semester: '',
            day: '',
            sub_code: '',
            f_subjects_value: {},
            teacher_value: {},
            course_type: '',
            teacher: '',
            
            id: '',
            term_name: 'TERM',
            term_list: [1, 2, 3, 4, 5, 6],
            room_number: '',
            period_number: '',
            start_time: '',
            end_time: '',
            section: '',
            f_subjects: []
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getInitialState = () => {
        this.setState({
            session: '',
            course: '',
            stream: '',
            semester: '',
            day: '',
            sub_code: '',
            f_subjects_value: {},
            teacher_value: {},
            course_type: '',
            teacher: '',
            id: '',
            term_name: 'TERM',
            term_list: [1, 2, 3, 4, 5, 6],
            room_number: '',
            period_number: '',
            start_time: '',
            end_time: '',
            section: ''
        })
    }

    __getSubInitialState = () => {
        this.setState({
            
            sub_code: '',
            course_type: '',
            teacher: '',
            id: '',
            term_name: 'TERM',
            term_list: [1, 2, 3, 4, 5, 6],
            room_number: '',
            f_subjects_value: {},
            teacher_value: {},
            period_number: '',
            start_time: '',
            end_time: '',
            section: ''
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'course') {
            let term_name = ""; let term_list = [];
            let course = this.props.courses;
            let results = course.filter((el) => el.name === e.target.value);
            if (results.length > 0) {
                term_name = results[0].tenure;
                let no_of_term = parseFloat(results[0].no_of_term);
                for (let i = 1; i <= no_of_term; i++) {
                    term_list.push(i);
                }
                this.setState({
                    term_name,
                    term_list
                })
            }
        }

        if (e.target.name === 'course') {
            let subjects = this.props.subjects;
            let r = subjects.filter(el => el.course == e.target.value)
            //console.log(r)
            this.setState({
                f_subjects: r
            })
        }

        if (e.target.name === 'period_number') {
            let period_number = parseInt(e.target.value);
            let r = periods.filter(el => el.period == period_number)
            if(r.length > 0){
                this.setState({
                    start_time: r[0].start_time,
                    end_time: r[0].end_time
                })
            }
            
        }
    }

    componentDidMount() {
        if (this.props.edit) {

            let subr = this.props.subjects.filter(el => el.value == this.props.edit_data.sub_code);
            let tear = this.props.teachers.filter(el => el.value == this.props.edit_data.teacher);

            this.setState({
                ...this.props.edit_data, f_subjects: this.props.subjects,
                f_subjects_value: subr,
                teacher_value: tear
            })
        } else {
            this.__getInitialState()
        }




    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                let subr = this.props.subjects.filter(el => el.value == this.props.edit_data.sub_code);
                let tear = this.props.teachers.filter(el => el.value == this.props.edit_data.teacher);

                this.setState({
                    ...this.props.edit_data,
                    f_subjects: this.props.subjects,
                    f_subjects_value: subr,
                    teacher_value: tear
                })
            } else {
                this.__getSubInitialState()
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;


        if (this.props.edit) {
            this.props.esetData(data);
            this.__getInitialState()

        } else {
            this.props.setData(data);

            this.__getSubInitialState()

        }

        

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    setTableChange = (name, value) => {
        this.setState({
            [name]: value
        })

        if(value !== null){
            if (name == 'teacher_value') {
                this.setState({
                    teacher: value.value
                })
            }

            if (name == 'f_subjects_value') {
                this.setState({
                    sub_code: value.value
                })
            }
        }

        
    }

    render() {
        let { classes } = this.props;
        let stream = [];
        let course = [];
        let session = [];
        let subject = [];
        let teacher = [];
        let semester = [];
        if (this.props.is_all_loaded) {
            let i = this.props.courses;
            if (Array.isArray(i) && i.length > 0) {
                course = i.map((el, index) =>
                    <MenuItem key={index} value={el.name}>{el.name}</MenuItem>
                )
            }

            let j = this.props.streams;
            if (Array.isArray(j) && j.length > 0) {
                stream = j.map((el, index) =>
                    <MenuItem key={index} value={el.stream_name}>{el.stream_name}</MenuItem>
                )
            }

            let k = this.state.term_list;
            if (Array.isArray(k) && k.length > 0) {
                semester = k.map((el, index) =>
                    <MenuItem key={index} value={el}>{el}</MenuItem>
                )
            }

            let l = this.props.sessions;
            if (Array.isArray(l) && l.length > 0) {
                session = l.map((el, index) =>
                    <MenuItem key={index} value={el.session_name}>{el.session_name}</MenuItem>
                )
            }

            let m = this.state.f_subjects;
            if (Array.isArray(m) && m.length > 0) {
                subject = m.map((el, index) =>
                    <MenuItem key={index} value={el.sub_code}>{el.sub_name} - {el.sub_code}</MenuItem>
                )
            }

            let n = this.props.teachers;

            if (Array.isArray(n) && n.length > 0) {
                teacher = n.map((el, index) =>
                    <MenuItem key={index} value={el.email}>{el.name} - {el.dept_code}</MenuItem>
                )
            }
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>
                            {/* <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Session</InputLabel>
                                <Select
                                    value={this.state.session}
                                    onChange={this.onChange}
                                    name="session"
                                    inputProps={{
                                        name: 'session'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {session}
                                </Select>
                            </FormControl>

                            <br />
                            <br /> */}

                            <Grid container spacing={2}>

                                <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                        <Select
                                            value={this.state.course}
                                            onChange={this.onChange}
                                            name="course"
                                            inputProps={{
                                                name: 'course'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {course}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Stream</InputLabel>
                                        <Select
                                            value={this.state.stream}
                                            onChange={this.onChange}
                                            name="stream"
                                            inputProps={{
                                                name: 'stream'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {stream}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={6} sm={12} md={6} xs={12}>

                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select {this.state.term_name}</InputLabel>
                                        <Select
                                            value={this.state.semester}
                                            onChange={this.onChange}
                                            name="semester"
                                            inputProps={{
                                                name: 'semester'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {semester}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={6} sm={12} md={6} xs={12}>

                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Day</InputLabel>
                                        <Select
                                            value={this.state.day}
                                            onChange={this.onChange}
                                            name="day"
                                            inputProps={{
                                                name: 'day'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Mon">Monday</MenuItem>
                                            <MenuItem value="Tue">Tuesday</MenuItem>
                                            <MenuItem value="Wed">Wednesday</MenuItem>
                                            <MenuItem value="Thu">Thursday</MenuItem>
                                            <MenuItem value="Fri">Friday</MenuItem>
                                            <MenuItem value="Sat">Saturday</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item lg={12} sm={12} md={12} xs={12}>
                                   {/*  <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Subject Code</InputLabel>
                                        <Select
                                            value={this.state.sub_code}
                                            onChange={this.onChange}
                                            name="sub_code"
                                            inputProps={{
                                                name: 'sub_code'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {subject}
                                        </Select>
                                    </FormControl> */}

                                    <Typography>
                                        Select Subject
                                    </Typography>

                                    <Sel
                                        options={this.state.f_subjects}
                                        value={this.state.f_subjects_value}
                                        onChange={this.setTableChange.bind(this,'f_subjects_value')}
                                    />

                                </Grid>

                                <Grid item lg={12} sm={12} md={12} xs={12}>
                                    {/* <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Teacher</InputLabel>
                                        <Select
                                            value={this.state.teacher}
                                            onChange={this.onChange}
                                            name="teacher"
                                            inputProps={{
                                                name: 'teacher'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {teacher}
                                        </Select>
                                    </FormControl> */}

                                    <Typography>
                                        Select Teacher
                                    </Typography>

                                    <Sel
                                        options={this.props.teachers}
                                        value={this.state.teacher_value}
                                        onChange={this.setTableChange.bind(this, 'teacher_value')}
                                    />

                                </Grid>

                                <Grid item lg={6} sm={12} md={6} xs={12}>

                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Course Type</InputLabel>
                                        <Select
                                            value={this.state.course_type}
                                            onChange={this.onChange}
                                            name="course_type"
                                            inputProps={{
                                                name: 'course_type'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="HC">HC</MenuItem>
                                            <MenuItem value="RC">RC</MenuItem>
                                            <MenuItem value="HG">HG</MenuItem>
                                            <MenuItem value="RC+HG">RC+HG</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Section (if applicable)</InputLabel>
                                        <Select
                                            value={this.state.section}
                                            onChange={this.onChange}
                                            name="section"
                                            inputProps={{
                                                name: 'section'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="A">A</MenuItem>
                                            <MenuItem value="B">B</MenuItem>
                                            <MenuItem value="C">C</MenuItem>
                                            <MenuItem value="D">D</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <TextField
                                        label="Room Number"
                                        name="room_number"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.room_number}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <br />
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Period</InputLabel>
                                        <Select
                                            value={this.state.period_number}
                                            onChange={this.onChange}
                                            name="period_number"
                                            inputProps={{
                                                name: 'period_number'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={1}>1st Period</MenuItem>
                                            <MenuItem value={2}>2nd Period</MenuItem>
                                            <MenuItem value={3}>3rd Period</MenuItem>
                                            <MenuItem value={4}>4th Period</MenuItem>
                                            <MenuItem value={5}>5th Period</MenuItem>
                                            <MenuItem value={6}>6th Period</MenuItem>
                                            <MenuItem value={7}>7th Period</MenuItem>
                                            <MenuItem value={8}>8th Period</MenuItem>
                                            <MenuItem value={9}>9th Period</MenuItem>
                                            <MenuItem value={10}>10th Period</MenuItem>
                                            <MenuItem value={11}>11th Period</MenuItem>
                                            <MenuItem value={12}>12th Period</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                            </Grid>


                            <Grid container spacing={8}>
                                <Grid item xs={12} lg={6}>

                                    <TextField
                                        label="Start Time"
                                        name="start_time"
                                        className={classes.textField}
                                        type="time"
                                        margin="normal"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.start_time}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={6}>

                                    <TextField
                                        label="End Time"
                                        name="end_time"
                                        className={classes.textField}
                                        type="time"
                                        margin="normal"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle,

                                            },
                                            shrink: true
                                        }}
                                        value={this.state.end_time}
                                        onChange={this.onChange}
                                    />

                                </Grid>
                            </Grid>
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)