import React, { Component } from 'react'
import { withStyles, Dialog, DialogTitle, ListItem, ListItemAvatar, ListItemText, List, Avatar, DialogActions, Button, Typography, DialogContent } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person'
import Done from '@material-ui/icons/Done'
import { green, red } from '@material-ui/core/colors';
import Moment from 'react-moment';
const styles = theme => ({
    imgg: {
        
    },
    photoDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing.unit*2
    }
})

class AttendancePhotoView extends Component {

    getGoodTime = (value) => {
        console.log(value)
        var dt = new Date(value * 1000);
        var hr = dt.getHours();
        var m = "0" + dt.getMinutes();
        var s = "0" + dt.getSeconds();
        return hr + ':' + m.substr(-2) + ':' + s.substr(-2);  
    }
    render() {
        let { classes } = this.props;
        let i = [];
        if (this.props.isLoaded) {
            i = <img
                alt="no photo available"
                src={this.props.image_data}
                className={classes.imgg}
            />
        }
        return (
            <div>

                <Dialog
                    open={this.props.isLoaded}
                    onClose={this.props.onClose}
                    fullWidth
                    fullScreen
                    aria-labelledby="simple-dialog-title">
                    <DialogTitle id="simple-dialog-title">Attendance Photo
                        <Typography
                            variant="body2"
                        >
                            {new Date(this.props.photoTakenOn).toString()}

                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{textAlign: 'center'}}>

                        
                        <div className={classes.photoDiv}>
                            {i}
                        </div>
                    </DialogContent>
                    

                    <DialogActions>
                        <Button onClick={this.props.onClose}>
                            Cancel
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}


export default withStyles(styles)(AttendancePhotoView)
