import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Chip } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
import Add from '@material-ui/icons/Add';



export default class CourseTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    onViewClick(el) {
        this.props.onViewClick(el);
    }

    getTable = (data) => {
        let i = data.map((el, index) =>
            <Chip
                key={index}
                label={<span>{el.sm_head} : {el.sm_amount}</span>}
            />

        )

        return <div style={{display: 'flex'}}>
            {i}
        </div>
    }

    render() {
        const columns = [
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Stream",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "for girls?",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Payment Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Total",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            {
                name: "Table Value",
                options: {
                    filter: true,
                    sort: true,
                }
            },
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton onClick={this.props.onAddClick} ><Add /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        //console.log(table_data);
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.course,
                    el.stream,
                    el.course_type,
                    el.for_girls,
                    el.payment_head,
                    el.payment_amount,
                    <Launch onClick={this.onActionClick.bind(this, el)} />,
                    this.getTable(el.payment_table)
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Payments List"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
