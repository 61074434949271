import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import { GetData } from '../../../api/service';
import Sel from 'react-select';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        minWidth: '100%'
    }
})

class SubjectAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            class_name: '',
            course_name: '',
            stream_name: '',
            dept_code: '',
            course_type: '',
            term: '',
            intake: 0,
            sub_code: [],
            teacher_list: [],
            is_admission: 0,
            term_list: [1, 2, 3, 4, 5, 6],
            term_name: 'TERM',
            subjects: [],
            f_subjects: [],
            teachers: [],
            f_teachers: [],
            isTeacherLoaded: false,
            sugession: '',
            major: [],
            compulsory: [],
            optional: []
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'course_name') {
            let term_name = ""; let term_list = [];
            let course = this.props.initialData.courses;
            let results = course.filter((el) => el.name === e.target.value);
            if (results.length > 0) {
                term_name = results[0].tenure;
                let no_of_term = parseFloat(results[0].no_of_term);
                for (let i = 1; i <= no_of_term; i++) {
                    term_list.push(i);
                }
                this.setState({
                    term_name,
                    term_list
                })
            }

        }

        if (e.target.name === 'dept_code') {
            let { subjects, teachers } = this.state;

            let f_subjects1 = subjects.filter(el => el.dept_code === e.target.value);
            let f_teachers1 = teachers.filter(el => el.dept_code === e.target.value);

            //let f_subjects = f_subjects1.map((el, index) => el)
            //console.log(subjects)

            let f_teachers = f_teachers1.map((el, index) => {
                return {
                    label: el.id,
                    value: el.name
                }
            })
  
            this.setState({
                f_subjects: f_subjects1,
                f_teachers
            })

        }

        this.NameSugession()
    }

    goodTerm(value) {
        switch (value) {
            case '1':
                return '1st';

            case '2':
                return '2nd';


            case '3':
                return '3rd';


            case '4':
                return '4th';


            case '5':
                return '5th';


            case '6':
                return '6th';


            default:
                return '';

        }
    }

    NameSugession() {
        let sugession = this.state.sugession;
        let { course_name, course_type, stream_name, dept_code, term, term_name } = this.state;

        sugession = `${course_name} ${stream_name} ${this.goodTerm(term)} ${term_name} - ${dept_code} ${course_type}`;
        this.setState({
            sugession
        })
    }

    handleSChange = (name, sub_code) => {
        this.setState({
            [name]: sub_code
        })
    }   

    handleTChange = (teacher_list) => {
        this.setState({ teacher_list });
    }


    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.setState({
                class_name: '',
                course_name: '',
                stream_name: '',
                dept_code: '',
                course_type: '',
                term: '',
                intake: 0,
                sub_code: [],
                teacher_list: [],
                is_admission: 0,
                id: '',
                major: [],
                compulsory: [],
                optional: []
            })
        }

        //get list

        GetData('/getsubject')
            .then((resp) => {
                if (resp.length > 0) {

                    let subjects = resp;
                    let ff = subjects.map((el, index) => {
                        return {
                            label: el.label,
                            value: el.sub_code,
                            dept_code: el.dept_code
                        }
                    })

                    this.setState({
                        subjects: ff
                    })
                }
            }).then(() => {
                GetData('/getemployee')
                    .then((resp) => {
                        this.setState({
                            teachers: resp,
                            isTeacherLoaded: true
                        })
                    })
            })

    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.setState({
                    class_name: '',
                    course_name: '',
                    stream_name: '',
                    dept_code: '',
                    course_type: '',
                    term: '',
                    intake: 0,
                    sub_code: [],
                    teacher_list: [],
                    is_admission: 0,
                    id: '',
                    major: [],
                    compulsory: [],
                    optional: []
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        let { class_name, course_name, stream_name, dept_code, course_type, term, intake, compulsory, major, optional, id, teacher_list, is_admission } = this.state;

        const data = {
            class_name, course_name, stream_name, dept_code, course_type, term, intake, teacher_list, is_admission, compulsory, major, optional, id
        }

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            
        }

        this.setState({
            class_name: '',
            course_name: '',
            stream_name: '',
            dept_code: '',
            course_type: '',
            term: '',
            intake: 0,
            sub_code: [],
            teacher_list: [],
            is_admission: 0,
            major: [],
            compulsory: [],
            optional: []
        })

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        let course = [];
        let stream = [];
        let department = [];
        let term = [];
        if (this.props.isInLoaded) {
            if (Array.isArray(this.props.initialData.courses) && this.props.initialData.courses.length > 0) {
                course = this.props.initialData.courses.map((el, index) =>
                    <MenuItem key={index} value={el.name}>{el.name}</MenuItem>
                )
            }

            if (Array.isArray(this.props.initialData.departments) && this.props.initialData.departments.length > 0) {
                department = this.props.initialData.departments.map((el, index) =>
                    <MenuItem key={index} value={el.dept_code}>{el.dept_code}</MenuItem>
                )
            }

            if (Array.isArray(this.props.initialData.streams) && this.props.initialData.streams.length > 0) {
                stream = this.props.initialData.streams.map((el, index) =>
                    <MenuItem key={index} value={el.stream_name}>{el.stream_name}</MenuItem>
                )
            }

            if (Array.isArray(this.state.term_list) && this.state.term_list.length > 0) {
                term = this.state.term_list.map((el, index) =>
                    <MenuItem key={index} value={el}>{el}</MenuItem>
                )
            }
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <Grid container spacing={24}>

                                <Grid item xs={6} lg={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Course Type</InputLabel>
                                        <Select
                                            value={this.state.course_type}
                                            onChange={this.onChange}
                                            name="course_type"
                                            inputProps={{
                                                name: 'course_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="HONOURS">HONOURS</MenuItem>
                                            <MenuItem value="REGULAR">REGULAR</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} lg={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                        <Select
                                            value={this.state.course_name}
                                            onChange={this.onChange}
                                            name="course_name"
                                            inputProps={{
                                                name: 'course_name'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {course}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} lg={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Stream</InputLabel>
                                        <Select
                                            value={this.state.stream_name}
                                            onChange={this.onChange}
                                            name="stream_name"
                                            inputProps={{
                                                name: 'stream_name'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {stream}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} lg={6}>
                                    {(this.state.course_type === 'HONOURS') ? <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Department</InputLabel>
                                        <Select
                                            value={this.state.dept_code}
                                            onChange={this.onChange}
                                            name="dept_code"
                                            inputProps={{
                                                name: 'dept_code'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {department}
                                        </Select>
                                    </FormControl> : null}
                                </Grid>



                                <Grid item xs={12} lg={12}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select {this.state.term_name}</InputLabel>
                                        <Select
                                            value={this.state.term}
                                            onChange={this.onChange}
                                            name="term"
                                            inputProps={{
                                                name: 'term'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {term}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        label="Intake Capacity"
                                        name="intake"
                                        className={classes.textField}
                                        type="number"

                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.intake}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        label="Class Name"
                                        name="class_name"
                                        className={classes.textField}
                                        type="text"
                                        helperText={`Sugession:  ${this.state.sugession}`}
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.class_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <InputLabel className={classes.textStyle}>Select Complusory Subjects</InputLabel>
                                    <Sel
                                        isMulti
                                        value={this.state.compulsory}
                                        onChange={this.handleSChange.bind(this,"compulsory")}
                                        options={this.state.subjects}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <InputLabel className={classes.textStyle}>Select Major Subject</InputLabel>
                                    <Sel
                                        isMulti
                                        value={this.state.major}
                                        onChange={this.handleSChange.bind(this, "major")}
                                        options={this.state.f_subjects}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <InputLabel className={classes.textStyle}>Select Optional Subjects</InputLabel>
                                    <Sel
                                        isMulti
                                        value={this.state.optional}
                                        onChange={this.handleSChange.bind(this, "optional")}
                                        options={this.state.subjects}
                                    />
                                </Grid>

                                <Grid item xs={6} lg={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>is Admission Eligible?</InputLabel>
                                        <Select
                                            value={this.state.is_admission}
                                            onChange={this.onChange}
                                            name="is_admission"
                                            inputProps={{
                                                name: 'is_admission'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={0}>NO</MenuItem>
                                            <MenuItem value={1}>YES</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <br />
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(SubjectAdd)