import React, { Component } from 'react'
import { withStyles, Modal, Typography, IconButton, Grid, Button, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Close from '@material-ui/icons/Close'
import Print from '@material-ui/icons/Print'
import Call from '@material-ui/icons/Call'
import Email from '@material-ui/icons/Email'
import ReactToPrint from "react-to-print";

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        minHeight: '100vh'
    },
    pullRight: {
        float: 'center'
    },
    image: {
        width: '100%'
    }
});


class EmployeeModal extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div>
                <Modal
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <div className={classes.paper}>
                        <div className={classes.pullRight}>
                            <ReactToPrint
                                trigger={() => <IconButton>
                                    <Print />
                                </IconButton>}
                                content={() => this.componentRef}
                            />
                            

                            <IconButton onClick={this.props.handleClose}>
                                <Close />
                            </IconButton>
                        </div>

                        <Details {...this.props} {...this.props.view_data} ref={el => (this.componentRef = el)}/>

                    </div>
                </Modal>
            </div>
        )
    }
}

export default withStyles(styles)(EmployeeModal);


class Details extends Component{

    render(){
        let { classes } = this.props;
        let d = this.props.file[0];
        let dp = d.filter((el) => el.type === 'DP');
        let i = [];
        if (Array.isArray(dp) && dp.length > 0 && dp[0].type === 'DP') {
            i = <img
                className={classes.image}
                src={`data:${dp[0].mime};base64,${dp[0].data}`}
            />
        }
        return (
            <div>
                <Grid container spacing={24}>
                    <Grid item xs={2} lg={2}>
                        {i}
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" id="modal-title">
                            {this.props.data.name}
                        </Typography>
                        <Typography variant="subtitle1" id="simple-modal-description">
                            {this.props.data.designation}, {this.props.data.dept_code}, {(this.props.data.is_HOD) ? "HOD" : ""}
                        </Typography>
                        <Typography variant="subtitle1" id="simple-modal-description">
                            <Button>
                                <Call /> {this.props.data.phone}
                            </Button>

                            <Button>
                                <Email /> {this.props.data.email.toString().toLowerCase()}
                            </Button>
                        </Typography>

                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Teaching Mode
                            </TableCell>
                                    <TableCell>
                                        {this.props.data.teaching_mode}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Specialisation
                            </TableCell>
                                    <TableCell>
                                        {this.props.data.specialisation}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Date of birth
                            </TableCell>
                                    <TableCell>
                                        {this.props.data.date_of_birth}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Date of Appointment
                            </TableCell>
                                    <TableCell>
                                        {this.props.data.date_of_appointment}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Gender
                            </TableCell>
                                    <TableCell>
                                        {this.props.data.gender}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Caste
                            </TableCell>
                                    <TableCell>
                                        {this.props.data.caste}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Present Address
                            </TableCell>
                                    <TableCell>
                                        {this.props.data.present_address}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Permanent Address
                            </TableCell>
                                    <TableCell>
                                        {this.props.data.permanent_address}
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

            </div>
        )
    }
    
}