import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';



export default class AttendanceReportTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }


    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    padding: "3px"
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    padding: "3px"
                }
            }
        }
    })



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    render() {





        const columns = [
            {
                name: 'Roll No',
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: 'Name',
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: 'Course',
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: 'Stream',
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: 'Course Type',
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: 'Semester',
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: 'Subject Code',
                options: {
                    filter: true,
                    sort: true
                }
            },

            {
                name: 'Paper Code',
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: 'Total Class',
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: 'Attended Class',
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: 'Not attended Class',
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: 'Percentage',
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: 'Email',
                options: {
                    filter: false,
                    sort: false
                }
            },
            {
                name: 'Phone',
                options: {
                    filter: false,
                    sort: false
                }
            },
        ]



        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            rowsPerPage: 100
        };

        let data = [];
        let table_data = this.props.data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.roll_no,
                    el.name,
                    el.course,
                    el.stream,
                    el.course_type,
                    el.semester,
                    el.sub_code,
                    el.paper_code,
                    el.attendance['total_class'],
                    el.attendance['attended_class'],
                    el.attendance['not_attended_class'],
                    el.attendance['percentage'],
                    el.email,
                    el.phone,
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                        title={`Class Attendance ${this.props.paper_code != '' ? `(Paper Code: ${this.props.paper_code})` : ''}`}
                        data={data}
                        columns={columns}
                        options={options}

                    />
                </MuiThemeProvider>
            </div>
        )


    }
}
