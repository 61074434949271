import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import CertificateLanding from '../Components/Certificate/CertificateLanding';


class CertificateContainer extends Component {
    constructor(props) {
        super(props);

        let us = JSON.parse(localStorage.getItem('user'));
        let username = us.user.email;
        let name = us.user.name;

        this.state = {
            username: username,
            apikey: us.user.api_key,
            name: name
        }

    }
    render() {
        return (
            <div>
                <Navbar history={this.props.history} username={this.state.username} apikey={this.state.apikey} shrink={true} />
                <div className="main-body">
                    <CertificateLanding
                        history={this.props.history}
                        username={this.state.username}
                        name={this.state.name}
                        apikey={this.state.apikey} />
                </div>
            </div>
        )
    }
}

export default withAuth(CertificateContainer);