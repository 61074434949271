import React, { Component } from 'react';
import { withStyles, Card, CardHeader, Typography, Grid, CardContent, Button } from '@material-ui/core';
import { GetData, PostData } from '../../api/service';
import { green, pink } from '@material-ui/core/colors';
import Done from '@material-ui/icons/Done'
import Delete from '@material-ui/icons/Delete'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Snack from '../Snackbar/Snack';
import AddNewStudent from './AddNewStudent';


const styles = theme => ({
    card: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    rollContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: pink[400],
        color: 'white',
        flexBasis: '100px'
    },
    detailsContainer: {
        flexBasis: '80%',
        padding: '5px 2px 2px 7px'
    },
    buttonContainer: {
        width: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        cursor: 'pointer'

    },
})

class EditAttendance extends Component {
    state = {
        id: this.props.match.params.id,
        course: '',
        stream: '',
        course_type: '',
        section: '',
        semester:'',
        by_username: '',
        name: '',
        attendance: [],
        students: [],
        sub_code: '',
        sub_name: '',
        date1: '',
        isLoaded: false,
        open: false,
        message: '',
        newStudent: false,
        newStudentList: []
    }
    componentDidMount() {
        GetData(`/${this.state.id}/getattendancebyid`)
        .then((resp) => {
            //console.log(resp)
            this.setState({
                ...resp,
                students: resp.attendance,
                isLoaded: true
            })
        })
    }
    takeValue = (el, status) => {

        let a = {
            email: el.email,
            roll_no: el.roll_no,
            name: el.name,
            time_stamp: new Date().getTime(),
            status: !status
        }

        let attendance = this.state.students;
        if (Array.isArray(attendance)) {
            let r = attendance.filter(el1 => el1.email === el.email);
            if (r.length > 0) {
                let k = attendance.findIndex(el1 => el1.email === el.email);
                //attendance.splice(k, 1);
                attendance[k].status = a.status;
                //console.log(k)
            }
            
        }
        //console.log(attendance)
        //console.log(attendance.indexOf(el1 => el1.email === "pop@gmail.com"))

        this.setState({
            attendance
        })


        
    }

    deleteStudent = (el) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.onConfirmDelete(el)
                },
                {
                    label: 'No',
                   
                }
            ]
        });
    }

    onConfirmDelete = (student) => {
        let attendance = this.state.attendance;
        let students = this.state.students;
        let r = attendance.filter(el => el.email != student.email);
        let r1 = students.filter(el => el.email != student.email);
        this.setState({
            attendance: r,
            students: r1
        })
    }

    onSubmit = () => {
        if(this.state.id != ''){
            let d = {
                id: this.state.id,
                attendance: this.state.attendance
            }
            let attendance = this.state.attendance;
            let r = attendance.filter(el => el.status == true)

            confirmAlert({
                title: 'Data Reverification',
                childrenElement: () => <div>
                    Total Student: <b>{attendance.length}</b> <br />
                    Attended Student: <b>{r.length}</b>
                </div>,
                buttons: [
                    {
                        label: 'OKAY',
                        onClick: this.onFinalSubmitChecked.bind(this, d)
                    },
                    {
                        label: 'NOT OKAY'
                    }
                ]
            })
        }
    }

    onFinalSubmitChecked = (data) => {
        PostData(`/updateattendance`, data)
        .then((resp) => {
            this.setState({
                open: true,
                message: resp
            })

            setTimeout(() => {
                window.location.reload();
            }, 1000);
        })
    }

    onNewStudentAdd = () => {
        let d = {
            course: this.state.course,
            semester: this.state.semester,
            sub_code: this.state.sub_code
        }

        PostData(`/getstudentlisttoaddinattendance`, d)
        .then((resp) => {
            
            this.setState({
                newStudent: true,
                newStudentList: resp
            })
        })
    }

    finalAddStudents = (students) => {
        let st = this.state.students;
        let att = this.state.attendance;
        console.log(students)

        if(Array.isArray(students) && students.length > 0){
            students.map(el => {
                let r = st.filter(el1 => el1.email == el.email);
                let r1 = att.filter(el1 => el1.email == el.email);
                if(r.length == 0){
                    att.push(el);
                }

                /* if (r1.length == 0) {
                    att.push(el);
                } */
            })
        }
        

        this.setState({
            students: st,
            attendance: att,
            newStudent: false,
            newStudentList: []
        })
    }

    render() {
        let { classes } = this.props;
        let i = [];
        if (this.state.isLoaded) {
            let students = this.state.students;
            let attendance = this.state.attendance;
            if (Array.isArray(students) && students.length > 0) {
                i = students.map((el, index) => {
                    let r = false;
                    if (Array.isArray(attendance) && attendance.length > 0) {
                        let r1 = attendance.filter(el1 => el1.email === el.email);
                        if (r1.length > 0) {
                            r = r1[0]['status'];
                        }

                    }

                    return (
                        <StudentAttendanceCard
                            key={index}
                            {...this.props}
                            data={el}
                            status={r}
                            takeValue={this.takeValue.bind(this, el, r)}
                            deleteStudent={this.deleteStudent.bind(this, el)}
                        />
                    )
                }

                )
            }
        } else {
            i = <Grid item xs={12} sm={12} lg={12} md={12} align="center">
                <Typography align="center">
                    Loading . . .
                    </Typography>
            </Grid>
        }
        return (
            <div>

                {this.state.open? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}
                <Card>
                    <CardHeader
                        title={`Edit Attendance`}
                        subheader={
                            <div>
                                <Typography>
                                    Subject: {this.state.sub_name} | Course: {this.state.course} {this.state.course == 'HS' ? 'Year': 'Semester'}: {this.state.semester} | Date: {this.state.date1} | By: {this.state.name}
                                </Typography>
                            </div>
                        }
                    />
                </Card>
                <br />

                {this.state.isLoaded ? (
                    <div>
                    <Grid container spacing={16}>

                        {i}

                    </Grid>

                    <br />

                    <div align="right">
                            <Button
                                onClick={this.onNewStudentAdd}
                            >
                                I want to add a new student
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.onSubmit}
                        >
                            Update the attendance
                        </Button>
                    </div>
                    </div>
                ) : null}


                {this.state.newStudent ? (
                    <AddNewStudent
                        students_list={this.state.newStudentList}
                        open={this.state.newStudent}
                        handleClose={() => {
                            this.setState({
                                newStudent: false,
                                newStudentList: []
                            })
                        }}
                        addNewStudent={this.finalAddStudents.bind(this)}
                    />
                ) : null}


            </div>
        );
    }
}

export default withStyles(styles)(EditAttendance);

const StudentAttendanceCard = (props) => {
    let { classes } = props;
    return (
        <Grid item xs={12} lg={4}>
            <Card style={{ borderLeft: 'solid 3px #2196F3' }} >

                <div className={classes.card}>


                    <div className={classes.detailsContainer}>

                        <Typography
                            variant="body2"
                        >
                            {props.data.name}
                        </Typography>
                        <Typography
                            variant="title"
                        >
                            {props.data.roll_no}
                        </Typography>
                        <Delete style={{fontSize: 15}} onClick={props.deleteStudent} />
                    </div>


                    <CardContent
                        style={{ cursor: 'pointer', backgroundColor: props.status ? green[500] : 'lightgrey' }}
                        onClick={props.takeValue}
                    >
                        <div

                            className={classes.buttonContainer}
                        >
                            {props.status ? <Done style={{ fontSize: 20, color: 'white' }} /> : 'A'}
                        </div>
                    </CardContent>

                </div>

            </Card>
        </Grid>
    )
}