import React, { Component } from 'react';
import { withStyles, Card, Typography, CardHeader, Avatar, CardContent, TextField, Button, CardActions, Grid, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import Receipt from '@material-ui/icons/Receipt'
import AttendanceReportTable from './AttendanceReportTable';
import { PostData, GetData } from './../../api/service'
import NotAttendedReportTable from './NotAttendedReportTable';
import AttendanceListStudent from './AttendanceListStudent';
import AttendancePhotoView from './AttendancePhotoView';

const styles = theme => ({
    formControl: {
        width: '50%',
        marginTop: theme.spacing.unit * 2
    }
})

class AttendanceReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            start_date: '',
            end_date: '',
            table_data: [],
            isLoading: true,
            small_data: [],
            smallLoaded: false,
            teacher: '',
            cancelClassList: [],
            teacher_list: [],
            showCancelClassList: false,
            table_data_not_attended: [],
            isLoadingNot: true,
            student_list: [],
            loadStudentList: false,
            image_data: [],
            isImageLoaded: false
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        let data = {
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            teacher: this.state.teacher
        }

        let sd = {
            date: this.state.start_date,
            teacher: this.state.teacher
        }

        PostData(`/getattendancereports`, sd)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    small_data: resp,
                    smallLoaded: true
                })
            })

        PostData(`/getallattendancereport`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    table_data: resp,
                    isLoading: false
                })
            })



        PostData(`/getallnotattendedreport`, sd)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    table_data_not_attended: resp,
                    isLoadingNot: false
                })
            })

    }

    componentDidMount() {
        GetData(`/getemployeesmalldetails`)
            .then((resp) => {
                this.setState({
                    teacher_list: resp
                })
            })
    }

    onClose = () => {
        this.setState({
            loadStudentList: false,
            student_list: []
        })
    }

    onActionClick = (id) => {
        GetData(`/${this.props.apikey}/${this.props.username}/${id}/getattendancebyid`)
            .then((resp) => {
                console.log(resp);
                this.setState({
                    student_list: resp,
                    loadStudentList: true
                })
            })
    }

    photoClick = (el) => {
        PostData(`/${this.props.apikey}/${this.props.username}/getphotoinattendance`, el)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    image_data: resp,
                    isImageLoaded: true,
                    photoTakenOn: el.time_stamp
                })
            })
    }


    cancelClassList = () => {
        // fetch Data from the database
        let data = {
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            teacher: this.state.teacher
        }

        //console.log(data)

        //get the post data

        PostData(`/getcancelclasslist`, data)
            .then((resp) => {
                console.log(resp);
                this.setState({
                    cancelClassList: resp,
                    showCancelClassList: true
                })
            })

    }

    handleCloseDialog = () => {
        this.setState({
            showCancelClassList: false,
            cancelClassList: []
        })
    }

    render() {
        let { classes } = this.props;
        let i = [];
        let teacher_list = this.state.teacher_list;
        if (Array.isArray(teacher_list) && teacher_list.length > 0) {
            i = teacher_list.map((el, index) =>

                <MenuItem key={index} value={el.email}>{el.name}</MenuItem>

            )
        }
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <Receipt />
                                </Avatar>
                            }
                            title={
                                <Typography
                                    variant="h5"
                                >

                                    Attendance Report

                    </Typography>
                            }
                        />

                        <CardContent>
                            <TextField
                                label="Date (dd-mm-YYYY)"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                variant="outlined"
                                name="start_date"
                                value={this.state.start_date}
                                onChange={this.onChange}
                                helperText={`Must be in the format dd-mm-YYYY`}
                            />

                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="outlined-age-simple">
                                    Teacher
        </InputLabel>
                                <Select
                                    name="teacher"
                                    value={this.state.teacher}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput labelWidth={200} name="teacher" />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {i}
                                </Select>
                            </FormControl>
                        </CardContent>

                        <CardActions>
                            <Button
                                type="submit"
                            >
                                Get Reports
                </Button>
                        </CardActions>
                    </Card>
                </form>


                <br />

                {this.state.smallLoaded ?

                    <ViewNumbers
                        {...this.state.small_data}
                        {...this.props}
                        cancelClassList={this.cancelClassList}
                    />

                    : null}

                <br />

                {!this.state.isLoading ?
                    <AttendanceReportTable
                        table_data={this.state.table_data}
                        isLoading={this.state.isLoading}
                        actionClick={this.onActionClick}
                        photoClick={this.photoClick}
                    /> : null}


                {this.state.showCancelClassList ?
                    <CancelledList
                        {...this.props}
                        open={this.state.showCancelClassList}
                        classList={this.state.cancelClassList}
                        handleClose={this.handleCloseDialog}
                    />

                    : null}

                <br />

                {this.state.isLoadingNot ? null :
                    <NotAttendedReportTable
                        table_data={this.state.table_data_not_attended}
                        isLoading={this.state.isLoadingNot}
                    />
                }



                {this.state.loadStudentList ? <AttendanceListStudent
                    open={this.state.loadStudentList}
                    table_data={this.state.student_list}
                    onClose={this.onClose}
                /> : null}


                {this.state.isImageLoaded ? <AttendancePhotoView
                    isLoaded={this.state.isImageLoaded}
                    image_data={this.state.image_data}
                    photoTakenOn={this.state.photoTakenOn}
                    onClose={() => {
                        this.setState({
                            isImageLoaded: false,
                            image_data: []
                        })

                    }}
                /> : null}



            </div>
        )
    }
}

export default withStyles(styles)(AttendanceReport);


export const ViewNumbers = (props) => {
    let { classes } = props;
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item lg={3} sm={12}>
                    <Card>
                        <CardHeader
                            title={`Allotted`}
                        />
                        <CardContent>
                            <Typography
                                color="primary"
                                variant="h3">
                                {props.alloted}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={12}>
                    <Card>
                        <CardHeader
                            title={`Attended`}
                        />
                        <CardContent>
                            <Typography
                                color="primary"
                                variant="h3">

                                {props.held}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={12}>
                    <Card onClick={props.cancelClassList} style={{ cursor: 'pointer' }}>
                        <CardHeader
                            title={`Cancelled`}
                        />
                        <CardContent>
                            <Typography
                                color="secondary"
                                variant="h3">
                                {props.canceled}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={12}>
                    <Card>
                        <CardHeader
                            title={`Not Attended`}
                        />
                        <CardContent>
                            <Typography
                                color="secondary"
                                variant="h3">
                                {props.not_attended}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export const CancelledList = (props) => {
    let { classes } = props;
    let i = [];
    let classList = props.classList;
    if (Array.isArray(classList) && classList.length > 0) {
        i = classList.map((el, index) =>

            <tr key={index}>
                <td>{index + 1}</td>
                <td>{el.date1}</td>
                <td>{el.name}</td>
                <td>{el.sub_name}</td>
                <td>{el.semester}</td>
                <td>{el.day}</td>
                <td>{el.period_number}</td>
                <td>{el.room_number}</td>
                <td>{el.reason}</td>
            </tr>

        )
    }
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='lg'
            >
                <DialogTitle id="alert-dialog-title">{"Cancelled List"}</DialogTitle>
                <DialogContent>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Teacher</th>
                                <th>Subject</th>
                                <th>Semester</th>
                                <th>Day</th>
                                <th>Period</th>
                                <th>Room</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {i}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}