import React, { Component } from 'react';
import { withStyles, Card, CardHeader, CardContent, CardActions, Button, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import Sel from 'react-select'
import { GetData, PostData } from '../../../api/service';
import RoutineViewer from './RoutineViewer';

const styles = theme => ({

})

class RoutineView extends Component {
    state = {
        subjects: [],
        isSubjectLoaded: false,
        sub_code: '',
        stream: '',
        sub_value: {},
        isLoaded: false,
        routineSubject: [],
        isSubjectRoutineListLoaded: false
    }
    componentDidMount() {
        GetData(`/getsubject`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    subjects: resp,
                    isSubjectLoaded: true
                })
            })
    }

    onChange = (name, value) => {
        this.setState({
            [name]: value
        })

        if (name === 'sub_value') {
            this.setState({
                sub_code: value.value
            })
        }
    }

    onChangeNew = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        if (this.state.sub_code != '') {
            let d = {
                sub_code: this.state.sub_code,
                stream: this.state.stream
            }
            PostData(`/getroutinebysubcode`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        routineSubject: resp,
                        isSubjectRoutineListLoaded: true
                    })
                })
        }
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <Card style={{ height: '50vh' }}>
                    <CardHeader
                        title={`Routine Viewer`}
                    />

                    <CardContent>

                        <Grid container spacing={24}>

                            <Grid item lg={6} md={6} xs={12} sm={12}>

                                <Sel
                                    options={this.state.subjects}
                                    isLoading={!this.state.isSubjectLoaded}
                                    value={this.state.sub_value}
                                    onChange={this.onChange.bind(this, 'sub_value')}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} xs={12} sm={12}>
                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel shrink className={classes.textStyle}>Select Stream</InputLabel>
                                    <Select
                                        value={this.state.stream}
                                        onChange={this.onChangeNew}
                                        name="stream"
                                        inputProps={{
                                            name: 'stream'
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="ARTS">ARTS</MenuItem>
                                        <MenuItem value="SCIENCE">SCIENCE</MenuItem>
                                        <MenuItem value="COMMERCE">COMMERCE</MenuItem>

                                    </Select>
                                </FormControl>

                            </Grid>

                        </Grid>




                    </CardContent>
                    <CardActions>
                        <Button
                            variant="small"
                            type="button"
                            onClick={this.onSubmit}
                        >
                            Search
                        </Button>
                    </CardActions>
                </Card>

                <br />

                {this.state.isSubjectRoutineListLoaded ? (
                    <RoutineViewer
                        data={this.state.routineSubject}
                        sub_code={this.state.sub_code}
                    />
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(RoutineView);