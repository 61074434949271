import React, { Component } from 'react'
import { Switch, Router, Route } from 'react-router-dom'
import Category from './Category/Category';
import Session from './Session/Session';
import Questions from './Questions/Questions';
import ReportsFeedback from './Reports/ReportsFeedback';
import StudentFeedbackReport from './Reports/StudentFeedbackReport';
import TeacherFeedbackReport from './Reports/TeacherFeedbackReport';
import FeedbackAnalysisStudent from './Reports/FeedbackAnalysisStudent';

export default class FeedbackLanding extends Component {
    constructor(props) {
        super(props);

        let ap = JSON.parse(localStorage.getItem('apikey'));

        this.state = {
            apikey: ap
        }

    }


    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/feedback/category" render={() => <Category {...this.props} apikey={this.state.apikey} />} />
                    <Route exact path="/feedback/session" render={() => <Session {...this.props} apikey={this.state.apikey} />} />
                    <Route exact path="/feedback/questions" render={() => <Questions {...this.props} apikey={this.state.apikey} />} />
                    <Route exact path="/feedback/reports" render={() => <ReportsFeedback {...this.props} apikey={this.state.apikey} />} />
                    <Route exact path="/feedback/reports/students" render={() => <StudentFeedbackReport {...this.props} apikey={this.state.apikey} />} />
                    <Route exact path="/feedback/reports/teachers" render={() => <TeacherFeedbackReport {...this.props} apikey={this.state.apikey} />} />
                    <Route exact path="/feedback/reports/analysis" render={() => <FeedbackAnalysisStudent {...this.props} apikey={this.state.apikey} />} />
                </Switch>
            </div>
        )
    }
}
